import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { loaderAction } from "../../services/actions/loader.action";
import axios from "axios";
import { Api } from "../../utils/API/Api";
import { showProductWithFilterAction } from "../../services/actions/product.action";
import { Modal } from "react-bootstrap";
import "./confirm.css";
const ConfirmModal = (props) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const handleDelete = async (id) => {
    dispatch(loaderAction(true));
    try {
      const { data } = await axios.post(Api.deleteProducts, {
        _id: props.id,
      });
      if (data.status === 1) {
        alert.success(data.message);
        dispatch(showProductWithFilterAction());
      } else {
        alert.error(data.message);
      }
      dispatch(loaderAction(false));
    } catch (error) {
      alert.error(error.message);
      dispatch(loaderAction(false));
    }
  };

  return (
    <div className="confirm_modal">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Are you sure you want to delete?
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <div className="add_product_btn " onClick={() => props.onHide()}>
            Cancel
          </div>
          <div className="add_product_btn ml-3" onClick={() => handleDelete()}>
            Confirm
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
