import { constants } from "../constants";
import { loaderAction } from "./loader.action";
import axios from "axios";
import { Api } from "../../utils/API/Api";
import store from "../store/store";

const getAllAttributesAction = () => async (dispatch) => {
  dispatch(loaderAction(true));
  dispatch({ type: constants.GET_ALL_ATTRIBUTES_REQUEST });
  const { data } = await axios.get(Api.allAttribute);

  if (data.status === 0) {
    dispatch({ type: constants.GET_ALL_ATTRIBUTES_FAIL, error: data.message });
    dispatch(loaderAction(false));
  } else {
    dispatch({
      type: constants.GET_ALL_ATTRIBUTES_SUCCESS,
      payload: data.data,
    });
    dispatch(loaderAction(false));
  }
};

const getCategoriesIds = (data) => async (dispatch) => {
  dispatch({ type: constants.GET_CATEGORY_IDS, payload: data });
};

const getAttributeSizes = (data) => async (dispatch) => {
  dispatch({ type: constants.GET_ATTRIBUTE_SIZES, payload: data });
};

const getAttributeColors = (data) => async (dispatch) => {
  dispatch({ type: constants.GET_ATTRIBUTE_COLOR, payload: data });
};

const addAttributeAction = (attribute, type) => async (dispatch) => {
  dispatch(loaderAction(true));

  const { category, subCategory, subSubCategory } =
    store.getState().categoryIds;

  const { size } = store.getState().size;
  const { color } = store.getState().color;

  dispatch({ type: constants.ADD_ATTRIBUTE_REQUEST });
  const { data } = await axios.post(Api.addAttribute, {
    category,
    subCategory,
    subSubCategory,
    size: size,
    color: color,
    parameter: attribute,
  });

  if (data.status === 0) {
    dispatch({ type: constants.ADD_ATTRIBUTE_FAIL, error: data.message });
    dispatch(loaderAction(false));
  } else {
    dispatch({
      type: constants.ADD_ATTRIBUTE_SUCCESS,
      message: data.message,
      payload: data.data,
    });
    dispatch(loaderAction(false));
  }

  dispatch(loaderAction(false));
};

const getAttributeAction = (id) => async (dispatch) => {
  dispatch(loaderAction(true));
  dispatch({ type: constants.GET_ATTRIBUTES_REQUEST });
  const { data } = await axios.post(Api.getAttribute, {
    subSubCategory: id,
  });

  if (data.status === 0) {
    dispatch({ type: constants.GET_ATTRIBUTES_FAIL, error: data.message });
    dispatch(loaderAction(false));
  } else {
    dispatch({
      type: constants.GET_ATTRIBUTES_SUCCESS,
      payload: data.data,
    });
    dispatch(loaderAction(false));
  }
};

const sendAttributeDataAction = (data) => async (dispatch) => {
  let id = data.rowData.subSubCategory._id;

  dispatch(getAttributeAction(id));
  dispatch(getCategoriesIds(data.rowData.subSubCategory));

  dispatch({ type: constants.SEND_ATTRIBUTE_DATA, payload: data });
};

const backToAttributePage = (type) => async (dispatch) => {
  if (type) {
    dispatch(loaderAction(true));
    dispatch({ type: constants.BACK_TO_ATTRIBUTE_PAGE });
    dispatch(loaderAction(false));
  }
};

const deleteAttributeAction = (id) => async (dispatch) => {
  dispatch(loaderAction(true));

  dispatch({ type: constants.DELETE_ATTRIBUTE_REQUEST });
  const { data } = await axios.post(Api.deleteAttribute, {
    _id: id,
  });

  if (data.status === 0) {
    dispatch({ type: constants.DELETE_ATTRIBUTE_REQUEST, error: data.message });
    dispatch(loaderAction(false));
  } else {
    dispatch({
      type: constants.DELETE_ATTRIBUTE_SUCCESS,
      message: data.message,
    });

    let { allAttributes } = store.getState().allAttributes;

    if (allAttributes.length > 0) {
      let arr = allAttributes.filter((item) => item._id !== id);

      dispatch({
        type: constants.GET_ALL_ATTRIBUTES_SUCCESS,
        payload: arr,
      });
    }

    dispatch(loaderAction(false));
  }
};

export {
  getAllAttributesAction,
  getCategoriesIds,
  getAttributeSizes,
  backToAttributePage,
  getAttributeAction,
  addAttributeAction,
  sendAttributeDataAction,
  deleteAttributeAction,
  getAttributeColors,
};
