import React from "react";

import MaterialTable from "material-table";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { DeleteIcon, EditTableIcon } from "../../utils/MenusList/Icons";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteAttributeAction,
  sendAttributeDataAction,
} from "../../services/actions/attribute.action";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
function AttributeTable() {
  const { allAttributes } = useSelector((state) => state.allAttributes);
  const { message, error } = useSelector((state) => state.deleteAttribute);
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    message && alert.success(message);
    error && alert.error(error);
  }, [alert, message, error]);

  const columns = [
    {
      title: "#",
      field: "index",
    },
    // { title: "Attribute Name", field: "attributeName" },
    { title: "Category", field: "category" },
    {
      title: "Sub-Category",
      field: "subCategory",
    },
    { title: "Sub-Subcategory", field: "subSubCategory" },
  ];
  const options = {
    print: true,
    download: true,
    filter: true,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 50, 100],
    actionsColumnIndex: -1,
  };

  let attributeData =
    allAttributes === null
      ? []
      : allAttributes &&
        allAttributes.map((item, index) => {
          return {
            index: index + 1,
            category: item?.category?.categoryName,
            subCategory: item?.subCategory?.subCategoryName,
            subSubCategory: item?.subSubCategory?.subSubCategoryName,
            rowData: item,
          };
        });

  const actions = [
    {
      icon: EditTableIcon,
      tooltip: "Edit Attribute",
      onClick: (event, rowData) => {
        dispatch(sendAttributeDataAction(rowData));
        history.push("/edit_attribute");
      },
      className: "view_icon",
    },
    {
      icon: DeleteIcon,
      tooltip: "Delete Attribute",
      onClick: (event, rowData) => {
        dispatch(deleteAttributeAction(rowData.rowData._id));
      },
    },
  ];

  return (
    <>
      <Box>
        {loading ? (
          <CircularProgress />
        ) : (
          <MaterialTable
            options={options && options}
            data={attributeData && attributeData}
            columns={columns && columns}
            title=""
            onRowClick={(event, rowData) => console.log(rowData)}
            actions={actions && actions}
          />
        )}
      </Box>
    </>
  );
}

export default AttributeTable;
