import React, { useState } from "react";
import userImg from "./user-img.png";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch } from "react-redux";
import { logoutSuperAdmin } from "../../services/actions/auth.action";
const UserInfoMenu = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <Dropdown
        className="user_detail_toolbar"
        onMouseLeave={() => setShowDropdown(false)}
        onMouseOver={() => setShowDropdown(true)}
      >
        <Dropdown.Toggle id="dropdown-basic">
          <div className="d-flex-toolbar justify-flex-end">
            <img src={userImg} alt="" />
            <div>
              <h5>Konstantin Veselovsky</h5>
              <p>konstantin@gmail.com</p>
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu show={showDropdown}>
          <Link to="/login" onClick={() => dispatch(logoutSuperAdmin())}>
            <LogoutIcon />
            Logout
          </Link>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default UserInfoMenu;
