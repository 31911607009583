import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// import { EditIcon } from "../../../utils/Icons";
import { useHistory } from "react-router-dom";

import MaterialTable from "material-table";
import { DeleteIcon, EditIcon } from "../../utils/MenusList/Icons";
import SwitchButton from "./SwitchButton";
import { useSelector } from "react-redux";

export default function UserTable() {
  let history = useHistory();
  const { userList } = useSelector((state) => state.userList);

  // console.log(userList);

  const columns = [
    {
      title: "#",
      field: "index",
    },
    { title: "Name", field: "fullName" },
    { title: "Age", field: "age" },
    { title: "Country Code", field: "countryCode" },
    { title: "Phone Number", field: "phoneNumber" },
    { title: "Created on", field: "createdAt" },
    {
      title: "Status",
      field: "isActive",
      render: (item) => <SwitchButton item={item} />,
    },
  ];
  const options = {
    print: true,
    download: true,
    filter: true,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 50, 100],
    actionsColumnIndex: -1,
  };

  const actions = [
    {
      icon: EditIcon,
      tooltip: "Edit Order",
      onClick: (event, rowData) => history.push("/users_details"),
      className: "view_icon",
    },
    {
      icon: DeleteIcon,
      tooltip: "Delete Order",
      // onClick: (event, rowData) => history.push("/orders-detail"),
    },
  ];

  return (
    <MaterialTable
      options={options}
      data={userList && userList}
      columns={columns && columns}
      title={""}
      onRowClick={(event, rowData) => console.log(rowData, "rowsd")}
      actions={actions && actions}
    />
  );
}
