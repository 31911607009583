import React from "react";
import SearchBar from "material-ui-search-bar";
import { searchProductAction } from "../../services/actions/product.action";
import { useDispatch } from "react-redux";
function ProductSearch() {
  const dispatch = useDispatch();
  return (
    <>
      <SearchBar
        // value={this.state.value}
        onChange={(newValue) => dispatch(searchProductAction(newValue))}
        onRequestSearch={(newValue) => dispatch(searchProductAction(newValue))}
        onCancelSearch={(newValue) => dispatch(searchProductAction())}
        placeholder="Search..."
        cancelOnEscape={true}
        className="product_search_btn"
      />
    </>
  );
}

export default ProductSearch;

// import React from "react";
// import SearchBar from "material-ui-search-bar";
// import { searchProductAction } from "../../services/actions/product.action";
// import { useDispatch } from "react-redux";
// function ProductSearch() {
//   const dispatch = useDispatch();
//   return (
//     <>
//       <SearchBar
//         placeholder="Search..."
//         cancelOnEscape={true}
//         className="product_search_btn"
//         onChange={(e) => dispatch(searchProductAction(e))}
//       />
//     </>
//   );
// }

// export default ProductSearch;
