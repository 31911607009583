import { constants } from "../constants";

const initialState = {
  heading: null,
  imageTitle: null,
  title: null,
  categoryData: null,
  _id: null,
  modalOpen: false,
  body: null,
};

const addCategoryState = {
  message: "",
  error: "",
};

const categoryState = {
  categoryList: [],
  reOrderCategoryList: [],
};
const subCategoryState = {
  categorySubList: [],
  reOrderCategorySubList: [],
};
const subSubCategoryState = {
  categorySubSubList: [],
  reOrderCategorySubSubList: [],
};

const toggleSubCategoryAction = {
  subCategoryToggle: false,
  categoryData: null,
};

const toggleSubSubCategoryAction = {
  subSubCategoryToggle: false,
  categorySubData: null,
};

const subSubCateImageState = {
  url: null,
};
const subSubCateModalState = {
  subOpen: false,
};

const categoryModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CATEGORY_MODAL_SUCCESS:
      return {
        ...initialState,
        heading: action.payload.heading,
        imageTitle: action.payload.imageTitle,
        title: action.payload.title,
        categoryData: action.payload.category,
        subCategoryData: action.payload.subCategory,
        type: action.payload.type,
        modalOpen: action.payload.open,
        _id: action.payload._id,
        body: action.payload.body,
      };
    case constants.CATEGORY_MODAL_FAIL:
      return {
        ...initialState,
      };
    case constants.CLEAR_CATEGORY:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

const categorySubSubModalReducer = (state = subSubCateModalState, action) => {
  switch (action.type) {
    case constants.CATEGORY_MODAL_OPEN:
      return {
        ...subSubCateModalState,
        subOpen: action.payload.open,
      };

    case constants.CATEGORY_MODAL_CLOSE:
      return {
        ...subSubCateModalState,
      };

    default:
      return state;
  }
};

const addCategoriesReducer = (state = addCategoryState, action) => {
  switch (action.type) {
    case constants.ADD_CATEGORY_REQUEST:
      return {
        ...addCategoryState,
      };
    case constants.ADD_CATEGORY_SUCCESS:
      return {
        ...addCategoryState,
        message: action.message,
      };
    case constants.ADD_CATEGORY_FAIL:
      return {
        ...addCategoryState,
        error: action.error,
      };
    // case constants.CLEAR_CATEGORY:
    //   return {
    //     ...initialState,
    //   };
    default:
      return state;
  }
};

const toggleCategoryReducer = (state = toggleSubCategoryAction, action) => {
  switch (action.type) {
    case constants.CATEGORY_SUB_TOGGLE_REQUEST:
      return {
        ...toggleSubCategoryAction,
      };

    case constants.CATEGORY_SUB_TOGGLE_SUCCESS:
      return {
        ...toggleSubCategoryAction,
        subCategoryToggle: action.subCategory,
        categoryData: action.payload,
      };
    // case constants.CLEAR_CATEGORY:
    //   return {
    //     ...initialState,
    //   };

    case constants.CATEGORY_SUB_TOGGLE_FAIL:
      return {
        state,
      };

    default:
      return state;
  }
};

const toggleSubCategoryReducer = (
  state = toggleSubSubCategoryAction,
  action
) => {
  switch (action.type) {
    case constants.CATEGORY_SUB_SUB_TOGGLE_SUCCESS:
      return {
        ...toggleSubSubCategoryAction,
        subSubCategoryToggle: action.subSubCategory,
        categorySubData: action.payload,
      };
    case constants.CATEGORY_SUB_SUB_TOGGLE_FAIL:
      return {
        state,
      };

    default:
      return state;
  }
};

const editSubSubCategoryImageReducer = (
  state = subSubCateImageState,
  action
) => {
  switch (action.type) {
    case constants.CATEGORY_MODAL_IMAGE_SUCCESS:
      return {
        ...toggleSubSubCategoryAction,
        url: action.payload.url,
      };
    case constants.CATEGORY_MODAL_IMAGE_RESET:
      return {
        state,
      };

    default:
      return state;
  }
};

const categoryReducer = (state = categoryState, action) => {
  switch (action.type) {
    case constants.CATEGORY_SUCCESS:
      return {
        ...categoryState,
        categoryList: action.payload.data,
        reOrderCategoryList: action.payload.reOrder,
      };
    case constants.CATEGORY_FAIL:
      return {
        ...categoryState,
      };

    default:
      return state;
  }
};

const subCategoryReducer = (state = subCategoryState, action) => {
  switch (action.type) {
    case constants.CATEGORY_SUB_SUCCESS:
      return {
        ...subCategoryState,
        categorySubList: action.payload.data,
        reOrderCategorySubList: action.payload.reOrder,
      };
    case constants.CATEGORY_SUB_FAIL:
      return {
        ...subCategoryState,
      };

    default:
      return state;
  }
};

const subSubCategoryReducer = (state = subSubCategoryState, action) => {
  switch (action.type) {
    case constants.CATEGORY_SUB_SUB_FAIL:
      return {
        ...subSubCategoryState,
      };
    case constants.CATEGORY_SUB_SUB_SUCCESS:
      return {
        ...subSubCategoryState,
        categorySubSubList: action.payload.data,
        reOrderCategorySubSubList: action.payload.reOrder,
      };

    default:
      return state;
  }
};

export {
  categoryModalReducer,
  addCategoriesReducer,
  categoryReducer,
  subCategoryReducer,
  subSubCategoryReducer,
  toggleCategoryReducer,
  toggleSubCategoryReducer,
  editSubSubCategoryImageReducer,
  categorySubSubModalReducer,
};
