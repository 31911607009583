export const ActiveInactive = (setActive, location) => {
  setActive(
    location.pathname.substring(1) === ""
      ? "Dashboard"
      : location.pathname.substring(1) === "dashboard_reseller"
      ? "Dashboard"
      : location.pathname.substring(1) === "dashboard_transaction"
      ? "Dashboard"
      : location.pathname.substring(1) === "users"
      ? "Users"
      : location.pathname.substring(1) === "users_details"
      ? "Users"
      : location.pathname.substring(1) === "order_details"
      ? "Users"
      : location.pathname.substring(1) === "category"
      ? "Category"
      : location.pathname.substring(1) === "orders"
      ? "Orders"
      : location.pathname.substring(1) === "products"
      ? "Products"
      : location.pathname.substring(1) === "add_products"
      ? "Products"
      : location.pathname.substring(1) === "add_product_details"
      ? "Products"
      : location.pathname.substring(1) === "edit_product_details"
      ? "Products"
      : location.pathname.substring(1) === "commission"
      ? "Commission"
      : location.pathname.substring(1) === "driver_user"
      ? "Driver User"
      : location.pathname.substring(1) === "add_driver"
      ? "Driver User"
      : location.pathname.substring(1) === "content"
      ? "Content"
      : location.pathname.substring(1) === "sub_admin"
      ? "Sub Admin"
      : location.pathname.substring(1) === "delivery_fee"
      ? "Delivery Fees"
      : location.pathname.substring(1) === "transactions"
      ? "Transactions"
      : location.pathname.substring(1) === "notifications"
      ? "Notifications"
      : location.pathname.substring(1) === "add_notifications"
      ? "Notifications"
      : location.pathname.substring(1) === "report_feedback"
      ? "Report/Feedback"
      : location.pathname.substring(1) === "video"
      ? "Video"
      : location.pathname.substring(1) === "attributes"
      ? "Attributes"
      : location.pathname.substring(1) === "add_attribute"
      ? "Attributes"
      : location.pathname.substring(1) === "edit_attribute"
      ? "Attributes"
      : location.pathname.substring(1)
  );
};
