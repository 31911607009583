import React from "react";
import CollapseFilter from "./CollapseFilters/CollapseFilter";

function CategoriesFilter() {
  return (
    <div className="product_category_filter_container">
      <div className="product_category_filter">
        <h6>Categories</h6>

        <CollapseFilter />
      </div>
    </div>
  );
}

export default CategoriesFilter;
