import { constants } from "../constants";

const initialState = {
  title: "",
  location_label: "",
  price_label: "",
  type: "",
  rowData: "",
};

const deliveryState = {
  data: [],
  loading: false
}

const deliverModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.DELIVERY_INPUT_DATA:
      return {
        ...state,
        title: action.payload.title,
        location_label: action.payload.location_label,
        price_label: action.payload.price_label,
        type: action.payload.type,
        rowData: action.payload.rowData,
      };
    case constants.DELIVERY_INPUT_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};



const getDeliverReducer = (state = deliveryState, action) => {

  switch (action.type) {
    case constants.DELIVERY_REQUEST:
      return {
        ...state,
        loading: action.payload.loading

      };
    case constants.DELIVERY_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: action.payload.loading
      };


    default:
      return state;
  }
};


export { deliverModalReducer, getDeliverReducer };
