/* eslint-disable jsx-a11y/alt-text */
export const DashboardCardMenus = [
  {
    count: 600,
    title: "Total orders",
    icons: "assets/images/dashboard_order.svg",
  },
  {
    count: 500,
    title: "Total deliverers",
    icons: "assets/images/dashboard_order.svg",
  },
  { count: "$900", title: "Total profit", icons: "assets/images/profit.svg" },
  {
    count: 600,
    title: "Total products sales",
    icons: "assets/images/dashboard_order.svg",
  },
  {
    count: 600,
    title: "Total users joined",
    icons: "assets/images/total-users.svg",
  },
  { count: "$1104", title: "Revenue", icons: "assets/images/revenue.svg" },
  { count: "+10.22", title: "Growth", icons: "assets/images/revenue.svg" },
  {
    count: "$100",
    title: "Total commission",
    icons: "assets/images/profit.svg",
  },
];
