import Heading from "../../Heading/Heading";
import Sidebar from "../../Sidebar/Sidebar";
import { useStyles } from "../../../utils/useStyles";
import PaymentTable from "./PaymentTable";
import UserInfo from "./UserInfo";
import "./userDetails.css";
import UserProductShared from "./UserProductShared";
import { Col, Row } from "react-bootstrap";
import OrderSectionDetails from "./OrderSectionDetails";
function UserDetail() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading />

        {/* Data table */}
        <div>
          <div className="card_box">
            <UserInfo />
          </div>
          <div>
            <Row>
              <Col xs={12} md={6}>
                <h6 className="heading_details">Products recently shared</h6>
                <div className="card_box p-0">
                  <UserProductShared />
                </div>
              </Col>
              <Col xs={12} md={6}>
                <h6 className="heading_details">Order placed section</h6>
                <div className="card_box p-0">
                  <OrderSectionDetails />
                </div>
              </Col>
            </Row>
          </div>
          <h6 className="heading_details">Payments</h6>
          <div className="card_box">

            <PaymentTable />
          </div>
        </div>
      </main>
    </div>
  );
}

export default UserDetail;
