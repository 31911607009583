import React from 'react'
import { Link } from "react-router-dom"
import { Col, Row } from "react-bootstrap";
function OrderSectionDetails() {
  return (
    <div className='box_product '>

      <Row className='pt-4 pl-3'>
        <Col xs={12} md={3} lg={2} className="pr-0">
          <div className="img_container">
            <img src='assets/images/product-img-two.png' />
          </div>
        </Col>
        <Col xs={12} md={5} lg={6} className="pl-0">
          <div className='pl-2 card_order'>

            <h5>11112232323</h5>
            <p >$20.00 <span>Size-M</span><span>Qty 1</span></p>
            <p className="shared_date">24 March 2022 - 10.00 AM</p>
          </div>
        </Col>
        <Col xs={12} md={4} lg={4}>
          <div className="shared_badge">52 Connections</div>
          <Link to="/order_details" className='link_order '>

            <img src="assets/images/arrow_right.svg" />
          </Link>
        </Col>
      </Row>


      <Row className='pt-4 pl-3'>
        <Col xs={12} md={2} className="pr-0">
          <div className="img_container">
            <img src='assets/images/product-img-two.png' />
          </div>
        </Col>
        <Col xs={12} md={6} className="pl-0">
          <div className='pl-2 card_order'>

            <h5>11112232323</h5>
            <p >$20.00 <span>Size-M</span><span>Qty 1</span></p>
            <p className="shared_date">24 March 2022 - 10.00 AM</p>
          </div>
        </Col>
        <Col xs={12} md={4}>
          <div className="shared_badge">52 Connections</div>
          <Link to="/order_details" className='link_order '>

            <img src="assets/images/arrow_right.svg" />
          </Link>
        </Col>
      </Row>

    </div>
  )
}

export default OrderSectionDetails