import Sidebar from "../Sidebar/Sidebar";
import Heading from "../Heading/Heading";
import { useStyles } from "../../utils/useStyles";
import TransactionTable from "./TransactionTable";
import BasicDateRangePicker from "../Dashboard/BasicDateRangePicker";
function Transactions() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading />

        {/* Data table */}

        <TransactionTable />
      </main>
    </div>
  );
}

export default Transactions;
