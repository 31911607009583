import React from "react";

import Sidebar from "../Sidebar/Sidebar";
import Heading from "../Heading/Heading";
import { useStyles } from "../../utils/useStyles";
import CommissionDataTable from "./CommissionDataTable";
import "./Commission.css";
function Commissions() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading />

        {/* Data table */}

        <CommissionDataTable />
      </main>
    </div>
  );
}

export default Commissions;
