import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Skeleton } from "@mui/material";

function LinearProgressWithLabel() {
  return (
    <Box>
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      <Skeleton variant="text" height={60} />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
    </Box>
  );
}


export default LinearProgressWithLabel;
