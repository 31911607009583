import axios from "axios";
import { Api } from "../../utils/API/Api";
import { constants } from "../constants";
import { loaderAction } from "./loader.action";

// DELIVERY_INPUT_DATA

const deliverModalAction = (type, data) => async (dispatch) => {
  switch (type) {
    case "ADD_FEE":
      return dispatch({
        type: constants.DELIVERY_INPUT_DATA,
        payload: {
          title: "Add Delivery Fees",
          location_label: "Add Location",
          price_label: "Add Price",
          type: "Add",
          rowData: "",
        },
      });
    case "EDIT_FEE":
      return dispatch({
        type: constants.DELIVERY_INPUT_DATA,
        payload: {
          title: "Edit Delivery Fees",
          location: "Edit Location",
          price_label: "Edit Price",
          type: "Edit",
          rowData: data,
        },
      });
    default:
      return false;
  }
};

const deliverModalActionReset = (type, data) => async (dispatch) => {
  return dispatch({
    type: constants.DELIVERY_INPUT_RESET,
  });
};


const getDeliveryAction = () => async (dispatch) => {
  dispatch(loaderAction(true));

  dispatch({
    type: constants.DELIVERY_REQUEST, payload: { loading: true },
  });
  await axios
    .get(Api.deliveryFee)
    .then((res) => {
      if (res.data.status === 1) {

        dispatch({
          type: constants.DELIVERY_SUCCESS, payload: { data: res.data.data, loading: false }
        }
        );
        dispatch(loaderAction(false));
      } else {
        dispatch(loaderAction(false));

      }
    }).catch(err => {
      console.log(err);
      dispatch(loaderAction(false));

    })

};



export { deliverModalAction, deliverModalActionReset, getDeliveryAction };
