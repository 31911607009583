import React from "react";
import { Container } from "react-bootstrap";
import CategoriesFilter from "./CategoriesFilter";
import CategoryPriceFIlter from "./CategoryPriceFIlter";
import ColorRadio from "./ColorRadio";
import SelectSize from "./SelectSize";
import { useDispatch } from "react-redux";
import { resetFilterAction } from "../../../services/actions/product.action";
function ProductFilter() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(resetFilterAction());
  }, [dispatch]);

  return (
    <div className="filter pb-2">
      <div className="row">
        <div className="col-9">
          <h4 className="pl-3 pt-3">Filters</h4>
        </div>
        <div className="col-3 mt-2">
          <div
            className="add_product_btn"
            onClick={() => dispatch(resetFilterAction())}
          >
            Reset
          </div>
        </div>
      </div>
      <hr />
      <CategoriesFilter />
      <hr />
      <h4 className="pl-3 pt-3">Price</h4>
      <Container className="pb-2">
        <CategoryPriceFIlter />
      </Container>
      <h4 className="pl-3 pt-3">Size</h4>
      <Container className="pb-2 d-flex justify-content-between">
        <p className="select_size_text">Select Size</p>
        <SelectSize className="product_select" />
      </Container>
      <h4 className="pl-3 pt-3">Color</h4>
      <Container className="pb-2 ">
        <ColorRadio />
      </Container>
    </div>
  );
}

export default ProductFilter;
