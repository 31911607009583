import React, { useEffect } from "react";

import Sidebar from "../Sidebar/Sidebar";
import Heading from "../Heading/Heading";
import { useStyles } from "../../utils/useStyles";
import ProductInnerPage from "./ProductInnerPage";
import "./Product.css";
import { useDispatch } from "react-redux";
import { getCategoryAction } from "../../services/actions/category.action";
import {
  productPageReloadAction,
  resetProductDetails,
  showAddProductImageAction,
} from "../../services/actions/product.action";
import { isUserLoggedIn } from "../../services/actions/auth.action";

function Products() {
  const classes = useStyles();

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getCategoryAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(productPageReloadAction(true));
    dispatch(showAddProductImageAction(false));
    dispatch(resetProductDetails());
    dispatch(isUserLoggedIn());
  }, []);

  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading productAction={true} />

        {/* Data table */}
        <ProductInnerPage />
      </main>
    </div>
  );
}

export default Products;
