import axios from "axios";
import { Api } from "../../utils/API/Api";
import { constants } from "../constants";
import { loaderAction } from "./loader.action";
import store from "../store/store";

const productPageReloadAction = (Boolean) => async (dispatch) => {
  Boolean
    ? dispatch({ type: constants.PRODUCT_PAGE_RELOAD_TRUE, payload: true })
    : dispatch({ type: constants.PRODUCT_PAGE_RELOAD_FALSE, payload: false });
};

const showAddProductImageAction = (Boolean, data) => async (dispatch) => {
  // dispatch(loaderAction(true));
  dispatch({
    type: Boolean
      ? constants.PRODUCT_IMAGE_BUTTON_SHOW
      : constants.PRODUCT_IMAGE_BUTTON_HIDE,
    payload: data,
  });
  // dispatch(loaderAction(false));
};

const categoryToggleAction = (Boolean) => async (dispatch) => {
  Boolean
    ? dispatch({
        type: constants.CATEGORY_TOGGLE_TRUE,
        filter: true,
      })
    : dispatch({
        type: constants.CATEGORY_TOGGLE_FALSE,
      });
};

const showColorAndPriceAction = (id, type) => async (dispatch) => {
  dispatch({ type: constants.PRODUCT_PRICE_COLOR_REQUEST });

  const { data } = await axios.post(Api.getSizeColor, {
    subCategory: type === "subCat" ? id : "",
    category: type === "cat" ? id : "",
    subSubCategory: type === "subSubCat" ? id : "",
  });

  if (data.status === 0) {
    dispatch({ type: constants.PRODUCT_PRICE_COLOR_FAIL, error: data.message });
  } else {
    dispatch({
      type: constants.PRODUCT_PRICE_COLOR_SUCCESS,
      payload: data.data,
    });
  }
};

const showProductWithFilterAction = () => async (dispatch) => {
  dispatch(loaderAction(true));
  dispatch({
    type: constants.PRODUCT_WITH_FILTER_REQUEST,
    loader: true,
  });

  const { data } = await axios.post(Api.getProducts, {
    colour: "",
    size: "",
    subSubCategoryName: "",
    price: "",
    category: "",
    subCategory: "",
  });

  if (data.status === 0) {
    dispatch({
      type: constants.PRODUCT_WITH_FILTER_FAIL,
      loader: false,
    });
  } else {
    const arr = data.data;
    const maxPrice = Math.max(...arr.map((o) => parseInt(o.price)));

    let len = maxPrice.toString().length;

    if (len > 1) {
      var formattedNumber = ("0" + maxPrice).slice(0, len);
    }

    const minPrice = Math.min(...arr.map((o) => parseInt(o.price)));

    dispatch({
      type: constants.PRODUCT_WITH_FILTER_SUCCESS,
      payload: {
        message: data.message,
        data: data.data,
        min: minPrice,
        max: formattedNumber !== undefined ? parseInt(formattedNumber) : 10,
      },
      loader: false,
    });
  }

  dispatch(loaderAction(false));
};

const filterProductWithAllFiltersAction = (rowData) => async (dispatch) => {
  const { color, size, price, subSubCateId, subCategoryId, categoryId } =
    rowData;

  dispatch({
    type: constants.PRODUCT_FILTER_REQUEST,
    payload: { loader: true },
  });

  const { data } = await axios.post(Api.getProducts, {
    colour: color,
    size: size,
    subSubCategoryName: subSubCateId,
    price: price,
    category: categoryId,
    subCategory: subCategoryId,
  });

  if (data.status === 0) {
    dispatch({
      type: constants.PRODUCT_FILTER_SUCCESS,
      payload: { loader: false },
    });
  } else {
    dispatch({
      type: constants.PRODUCT_FILTER_WITH_CATEGORY_START,
      payload: {
        filterCatData: data.data,
        randomData: data.data,
        loader: false,
      },
      filter: true,
    });
  }
};

const showFilterSubCategoriesAction = (category) => async (dispatch) => {
  dispatch({ type: constants.PRODUCT_FILTER_CATEGORY_REQUEST, loader: true });

  const { data } = await axios.post(Api.subcategoryAndSubSubCategory, {
    category: category._id,
  });

  if (data.status === 0) {
    dispatch({
      type: constants.PRODUCT_FILTER_CATEGORY_FAIL,
      message: data.message,
      loader: false,
    });
  } else {
    dispatch({
      type: constants.PRODUCT_FILTER_CATEGORY_SUCCESS,
      message: data.message,
      payload: data.data,
      loader: false,
    });
  }
};

const searchProductAction = (data) => async (dispatch) => {
  const { productList } = store.getState().productList;

  if (data) {
    if (productList.length > 0) {
      let search = data.toLowerCase(),
        newArr = productList.filter((el) => {
          let searchValue = el.productName.toLowerCase();
          return searchValue.indexOf(search) !== -1;
        });

      productList.map((product) => {
        const size = product.size.map((element) => {
          return element.toLowerCase();
        });

        if (size.includes(data.toLowerCase())) {
          newArr.push(product);
        } else {
        }
      });

      if (newArr.length === 0) {
        dispatch({
          type: constants.PRODUCT_FILTER_WITH_CATEGORY_START,
          payload: { filterCatData: newArr, randomData: newArr },
          filter: true,
        });
      } else {
        dispatch({
          type: constants.PRODUCT_FILTER_WITH_CATEGORY_START,
          payload: { filterCatData: newArr, randomData: newArr },
          filter: true,
        });
      }
    }
  } else {
    dispatch({
      type: constants.PRODUCT_FILTER_WITH_CATEGORY_START,
      payload: productList,
      filter: false,
    });
  }
};

const resetFilterAction = () => async (dispatch) => {
  const { productList } = store.getState().productList;

  dispatch({
    type: constants.PRODUCT_FILTER_WITH_CATEGORY_END,
    payload: productList,
    filter: false,
  });
  dispatch({ type: constants.PRODUCT_PRICE_COLOR_FAIL });
  dispatch({ type: constants.RESET_COLOR });
  dispatch({ type: constants.RESET_SIZE });
  dispatch({ type: constants.RESET_PRICE });
  dispatch({ type: constants.RESET_CATEGORY });

  dispatch({ type: constants.RESET_SUB_CATEGORY });
  dispatch({ type: constants.RESET_SUB_SUB_CATEGORY });

  dispatch({ type: constants.PRODUCT_FILTER_INPUT_VALUE_RESET });
  dispatch({ type: constants.CATEGORY_TOGGLE_FALSE });
};

// Add Product Details to Product *************************

const addProductCategoriesIdAction = (category) => async (dispatch) => {
  dispatch({ type: constants.ADD_PRODUCT_IDS, payload: category });
};

const addFrontImageProductAction = (data) => async (dispatch) => {
  dispatch({ type: constants.FRONT_IMAGE_LIST, payload: data });
};

const addMultipleProductImageAction = (data) => async (dispatch) => {
  dispatch({ type: constants.MULTIPLE_IMAGE_LIST, payload: data });
};

const resetProductDetails = () => async (dispatch) => {
  dispatch({ type: constants.RESET_FRONT_IMAGE_LIST });
  dispatch({ type: constants.RESET_PRODUCT_IDS });
  dispatch({ type: constants.RESET_MULTIPLE_IMAGE_LIST });
  dispatch({ type: constants.SINGLE_PRODUCT_DETAILS_RESET });
};

const priceProductFilterAction = (price, max, rowData) => async (dispatch) => {
  const { productList } = store.getState().productList;

  dispatch({
    type: constants.PRODUCT_FILTER_INPUT_VALUE_SAVE,
    payload: rowData,
  });
  const { filterCatData } = store.getState().filterCatData;

  let Boolean = false;
  let newArr = [];
  if (filterCatData) {
    if (filterCatData.length > 0) {
      Boolean = true;
    } else {
      Boolean = false;
    }
  }

  if (Boolean) {
    if (filterCatData.length > 0) {
      newArr = filterCatData.filter((item) => {
        return (
          parseInt(item.price) >= Math.floor((price[0] * max) / 8) &&
          parseInt(item.price) <= Math.floor((price[1] * max) / 8)
        );
      });
    }
  } else {
    if (productList.length > 0) {
      newArr = productList.filter((item) => {
        return (
          parseInt(item.price) >= Math.floor((price[0] * max) / 8) / 8 &&
          parseInt(item.price) <= Math.floor((price[1] * max) / 8)
        );
      });
    }
  }

  if (newArr.length === 0) {
    dispatch({
      type: constants.PRODUCT_FILTER_WITH_CATEGORY_START,
      payload: { filterCatData: filterCatData, randomData: newArr },

      filter: true,
    });
  } else {
    dispatch({
      type: constants.PRODUCT_FILTER_WITH_CATEGORY_START,
      payload: { filterCatData: filterCatData, randomData: newArr },

      filter: true,
    });
  }
};

const savePriceFilterDataInStore = (data) => async (dispatch) => {
  const { sizeData } = store.getState().sizeData;
  const { colorData } = store.getState().colorData;

  const { categoryData } = store.getState().categoryData;
  const { categorySubData } = store.getState().categoryData;
  const { categorySubSubData } = store.getState().categoryData;

  let rowData = {
    price: data,
    size: sizeData,
    color: colorData,
    subSubCateId: categorySubSubData ? categorySubSubData : "",
    categoryId: categoryData ? categoryData : "",
    subCategoryId: categorySubData ? categorySubData : "",
  };
  dispatch({
    type: constants.PRODUCT_FILTER_INPUT_VALUE_SAVE,
    payload: rowData,
  });
  dispatch({
    type: constants.SAVE_PRICE,
    payload: data,
  });
};

const saveColorFilterDataInStore = (data) => async (dispatch) => {
  const { sizeData } = store.getState().sizeData;
  const { priceData } = store.getState().priceData;
  const { categoryData } = store.getState().categoryData;
  const { categorySubData } = store.getState().categoryData;
  const { categorySubSubData } = store.getState().categoryData;
  let rowData = {
    price: priceData,
    size: sizeData,
    color: data,
    subSubCateId: categorySubSubData ? categorySubSubData : "",
    categoryId: categoryData ? categoryData : "",
    subCategoryId: categorySubData ? categorySubData : "",
  };
  dispatch({
    type: constants.SAVE_COLOR,
    payload: data,
  });
  dispatch({
    type: constants.PRODUCT_FILTER_INPUT_VALUE_SAVE,
    payload: rowData,
  });
};

const saveSizeFilterDataInStore = (data) => async (dispatch) => {
  const { colorData } = store.getState().colorData;
  const { priceData } = store.getState().priceData;
  const { categoryData } = store.getState().categoryData;
  const { categorySubData } = store.getState().categoryData;
  const { categorySubSubData } = store.getState().categoryData;
  let rowData = {
    price: priceData,
    size: data,
    color: colorData,
    subSubCateId: categorySubSubData ? categorySubSubData : "",
    categoryId: categoryData ? categoryData : "",
    subCategoryId: categorySubData ? categorySubData : "",
  };
  dispatch({
    type: constants.SAVE_SIZE,
    payload: data,
  });
  dispatch({
    type: constants.PRODUCT_FILTER_INPUT_VALUE_SAVE,
    payload: rowData,
  });
};

const saveCategoryIdsInStore = (data) => async (dispatch) => {
  dispatch({
    type: constants.SAVE_CATEGORY,
    payload: data,
  });
};
const saveSubCategoryIdsInStore = (data) => async (dispatch) => {
  console.log(data, "sub ============ id");
  dispatch({
    type: constants.SAVE_SUB_CATEGORY,
    payload: data,
  });
};
const saveSubSubCategoryIdsInStore = (data) => async (dispatch) => {
  console.log(data, "sub sub id");
  dispatch({
    type: constants.SAVE_SUB_SUB_CATEGORY,
    payload: data,
  });
};

const saveSingleProductDetailsAction = (data) => async (dispatch) => {
  dispatch({
    type: constants.SINGLE_PRODUCT_DETAILS_SAVE,
    payload: { data: data, isEditStatus: true },
  });
};

export {
  saveSubCategoryIdsInStore,
  saveSubSubCategoryIdsInStore,
  savePriceFilterDataInStore,
  saveColorFilterDataInStore,
  saveSizeFilterDataInStore,
  categoryToggleAction,
  showAddProductImageAction,
  showProductWithFilterAction,
  showColorAndPriceAction,
  productPageReloadAction,
  showFilterSubCategoriesAction,
  searchProductAction,
  resetFilterAction,
  filterProductWithAllFiltersAction,
  addProductCategoriesIdAction,
  addFrontImageProductAction,
  addMultipleProductImageAction,
  resetProductDetails,
  priceProductFilterAction,
  saveCategoryIdsInStore,
  saveSingleProductDetailsAction,
};
