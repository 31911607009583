import React, { useEffect } from "react";
import MaterialTable from "material-table";
import {
  DeleteIcon,
  EditTableIcon,
} from "../../utils/MenusList/Icons";
import Box from "@mui/material/Box";

import { Api } from "../../utils/API/Api";
import LinearProgressWithLabel from "./../../Loader/LinearProgessWithLabel";
import { deleteAPI } from "../../middleware/apiMiddleware";
import { loaderAction } from "../../services/actions/loader.action";
import { useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { useState } from "react";
import { deliverModalAction, getDeliveryAction } from "../../services/actions/deliveryFee.action";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";


export default function DeliveryFeesTable() {

  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const { data, loading } = useSelector(state => state.deliveryData);


  useEffect(() => {
    dispatch(getDeliveryAction())
  }, [])


  const columns = [
    {
      title: "#",
      field: "index",
    },
    { title: "Location", field: "location" },
    { title: "Fees", field: "price" },
  ];

  const feeList = data
    ? data.map((fee, index) => {
      return {
        index: index + 1,
        location: fee?.location,
        price: fee?.price,
        _id: fee?._id,
        data: fee,
      };
    })
    : [];
  const options = {
    print: true,
    download: true,
    filter: true,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 50, 100],
    actionsColumnIndex: -1,
  };

  const handleDelete = async (id) => {
    dispatch(loaderAction(true));

    const { data, error } = await deleteAPI(Api.deleteDeliveryFee, id);

    if (!error) {
      if (data.status === 1) {
        dispatch(getDeliveryAction())
        alert.success(data.message);
      } else {
        alert.error(data.message);
      }
    } else {
      alert.error(error.message);
    }
    dispatch(loaderAction(false));
  };

  const actions = [
    {
      icon: EditTableIcon,
      tooltip: "Edit Order",
      onClick: (event, rowData) => {
        history.push("/add_delivery_fee");
        dispatch(deliverModalAction("EDIT_FEE", rowData));
      },
      className: "view_icon",
    },
    {
      icon: DeleteIcon,
      tooltip: "Delete Order",
      onClick: (event, rowData) => handleDelete(rowData._id),
    },
  ];



  return (
    <Box>

      {loading ? (
        <LinearProgressWithLabel />
      ) : (
        <MaterialTable
          options={options}
          data={feeList}
          columns={columns && columns}
          title={""}
          onRowClick={(event, rowData) => console.log(rowData)}
          actions={actions && actions}
        />
      )}
    </Box>
  );
}
