import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom";
import { SidebarMenuTitleIcons } from "../../utils/MenusList/SidebarMenu";
import { ActiveInactive } from "../../utils/SidebarActiveInactive";
import { sidebarStyles } from "../../utils/useStyles";
import logoImg from "./logo.png";
import "./Sidebar.css";
import UserInfoMenu from "./UserInfoMenu";
import { LinearProgress } from "@material-ui/core";

function Sidebar({ loading }) {
  const { reloadStatus } = useSelector((state) => state.reloadStatus);
  const classes = sidebarStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  let location = useLocation();

  const [active, setActive] = React.useState("/");
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    ActiveInactive(setActive, location);
  }, [active, location]);

  let sideBarMenus = SidebarMenuTitleIcons;
  const productRoute = () => {
    // <Redirect to="/products" />;
    if (!reloadStatus) {
      // window.location.href = "/products";
    }
  };

  return (
    <div>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>

          <UserInfoMenu />
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <div className="sidebar_logo">
            <img src={logoImg} alt="" srcSet="" className="sidebar_logo_icon" />
          </div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>

        <List>
          {sideBarMenus &&
            sideBarMenus.map((menu, index) => (
              <Link
                key={index}
                onClick={() => menu.title === "Products" && productRoute()}
                to={
                  ("/",
                  menu.title === "Logout"
                    ? "/login"
                    : menu.title === "Dashboard"
                    ? ""
                    : menu.title === "Users"
                    ? "users"
                    : menu.title === "Category"
                    ? "category"
                    : menu.title === "Orders"
                    ? "orders"
                    : menu.title === "Products"
                    ? "products"
                    : menu.title === "Commission"
                    ? "commission"
                    : menu.title === "Driver User"
                    ? "driver_user"
                    : menu.title === "Content"
                    ? "content"
                    : menu.title === "Sub Admin"
                    ? "sub_admin"
                    : menu.title === "Delivery Fees"
                    ? "delivery_fee"
                    : menu.title === "Transactions"
                    ? "transactions"
                    : menu.title === "Notifications"
                    ? "notifications"
                    : menu.title === "Report/Feedback"
                    ? "report_feedback"
                    : menu.title === "Video"
                    ? "video"
                    : menu.title === "Attributes"
                    ? "attributes"
                    : menu.title)
                }
              >
                <ListItem
                  button
                  className={menu.title === active ? "menu_btn_active" : null}
                >
                  <ListItemIcon className={"menu_icon"}>
                    {menu.icons}
                  </ListItemIcon>
                  <ListItemText primary={menu.title} />
                </ListItem>
              </Link>
            ))}
        </List>
        <Divider />
      </Drawer>
    </div>
  );
}

export default Sidebar;
