export const headingToggle = (setHeading, location, params) => {
  console.log(params);
  setHeading(
    location.pathname.substring(1) === "dashboard"
      ? "Dashboard"
      : location.pathname.substring(1) === "dashboard_reseller"
        ? "Resellers"
        : location.pathname.substring(1) === "dashboard_transaction"
          ? "Transaction"
          : location.pathname.substring(1) === "users"
            ? "Users"
            : location.pathname.substring(1) === "users_details"
              ? "Users details"
              : location.pathname.substring(1) === "order_details"
                ? "Order details"
                : location.pathname.substring(1) === "category"
                  ? "Category"
                  : location.pathname.substring(1) === "orders"
                    ? "Orders"
                    : location.pathname.substring(1) === "products"
                      ? "Products"
                      : location.pathname.substring(1) === "add_products"
                        ? "Add Products"
                        : location.pathname.substring(1) === "add_product_details"
                          ? "Add Product Details"
                          : location.pathname.substring(1) === "edit_product_details"
                            ? "Edit Product Details"
                            : location.pathname.substring(1) === "commission"
                              ? "Commission"
                              : location.pathname.substring(1) === "driver_user"
                                ? "Driver User"
                                : location.pathname.substring(1) === "add_driver"
                                  ? "Add Driver"
                                  : location.pathname.substring(1) === "content"
                                    ? "Content"
                                    : location.pathname.substring(1) === "sub_admin"
                                      ? "Sub Admin"
                                      : location.pathname.substring(1) === "delivery_fee"
                                        ? "Delivery Fees"
                                        : location.pathname.substring(1) === "transactions"
                                          ? "Transactions"
                                          : location.pathname.substring(1) === "add_notifications"
                                            ? "Add Notifications"
                                            : location.pathname.substring(1) === "notifications"
                                              ? "Notifications"
                                              : location.pathname.substring(1) === "report_feedback"
                                                ? "Reports/Feedback"
                                                : location.pathname.substring(1) === "video"
                                                  ? "Video"
                                                  : location.pathname.substring(1) === "attributes"
                                                    ? "Attributes"
                                                    : location.pathname.substring(1) === "add_attribute"
                                                      ? "Add Attributes"
                                                      : location.pathname.substring(1) === "edit_attribute"
                                                        ? "Edit Attributes"
                                                        : location.pathname.substring(1)
                                                          ? "Resellers"
                                                          : location.pathname.substring(1) === "dashboard_transaction"
                                                            ? "Transaction"
                                                            : location.pathname.substring(1) === "users"
                                                              ? "Users"
                                                              : location.pathname.substring(1) === "users_details"
                                                                ? "Users details"
                                                                : location.pathname.substring(1) === "order_details"
                                                                  ? "Order details"
                                                                  : location.pathname.substring(1) === "category"
                                                                    ? "Category"
                                                                    : location.pathname.substring(1) === "orders"
                                                                      ? "Orders"
                                                                      : location.pathname.substring(1) === "products"
                                                                        ? "Products"
                                                                        : location.pathname.substring(1) === "add_products"
                                                                          ? "Add Products"
                                                                          : location.pathname.substring(1) === "add_product_details"
                                                                            ? "Add Product Details"
                                                                            : location.pathname.substring(1) === "edit_product_details"
                                                                              ? "Edit Product Details"
                                                                              : location.pathname.substring(1) === "commission"
                                                                                ? "Commission"
                                                                                : location.pathname.substring(1) === "driver_user"
                                                                                  ? "Driver User"
                                                                                  : location.pathname.substring(1) === "add_driver"
                                                                                    ? params
                                                                                      ? "Edit Driver"
                                                                                      : "Add Driver"
                                                                                    : location.pathname.substring(1) === "content"
                                                                                      ? "Content"
                                                                                      : location.pathname.substring(1) === "sub_admin"
                                                                                        ? "Sub Admin"
                                                                                        : location.pathname.substring(1) === "delivery_fee"
                                                                                          ? "Delivery Fees"
                                                                                          : location.pathname.substring(1) === "transactions"
                                                                                            ? "Transactions"
                                                                                            : location.pathname.substring(1) === "notifications"
                                                                                              ? "Notifications"
                                                                                              : location.pathname.substring(1) === "report_feedback"
                                                                                                ? "Reports/Feedback"
                                                                                                : location.pathname.substring(1) === "video"
                                                                                                  ? "Video"
                                                                                                  : location.pathname.substring(1) === "attributes"
                                                                                                    ? "Attributes"
                                                                                                    : location.pathname.substring(1) === "add_attribute"
                                                                                                      ? "Add Attributes"
                                                                                                      : location.pathname.substring(1) === "edit_attribute"
                                                                                                        ? "Edit Attributes"
                                                                                                        : location.pathname.substring(1)
  );
};
