import React from "react";
import "./Login.css";
function LeftSideBanner() {
  return (
    <div className="left_side_bar">
      <div className="logo ">
        <img src="assets/images/logo.png" alt="" srcSet="" />
      </div>
      <div className="logo_image">
        <img src="assets/images/login-img.svg" alt="" srcSet="" />
      </div>
    </div>
  );
}

export default LeftSideBanner;
