import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./components/Login/Login";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import Dashboard from "./components/Dashboard/Dashboard";
import Users from "./components/Users/Users";
import Category from "./components/Category/Category";
import Orders from "./components/Orders/Orders";
import Products from "./components/Products/Products";
import AddProducts from "./components/Products/AddProducts/AddProducts";
import Commissions from "./components/Commissions/Commissions";
import DriverUsers from "./components/DriverUsers/DriverUsers";
import Content from "./components/Content/Content";
import SubAdmin from "./components/SubAdmin/SubAdmin";
import DeliveryFees from "./components/DeliveryFees/DeliveryFees";
import Transactions from "./components/Transactions/Transactions";
import Notifications from "./components/Notifications/Notifications";
import ReportsFeedback from "./components/ReportsFeedback/ReportsFeedback";
import Attributes from "./components/Attributes/Attributes";
import EditAttribute from "./components/Attributes/EditAttribute";
import AddAttribute from "./components/Attributes/AddAttribute";
import Video from "./components/Video/Video";

import Reseller from "./components/Dashboard/Reseller/Reseller";
import TransactionDashboard from "./components/Dashboard/Transactions/TransactionDashboard";
import UserDetail from "./components/Users/UserDetails/UserDetail";
import { useSelector } from "react-redux";
import Loader from "./Loader/Loader";
import PrivateRoute from "./HOC/PrivateRoute";
import Error404 from "./Error404/Error404";
import OrderDetails from "./components/Users/UserDetails/OrderDetails";
import "./App.css";
import AddProductDetails from "./components/Products/AddProducts/AddProductDetails";
import { useDispatch } from "react-redux";
import AddDriver from "./components/DriverUsers/AddDriver";
import AddNotifications from "./components/Notifications/AddNotifications";
import DragCategories from "./components/Category/DragCategories";
import AddEditDeliveryFee from "./components/DeliveryFees/AddEditDeliveryFee";
import { getAuthentication, getEmail, getSession } from "./helper/Cookies";
import { constants } from "./services/constants";
import RedirectRoute from "./HOC/RedirectRoute";

function App() {
  const { loader } = useSelector((state) => state.loader);

  const [wait, setWait] = useState(true);

  const dispatch = useDispatch();

  const checkIsLoggedIn = () => {
    var token = getAuthentication();

    if (token) {
      var email = getEmail();
      dispatch({
        type: constants.LOGIN_SUCCESS,
        payload: { token: token, email: email },
      });
    } else {
      // getSession
      var sessionToken = getSession();
      if (sessionToken) {
        var email = getEmail();
        dispatch({
          type: constants.LOGIN_SUCCESS,
          payload: { token: sessionToken, email: email },
        });
      }
    }
    setWait(false);
  };

  React.useEffect(() => {
    checkIsLoggedIn();
  }, []);

  return (
    <>
      {loader && <Loader />}

      {!wait ? (
        <Router>
          <Switch>
            <PrivateRoute path="/" exact component={Dashboard} />
            <RedirectRoute path="/login" exact component={Login} />
            <RedirectRoute exact path="/forgot" component={ForgotPassword} />

            <PrivateRoute path="/drag" exact component={DragCategories} />
            <PrivateRoute
              path="/dashboard_reseller"
              exact
              component={Reseller}
            />
            <PrivateRoute
              path="/dashboard_transaction"
              exact
              component={TransactionDashboard}
            />
            {/* //user */}

            <PrivateRoute path="/users" exact component={Users} />
            <PrivateRoute path="/users_details" exact component={UserDetail} />
            <PrivateRoute
              path="/order_details"
              exact
              component={OrderDetails}
            />

            {/* Category PrivateRoute */}
            <PrivateRoute path="/category" exact component={Category} />
            <PrivateRoute path="/orders" exact component={Orders} />
            {/* Product PrivateRoute */}
            <PrivateRoute path="/products" exact component={Products} />
            <PrivateRoute path="/add_products" exact component={AddProducts} />
            <PrivateRoute
              path="/add_product_details"
              exact
              component={AddProductDetails}
            />

            <PrivateRoute
              path="/edit_product_details"
              exact
              component={AddProductDetails}
            />

            <PrivateRoute path="/commission" exact component={Commissions} />
            <PrivateRoute path="/driver_user" exact component={DriverUsers} />
            <PrivateRoute path="/add_driver" exact component={AddDriver} />
            <PrivateRoute path="/content" exact component={Content} />
            <PrivateRoute path="/sub_admin" exact component={SubAdmin} />
            <PrivateRoute path="/delivery_fee" exact component={DeliveryFees} />
            <PrivateRoute
              path="/add_delivery_fee"
              exact
              component={AddEditDeliveryFee}
            />
            <PrivateRoute path="/transactions" exact component={Transactions} />
            <PrivateRoute
              path="/notifications"
              exact
              component={Notifications}
            />
            <PrivateRoute
              path="/add_notifications"
              exact
              component={AddNotifications}
            />
            <PrivateRoute
              path="/report_feedback"
              exact
              component={ReportsFeedback}
            />
            <PrivateRoute path="/video" exact component={Video} />
            <PrivateRoute path="/attributes" exact component={Attributes} />
            <PrivateRoute
              path="/add_attribute"
              exact
              component={AddAttribute}
            />
            <PrivateRoute
              path="/edit_attribute"
              exact
              component={EditAttribute}
            />
            <Route component={Error404} />
          </Switch>
        </Router>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <center>
            <div class="spinner-border text-dark" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </center>
        </div>
      )}
    </>
  );
}

export default App;
