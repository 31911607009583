import Button from "@restart/ui/esm/Button";
import { Modal } from "react-bootstrap";
import { addFrontImageProductAction } from "../../../services/actions/product.action";
import { Api } from "../../../utils/API/Api";
import axios from "axios";
import { useDispatch } from "react-redux";
import { PlusIcon } from "../../../utils/MenusList/Icons";
import { useAlert } from "react-alert";
import { loaderAction } from "../../../services/actions/loader.action";

function EditProductModal(props) {
  const dispatch = useDispatch();

  const alert = useAlert();
  const handleImage = async (e) => {
    e.preventDefault();
    dispatch(loaderAction(true));
    const file = e.target.files;

    const fileData = file === null ? null : file[0];

    var myForm = new FormData();
    myForm.append("image", fileData);
    e.target.value = null;
    const { data } = await axios.post(Api.uploadSingleImage, myForm);
    if (data.status === 1) {
      dispatch(addFrontImageProductAction(data.image));
      alert.success(data.message);
      props.onHide();
      dispatch(loaderAction(false));
    } else {
      alert.error(data.message);
      dispatch(loaderAction(false));
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Product Image
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <Link to="/add_product_details">Add Product Detail</Link> */}
        <div className="add_product_aside_content">
          <h5>Men Fashion/ Western Wear/ T-shirt</h5>
          <div>
            <h6>Please provide only front image for each product</h6>
            <input
              type="file"
              accept="image/*"
              id="contained-button-file"
              style={{ display: "none" }}
              onChange={(e) => handleImage(e)}
            />
            <div className="file">
              <label htmlFor="contained-button-file" className="file_upload">
                <PlusIcon className="upload_image" />
              </label>
            </div>
            <Button htmlFor="contained-button-file">
              <img src="assets/images/upload-product-img.svg" alt="" />
              Edit Product Image
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

// function App() {
//   const [modalShow, setModalShow] = React.useState(false);

//   return (
//     <>
//       <Button variant="primary" onClick={() => setModalShow(true)}>
//         Launch vertically centered modal
//       </Button>

//       <MyVerticallyCenteredModal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//       />
//     </>
//   );
// }

// render(<App />);

export { EditProductModal };
