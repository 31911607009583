import Sidebar from "../Sidebar/Sidebar";
import Heading from "../Heading/Heading";
import { useStyles } from "../../utils/useStyles";
import { Button, Col, Row } from "react-bootstrap";
import "./Content.css";
function Content() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading />

        {/* Data table */}

        <div className='card_box'>
          <Row>

            <Col sm={12} xl={6}>
              <div className="content_box">
                <label >Policies & cookies</label>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
              </div>
            </Col>
            <Col sm={12} xl={6}>
              <div className="content_box">
                <label >About Us</label>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
              </div>
            </Col>
            <Col sm={12} xl={6}>
              <div className="content_box">
                <label >Terms & Condition</label>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
              </div>
            </Col>
            <Col sm={12} xl={6}>
              <div className="content_box">
                <label >Articles</label>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
              </div>
            </Col>
            <Col sm={12} xl={6}>
              <div className="content_box">
                <label >Facebook</label>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
              </div>
            </Col>
            <Col sm={12} xl={6}>
              <div className="content_box">
                <label >Instagram</label>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
              </div>
            </Col>
            <Col sm={12} xl={6}>
              <div className="content_box">
                <label >Whatsapp</label>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
              </div>
            </Col>
            <Col sm={12} xl={12}>
              <div className="submit_product text-right">
                <Button className="btn_submit">Add FAQ</Button>
              </div>
            </Col>
          </Row>


          <Row>
            <Col sm={12} xl={6}>
              <div className="content_box">
                <label >What is Lorem Ipsum</label>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
              </div>
            </Col>
            <Col sm={12} xl={6}>
              <div className="content_box">
                <label >What is Lorem Ipsum</label>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
              </div>
            </Col>
            <Col sm={12} xl={6}>
              <div className="content_box">
                <label >What is Lorem Ipsum</label>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
              </div>
            </Col>
            <Col sm={12} xl={6}>
              <div className="content_box">
                <label >What is Lorem Ipsum</label>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
              </div>
            </Col>
            <Col sm={12} xl={12}>
              <div className="submit_product text-right">
                <Button className="btn_cancel">Cancel</Button>
                <Button className="btn_submit">Submit</Button>
              </div>
            </Col>
          </Row>

        </div>
      </main>
    </div>
  );
}

export default Content;
