import React from "react";
import { Col, Row } from "react-bootstrap";
import { TopSellingProductList } from "../../utils/MenusList/TopSellingProductList";
import SellingProductRange from "./SellingProductRange";
import SortByButton from "./SortByButton";

function TopSellingProductFilter() {
  return (
    <>
      <Row>
        <Col md={8}>
          <h6>Top-Selling Product</h6>
        </Col>
        <Col md={4}>
          <SortByButton />
        </Col>
      </Row>

      <Row className="mt-4">
        {TopSellingProductList.map((product, index) => (
          <Row key={index}>
            <Col md={4}>
              <p className="text_product_title">{product.title}</p>
            </Col>
            <Col md={8}>
              <SellingProductRange value={product.max} />
            </Col>
          </Row>
        ))}
      </Row>
    </>
  );
}

export default TopSellingProductFilter;
