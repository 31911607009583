import React, { useRef, useState, useEffect } from 'react'
import { useStyles } from './../../utils/useStyles';
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import { useAlert } from 'react-alert';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { customPutAPI, getCoordinates } from '../../middleware/apiMiddleware';
import { loaderAction } from '../../services/actions/loader.action';
import { customPostAPI } from './../../middleware/apiMiddleware';
import { Api } from '../../utils/API/Api';
import { getDeliveryAction } from '../../services/actions/deliveryFee.action';
import Sidebar from '../Sidebar/Sidebar';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import Heading from '../Heading/Heading';
import { useHistory } from 'react-router-dom';

const AddEditDeliveryFee = () => {
    const classes = useStyles();
    let API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: API_KEY,
        libraries: ["places"],
    });

    const { type, title, location_label, price_label, rowData } = useSelector(
        (state) => state.deliveryAction
    );

    console.log(type)
    const alert = useAlert();
    const originRef = useRef();
    const dispatch = useDispatch();
    const history = useHistory();
    const [location, setLocation] = useState("");
    const [price, setPrice] = useState("");
    const emptyFrom = () => {
        setLocation("");
        setPrice("");
    };
    const [formData, setFormData] = useState({
        location: "",
    })

    const calculateDist = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let input = { ...formData };
        if (!originRef.current.value) {
            return;
        }
        const { data } = await getCoordinates(value);

        if (data.status === "OK") {

            const { formatted_address, geometry } = data?.results[0];
            let latitude = geometry?.location.lat;
            let longitude = geometry?.location.lng;
            input[name] = formatted_address
            setFormData(input);
        }
    }


    const handleSubmit = async () => {
        if (!formData.location || !price) {
            alert.error("All fields are required");
            return false;
        }
        dispatch(loaderAction(true));

        let body = {
            location: formData.location,
            price,
        };

        const { data, error } = await customPostAPI(Api.addDeliveryFee, body);
        if (!error) {
            if (data.status === 1) {
                alert.success(data.message);
                dispatch(getDeliveryAction())

                emptyFrom();
                history.push("/delivery_fee")

            } else {
                alert.error(data.message);
            }
        } else {
            alert.error(error.message);
        }
        dispatch(loaderAction(false));
    };


    const handleEditDelivery = async () => {
        if (!formData.location || !price) {
            alert.error("All fields are required");
            return false;
        }
        dispatch(loaderAction(true));

        let body = {
            location: formData.location,
            price,
            _id: rowData?._id
        };
        const { data, error } = await customPutAPI(Api.editDeliveryFee, body);
        if (!error) {
            if (data.status === 1) {
                alert.success(data.message);
                dispatch(getDeliveryAction())

                emptyFrom();
                history.push("/delivery_fee")

            } else {
                alert.error(data.message);
            }
        } else {
            alert.error(error.message);
        }
        dispatch(loaderAction(false));
    };


    useEffect(() => {
        setFormData({ location: rowData?.location });
        setPrice(rowData?.price);
        if (!type) {
            return history.push("/delivery_fee")
        }
    }, [rowData])


    return (
        <div className={classes.root}>
            <Sidebar />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Heading type={type} />
                <div className="card_box">
                    <Container>
                        {isLoaded ? (
                            <Autocomplete>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Address </Form.Label>
                                    <Form.Control
                                        type="address"
                                        placeholder="Enter address"
                                        name="location"
                                        defaultValue={formData.location}
                                        ref={originRef}
                                        onBlur={(e) => calculateDist(e)}
                                        required={true}
                                    />
                                </Form.Group>
                            </Autocomplete>
                        ) : (
                            "Something went wrong with your location api"
                        )}



                        <div className="form-group">
                            <label>{price_label && price_label}</label>
                            <input
                                type="text"
                                placeholder={`Enter ${price_label && price_label} name`}
                                autoComplete="off"
                                className=" form-control "
                                value={price || ""}
                                onChange={(e) => setPrice(e.target.value)}
                            />
                        </div>

                        <Row>
                            <Col sm={12} xl={12}>
                                <div className="submit_product text-right">
                                    <Button
                                        className="btn_cancel"
                                        onClick={() => {
                                            emptyFrom();
                                            history.push("/delivery_fee")
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    {type == "Edit" ?
                                        <Button onClick={handleEditDelivery} className="btn_submit">
                                            Update
                                        </Button> :
                                        <Button onClick={handleSubmit} className="btn_submit">

                                            Submit
                                        </Button>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </div>

            </main>
        </div>
    );
}


export default AddEditDeliveryFee