import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { customPostAPI } from "../../middleware/apiMiddleware";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import {
  categoryModalAction,
  getCategoryAction,
  hideToggleCategory,
  resetCategoryDataAction,
  toggleSubCategoryAction,
} from "../../services/actions/category.action";
import { Api } from "../../utils/API/Api";
import { loaderAction } from "./../../services/actions/loader.action";
import CategoryModal from "./CategoryModal";
import DragCategories from "./DragCategories";
function AddCategory() {
  let type = "EDIT_CATEGORY";
  const { categoryList, reOrderCategoryList } = useSelector(
    (state) => state.categoryList
  );
  const { categoryData } = useSelector((state) => state.subCategoryToggle);

  const dispatch = useDispatch();
  const alert = useAlert();

  const [action, setAction] = useState(true);
  useEffect(() => {
    dispatch(getCategoryAction());
  }, [dispatch]);
  let location = useLocation();

  useEffect(() => {
    if (location.pathname === "/add_products") {
      setAction(false);
    } else {
      setAction(true);
    }
  }, []);

  const handleDelete = async (id) => {
    dispatch(loaderAction(true));
    let body = {
      _id: id,
    };
    const { data, error } = await customPostAPI(Api.deleteCategory, body);
    if (!error) {
      if (data.status === 1) {
        dispatch(getCategoryAction());
        if (id == categoryData?._id) {
          dispatch(toggleSubCategoryAction());
        }
        alert.success(data.message);
      } else {
        alert.error(data.message);
      }
    } else {
      alert.error(error.message);
    }
    dispatch(loaderAction(false));
  };
  const [modalShow, setModalShow] = React.useState(false);
  const handleOpen = async () => {
    dispatch(loaderAction(true));

    let open = true;
    let type = "ADD_CATEGORY";
    let id = null;

    let data = null;
    dispatch(categoryModalAction(type, id, data, open));
    setModalShow(true);
    dispatch(loaderAction(false));
  };

  // console.log(reOrderCategoryList, "categoryList=====");

  return (
    <div>
      <div className="card_box">
        <div className="d-flex justify-content-between ">
          <div>
            <h6>{location.pathname !== "/add_products" && "Add "}Category</h6>
          </div>
          <div>
            {location.pathname !== "/add_products" && (
              <AddCircleIcon
                onClick={() => {
                  handleOpen();
                }}
                className="btn_add_Category"
              />
            )}
            <CategoryModal modalShow={modalShow} setModalShow={setModalShow} />
          </div>
        </div>
        {action ? (
          <DragCategories
            reOrderCategoryList={reOrderCategoryList}
            type={"Category"}
          />
        ) : (
          <div className="category_list">
            {categoryList &&
              categoryList.map((category, index) => (
                <div
                  key={index}
                  className={
                    category?._id === categoryData?._id
                      ? "menu_btn_active"
                      : null
                  }
                >
                  <div className="d-flex justify-content-start category_box_container">
                    <div>
                      <img
                        src={category.categoryImage}
                        className="category_icon"
                        alt=""
                      />
                    </div>
                    <div
                      className="category_name_text"
                      onClick={() =>
                        dispatch(toggleSubCategoryAction(category, true))
                      }
                    >
                      <h5 className="category_name">{category.categoryName}</h5>
                    </div>
                    <div>
                      <img
                        src="assets/images/arrow_right.svg"
                        className="category_arrow"
                        alt=""
                        onClick={() => {
                          dispatch(toggleSubCategoryAction(category, true));
                          dispatch(hideToggleCategory(type));
                        }}
                      />
                    </div>
                  </div>

                  <hr className="m-0" />
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default AddCategory;
