import React from "react";

import MaterialTable from "material-table";
import { EditIcon, DeleteIcon } from "../../../utils/MenusList/Icons";
import { Link } from "react-router-dom";

export default function TransactionInner() {
  const columns = [
    { title: "#", field: "id" },
    { title: "Order ID", field: "orderId" },
    { title: "User Name", field: "username" },
    { title: "Date", field: "date" },
    { title: "Total", field: "total" },
    {
      title: "Status",
      field: "status",

      render: (item) => <span className="color-primary"> {item.status}</span>,
    },
    { title: "Method", field: "method" },
  ];
  const options = {
    print: true,
    download: true,
    filter: true,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 50, 100],
    actionsColumnIndex: -1,
    search: false,
  };
  const tableData = [
    {
      id: 1,
      orderId: "GHH234",
      username: "John Doe",
      date: "28/3/2022",
      total: "$400",
      status: "status",

      method: "MasterCard",
    },
  ];
  const actions = [
    {
      icon: EditIcon,
      tooltip: "Edit Order",
      // onClick: (event, rowData) => history.push("/orders-detail"),
    },
    {
      icon: DeleteIcon,
      tooltip: "Delete Order",
      // onClick: (event, rowData) => history.push("/orders-detail"),
    },
  ];
  return (
    <>
      <div className={"table_dashboard"}>
        <MaterialTable
          options={options && options}
          data={tableData && tableData}
          columns={columns && columns}
          title={"Transactions"}
          onRowClick={(event, rowData) => console.log(rowData)}
          actions={actions && actions}
        />
      </div>
      <Link to="/dashboard_transaction">See more</Link>
    </>
  );
}
