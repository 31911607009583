import React, { useState, useEffect } from "react";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import LeftSideBanner from "./LeftSideBanner";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { isUserLoggedIn, loginAdmin } from "../../services/actions/auth.action";
import { Redirect } from "react-router-dom";
import { useAlert } from "react-alert";

function Login() {
  const dispatch = useDispatch();
  const { isAuthenticated, message, error } = useSelector(
    (state) => state.auth
  );
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [toggle, setToggle] = useState(false);
  const alert = useAlert();

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(isUserLoggedIn());
    }
  }, []);

  useEffect(() => {
    error && alert.error(error);
    message && alert.success(message);
  }, [message, alert, error]);

  React.useEffect(() => {
    let e = localStorage.getItem("Email");
    let pass = localStorage.getItem("Password");
    setEmail(e);
    setPassword(pass);
  }, []);
  const loginHandle = () => {
    let data = {
      email,
      password,
    };
    toggle && localStorage.setItem("Email", email);
    toggle && localStorage.setItem("Password", password);
    dispatch(loginAdmin(data, toggle));
  };

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  const onchange = (e) => {
    if (e.target.name == "keepMe") {
      setToggle(!toggle);
    } else {
      setToggle(e.target.value);
    }
  };

  return (
    <div className="bg-grey">
      <Container fluid>
        <Row>
          <Col className="login_left_side">
            <LeftSideBanner />
          </Col>
          <Col className="login_right_side">
            <div className="right_side_content">
              <Form className="" autoComplete="off">
                <p className="login_heading mb-4">Sign In</p>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email Address"
                    autoComplete="off"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={(e) =>
                      e.key === "Enter" ? loginHandle(e) : null
                    }
                  />
                </Form.Group>
                <div className="mt-4 mb-4">
                  <NavLink to="/forgot" className="link_forgot_password">
                    Forgot Password
                  </NavLink>
                </div>
                <div className="d-flex justify-content-between mt-4">
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Remember me"
                      checked={toggle}
                      name="keepMe"
                      id="keepMe"
                      // onChange={() => setToggle(!toggle)}
                      onChange={onchange}
                    />
                  </Form.Group>
                  <div
                    className="link_forgot_password"
                    onClick={(e) => loginHandle(e)}
                  >
                    <Button>
                      Login <ArrowRightAltIcon className="right_arrow ml-2" />
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Login;
