import * as React from "react";
import PropTypes from "prop-types";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Button } from "react-bootstrap";

import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        // <Box sx={{ p: 3 }}>
        <>{children}</>
        // </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 0,
    width: "100%",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-selected": {
      color: "#000",
      backgroundColor: "transparent",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "transparent",
    },
  })
);

function AddProductTabs({ setTab, tab }) {
  const { frontImageList } = useSelector((state) => state.frontImageList);

  const { singleProductDetailData, isEditStatus } = useSelector(
    (state) => state.singleProductDetailData
  );

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div sx={{ width: "100%" }}>
      {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}> */}

      <StyledTabs
        value={tab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        aria-label="visible arrows tabs example"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            "&.Mui-disabled": { display: "none" },
          },
        }}
      >
        <StyledTab
          {...a11yProps(0)}
          icon={
            <>
              <img
                src={
                  isEditStatus
                    ? singleProductDetailData?.images
                    : frontImageList && frontImageList
                }
                alt=""
              />

              <span>Product1</span>
            </>
          }
        />
      </StyledTabs>
      {/* </Box> */}
    </div>
  );
}

export { TabPanel, AddProductTabs };
