import React from "react";

import { Link } from "react-router-dom";


function AddAttributeButton() {

  return (
    <Link
      to="/add_attribute" className="add_driver_link"

    >
      <div className="add_product_btn">Add Attribute</div>
    </Link>
  );
}

export default AddAttributeButton;