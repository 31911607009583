import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// import { EditIcon } from "../../../utils/Icons";
import { useHistory } from "react-router-dom";

import MaterialTable from "material-table";
import { DeleteIcon, EditIcon } from "../../../utils/MenusList/Icons";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function PaymentTable() {
  const classes = useStyles();
  let history = useHistory();

  const columns = [
    { title: "#", field: "id" },
    { title: "OrderId", field: "orderId" },
    { title: "Age", field: "age" },
    { title: "Country Code", field: "countryCode" },
    { title: "Phone Number", field: "phoneNumber" },
    { title: "Created on", field: "createdOn" },
    {
      title: "Status",
      field: "status",
    },
  ];
  const options = {
    print: true,
    download: true,
    filter: true,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 50, 100],
    actionsColumnIndex: -1,
  };
  const tableData = [
    {
      id: 1,
      orderId: "John Doe",
      age: "32",
      countryCode: "+72",
      phoneNumber: "1234567890",
      createdOn: "23/04/2022",
      status: "status",
    },
  ];
  const actions = [
    // {
    //   icon: EditIcon,
    //   tooltip: "Edit Order",
    //   onClick: (event, rowData) => history.push("/users_details"),
    //   className: "view_icon",
    // },
    {
      icon: DeleteIcon,
      tooltip: "Delete Order",
      // onClick: (event, rowData) => history.push("/orders-detail"),
    },
  ];

  return (
    <MaterialTable
      options={options}
      data={tableData && tableData}
      columns={columns && columns}
      title={""}
      onRowClick={(event, rowData) => console.log(rowData)}
      actions={actions && actions}
    />
  );
}
