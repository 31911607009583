import React, { useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Heading from "../Heading/Heading";
import { useStyles } from "../../utils/useStyles";
import AToZFilter from "../Dashboard/Reseller/AToZFilter";
import UserTable from "./UserTable";
import { useDispatch } from "react-redux";
import { userAction } from "../../services/actions/user.action";
function Users() {
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userAction());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading />

        {/* Data table */}
        <div>
          <AToZFilter />

          <div className="card_box">
            <UserTable />
          </div>
        </div>
      </main>
    </div>
  );
}
export default Users;
