import React, { useState } from "react";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import Heading from "../Heading/Heading";
import Sidebar from "../Sidebar/Sidebar";
import { PlusIcon } from "../../utils/MenusList/Icons";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useStyles } from "./../../utils/useStyles";
import axios from "axios";
import { Api } from "../../utils/API/Api";
import { useAlert } from "react-alert";

import { loaderAction } from "./../../services/actions/loader.action";
import { useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useFetch } from "../../Hooks/useFetch";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function AddDriver() {
  const classes = useStyles();
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();
  let options = {
    open: false,
    vertical: "top",
    horizontal: "right",
  };
  const search = useLocation().search;
  const driverId = new URLSearchParams(search).get("id");

  const { data } = useFetch(driverId && Api.singleDriver);

  const [editAction, setEditAction] = useState(false);

  const [driverData, setDriverData] = useState({
    name: "",
    email: "",
    address: "",
    phoneNumber: "",
    image: "", // driver image
    dateOfBirth: " ",
    password: "",
    driverLicenseNumber: "",
    countryCode: "",
    insuranceReference: "",
    assignJob: " ",
    idReference: "",
    licensePhoto: "",
    insurancePhoto: "",
    idPhoto: "",
    vehicleType: "",
    vehiclePhoto: "",
    numberPlate: "",
  });
  const [progressLoading, setProgressLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = useState(null);
  const handleClick = () => {
    setOpen(true);
    setError("Entered number is not valid");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(null);

    setOpen(false);
  };

  const handleInput = (e) => {
    const { name, value, maxLength } = e.target;
    let data = { ...driverData };
    if (name === "phoneNumber") {
      const message =
        value.length < maxLength - 1 ? handleClick() : handleClose();
    }
    data[name] = value;
    setDriverData(data);
  };
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleEmailInput = (e) => {
    const { name, value, maxLength } = e.target;
    if (!isValidEmail(value)) {
      setError("Email is invalid");
      setOpen(true);
    } else {
      setError(null);
      handleClose();
    }
    let data = { ...driverData };
    data[name] = value;
    setDriverData(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let validation = await handleVerifyInputs();
    if (validation) {
      alert.error("All fields are required");
      return false;
    }
    if (driverData.phoneNumber.length < 9) {
      alert.info("Please enter a valid phoneNumber");
      dispatch(loaderAction(false));

      return false;
    }
    dispatch(loaderAction(true));

    const { data } = await axios.post(Api.addDriver, driverData);
    if (data.status === 1) {
      alert.success("Driver added successfully");
      history.push("/driver_user");
    } else {
      alert.error(data.message);
    }
    dispatch(loaderAction(false));
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    dispatch(loaderAction(true));

    driverData["_id"] = driverId;

    const { data } = await axios.put(Api.editDriver, driverData);
    if (data.status === 1) {
      alert.success("Driver updated successfully");
      history.push("/driver_user");
    } else {
      alert.error(data.message);
    }
    dispatch(loaderAction(false));
  };

  const handleImage = async (e) => {
    e.preventDefault();
    const { name } = e.target;
    setProgressLoading(true);
    dispatch(loaderAction(true));

    const file = e.target.files;

    const fileData = file === null ? null : file[0];

    var myForm = new FormData();
    myForm.append("image", fileData);
    e.target.value = null;
    const { data } = await axios.post(Api.uploadSingleImage, myForm);
    if (data.status === 1) {
      let driverImage = { ...driverData };
      driverImage[name] = data.image;
      setDriverData(driverImage);
      alert.success("Image uploaded successfully");
      setProgressLoading(false);
    } else {
      alert.error(data.message);
      setProgressLoading(false);
    }
    setProgressLoading(false);
    dispatch(loaderAction(false));
  };

  const handleVerifyInputs = () => {
    let emptyValue =
      driverData.name === "" ||
      driverData.email === "" ||
      driverData.password === "" ||
      driverData.address === "" ||
      driverData.image === "" ||
      driverData.dateOfBirth === "" ||
      driverData.driverLicenseNumber === "" ||
      driverData.countryCode === "" ||
      driverData.insuranceReference === "" ||
      driverData.assignJob === "" ||
      driverData.idReference === "" ||
      driverData.licensePhoto === "" ||
      driverData.insurancePhoto === "" ||
      driverData.idPhoto === "" ||
      driverData.vehicleType === "" ||
      driverData.numberPlate === "";

    return emptyValue;
  };

  React.useEffect(() => {
    if (data.length == 0) {
      setEditAction(false);
      return false;
    }
    if (data) {
      let driver = { ...driverData };
      setEditAction(true);
      driver["name"] = data?.name;
      driver["email"] = data?.email;
      driver["address"] = data?.address;
      driver["phoneNumber"] = data?.phoneNumber;
      driver["image"] = data?.image; // driver image
      driver["dateOfBirth"] =
        data?.dateOfBirth && data?.dateOfBirth.slice(0, 10);
      driver["driverLicenseNumber"] = data?.driverLicenseNumber;
      driver["countryCode"] = data?.countryCode;
      driver["insuranceReference"] = data?.insuranceReference;
      driver["assignJob"] = data?.assignJob;
      driver["idReference"] = data?.idReference;
      driver["licensePhoto"] = data?.licensePhoto;
      driver["insurancePhoto"] = data?.insurancePhoto;
      driver["idPhoto"] = data?.idPhoto;
      driver["vehicleType"] = data?.vehicleType;
      driver["vehiclePhoto"] = data?.vehiclePhoto;
      driver["numberPlate"] = data?.numberPlate;

      setDriverData(driver);
    } else {
      setEditAction(false);
    }
  }, [data]);

  return (
    <div className={classes.root}>
      <Sidebar loading={progressLoading} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading edit={data ? true : false} />

        <div className="card_box">
          <Stack spacing={2}>
            <Snackbar
              open={open}
              autoHideDuration={2000}
              onClose={handleClose}
              anchorOrigin={options}
              key={options}
            >
              <Alert
                onClose={handleClose}
                severity="warning"
                sx={{ width: "100%" }}
              >
                {error && error}
              </Alert>
            </Snackbar>
          </Stack>
          <Form
            className="form_activity form_add_product row mt-4 row"
            autoComplete="off"
          >
            <Form.Group className="mb-2 col-sm-12 col-md-6">
              <Form.Label className="label_grey">
                Driver Name:
                <Tooltip title="Please enter the name" placement="top">
                  <InfoIcon className="info_icon" />
                </Tooltip>
              </Form.Label>
              <InputGroup className="mb-2">
                <FormControl
                  placeholder="Enter the driver name"
                  aria-label="price"
                  aria-describedby="basic-addon1"
                  name="name"
                  value={driverData.name}
                  onChange={(e) => handleInput(e)}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2 col-sm-12 col-md-6">
              <Form.Label className="label_grey">
                Date of Birth:
                <Tooltip title="Please enter the date of birth" placement="top">
                  <InfoIcon className="info_icon" />
                </Tooltip>
              </Form.Label>
              <InputGroup className="mb-2">
                <FormControl
                  placeholder="Enter the dateOfBirth"
                  aria-label="price"
                  aria-describedby="basic-addon1"
                  name="dateOfBirth"
                  value={driverData.dateOfBirth}
                  type="date"
                  onChange={(e) => handleInput(e)}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2 col-sm-12 col-md-6">
              <Form.Label className="label_grey">
                Email
                <Tooltip title="Please enter the email" placement="top">
                  <InfoIcon className="info_icon" />
                </Tooltip>
              </Form.Label>
              <InputGroup className="mb-2">
                <FormControl
                  placeholder="Enter the email"
                  aria-label="price"
                  aria-describedby="basic-addon1"
                  name="email"
                  value={driverData.email}
                  autoComplete="off"
                  type="text"
                  onChange={(e) => handleEmailInput(e)}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2 col-sm-12 col-md-6">
              <Form.Label className="label_grey">
                Password
                <Tooltip title="Please enter the password" placement="top">
                  <InfoIcon className="info_icon" />
                </Tooltip>
              </Form.Label>
              <InputGroup className="mb-2">
                <FormControl
                  placeholder="Enter the password"
                  aria-label="price"
                  aria-describedby="basic-addon1"
                  name="password"
                  value={driverData.password}
                  type="password"
                  autoComplete="new-password"
                  onChange={(e) => handleInput(e)}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2 col-sm-12 col-md-6">
              <Form.Label className="label_grey">
                Address
                <Tooltip
                  title="Please enter the address of 100 character"
                  placement="top"
                >
                  <InfoIcon className="info_icon" />
                </Tooltip>
              </Form.Label>
              <InputGroup className="mb-2">
                <FormControl
                  placeholder="Enter the address"
                  aria-label="price"
                  aria-describedby="basic-addon1"
                  name="address"
                  maxLength={100}
                  value={driverData.address}
                  onChange={(e) => handleInput(e)}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2 col-sm-12 col-md-6">
              <Form.Label className="label_grey">
                Country Code
                <Tooltip
                  title="Please enter the country code below of 6 character"
                  placement="top"
                >
                  <InfoIcon className="info_icon" />
                </Tooltip>
              </Form.Label>
              <InputGroup className="mb-2">
                <FormControl
                  placeholder="Enter the country code"
                  aria-label="price"
                  aria-describedby="basic-addon1"
                  name="countryCode"
                  value={driverData.countryCode}
                  maxLength={5}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => handleInput(e)}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2 col-sm-12 col-md-6">
              <Form.Label className="label_grey">
                Phone Number
                <Tooltip title="Please enter the Phone Number" placement="top">
                  <InfoIcon className="info_icon" />
                </Tooltip>
              </Form.Label>
              <InputGroup className="mb-2">
                <FormControl
                  placeholder="Enter the phone number"
                  aria-label="price"
                  aria-describedby="basic-addon1"
                  name="phoneNumber"
                  maxLength={11}
                  required={true}
                  value={driverData.phoneNumber}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => handleInput(e)}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2 col-sm-12 col-md-6">
              <Form.Label className="label_grey">
                Assign Job
                <Tooltip title="Please enter the Assign Job" placement="top">
                  <InfoIcon className="info_icon" />
                </Tooltip>
              </Form.Label>
              <InputGroup className="mb-2">
                <FormControl
                  placeholder="Enter the assign job"
                  aria-label="price"
                  aria-describedby="basic-addon1"
                  name="assignJob"
                  value={driverData.assignJob}
                  onChange={(e) => handleInput(e)}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2 col-sm-12 col-md-6 align_middle">
              <Form.Label className="label_grey">
                Driver License Number
                <Tooltip
                  title="Please enter the Driver License Number"
                  placement="top"
                >
                  <InfoIcon className="info_icon" />
                </Tooltip>
              </Form.Label>
              <InputGroup className="mb-2">
                <FormControl
                  placeholder="Enter the driver license number"
                  aria-label="price"
                  aria-describedby="basic-addon1"
                  name="driverLicenseNumber"
                  value={driverData.driverLicenseNumber}
                  onChange={(e) => handleInput(e)}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2 col-sm-12 col-md-6">
              <Form.Label className="label_grey">
                Driver License Photo
                <Tooltip
                  title="Please enter the Driver License Photo"
                  placement="top"
                >
                  <InfoIcon className="info_icon" />
                </Tooltip>
              </Form.Label>
              <div className="d-flex driver_uploader">
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  id="contained-button-file-licensePhoto"
                  name="licensePhoto"
                  onChange={(e) => handleImage(e)}
                />
                <div className="file">
                  <label
                    htmlFor="contained-button-file-licensePhoto"
                    className="file_upload"
                  >
                    <PlusIcon className="upload_image" />
                  </label>
                </div>
                <div className="driver_img_container">
                  <img alt="" src={driverData?.licensePhoto} />
                </div>
              </div>
            </Form.Group>

            <Form.Group className="mb-2 col-sm-12 col-md-6 align_middle">
              <Form.Label className="label_grey">
                Insurance reference
                <Tooltip
                  title="Please enter the Insurance reference"
                  placement="top"
                >
                  <InfoIcon className="info_icon" />
                </Tooltip>
              </Form.Label>
              <InputGroup className="mb-2">
                <FormControl
                  placeholder="Enter the insurance reference"
                  aria-label="price"
                  aria-describedby="basic-addon1"
                  onChange={(e) => handleInput(e)}
                  name="insuranceReference"
                  value={driverData.insuranceReference}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2 col-sm-12 col-md-6">
              <Form.Label className="label_grey">
                Insurance Photo
                <Tooltip
                  title="Please enter the Insurance Photo"
                  placement="top"
                >
                  <InfoIcon className="info_icon" />
                </Tooltip>
              </Form.Label>
              <div className="d-flex driver_uploader">
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  id="contained-button-file-insurancePhoto"
                  name="insurancePhoto"
                  onChange={(e) => handleImage(e)}
                />
                <div className="file">
                  <label
                    htmlFor="contained-button-file-insurancePhoto"
                    className="file_upload"
                  >
                    <PlusIcon className="upload_image" />
                  </label>
                </div>
                <div className="driver_img_container">
                  {/* <div
                    className="close_icon"
                    // onClick={() => handleDeleteImage(item)}
                  >
                    {driverData?.insurancePhoto == "" ? null : (
                      <HighlightOffIcon />
                    )}
                  </div> */}
                  <img alt="" src={driverData?.insurancePhoto} />
                </div>
              </div>
            </Form.Group>

            <Form.Group className="mb-2 col-sm-12 col-md-6 align_middle">
              <Form.Label className="label_grey">
                ID reference
                <Tooltip title="Please enter the ID reference" placement="top">
                  <InfoIcon className="info_icon" />
                </Tooltip>
              </Form.Label>
              <InputGroup className="mb-2">
                <FormControl
                  placeholder="Enter the driver license number"
                  aria-label="price"
                  aria-describedby="basic-addon1"
                  onChange={(e) => handleInput(e)}
                  name="idReference"
                  value={driverData.idReference}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2 col-sm-12 col-md-6">
              <Form.Label className="label_grey">
                ID Photo
                <Tooltip title="Please enter the ID Photo" placement="top">
                  <InfoIcon className="info_icon" />
                </Tooltip>
              </Form.Label>
              <div className="d-flex driver_uploader">
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  id="contained-button-file-idPhoto"
                  name="idPhoto"
                  onChange={(e) => handleImage(e)}
                />
                <div className="file">
                  <label
                    htmlFor="contained-button-file-idPhoto"
                    className="file_upload"
                  >
                    <PlusIcon className="upload_image" />
                  </label>
                </div>
                <div className="driver_img_container">
                  <img alt="" src={driverData?.idPhoto} />
                </div>
              </div>
            </Form.Group>

            <Form.Group className="mb-2 col-sm-12 col-md-6 align_middle">
              <Form.Label className="label_grey">
                Vehicle Type
                <Tooltip title="Please enter the Vehicle Type" placement="top">
                  <InfoIcon className="info_icon" />
                </Tooltip>
              </Form.Label>
              <InputGroup className="mb-2">
                <FormControl
                  placeholder="Enter the driver Vehicle Type"
                  aria-label="Vehicle Type"
                  aria-describedby="basic-addon1"
                  onChange={(e) => handleInput(e)}
                  name="vehicleType"
                  value={driverData.vehicleType}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2 col-sm-12 col-md-6">
              <Form.Label className="label_grey">
                Vehicle Photo
                <Tooltip title="Please enter the Vehicle Photo" placement="top">
                  <InfoIcon className="info_icon" />
                </Tooltip>
              </Form.Label>
              <div className="d-flex driver_uploader">
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  id="contained-button-file-vehiclePhoto"
                  name="vehiclePhoto"
                  onChange={(e) => handleImage(e)}
                />
                <div className="file">
                  <label
                    htmlFor="contained-button-file-vehiclePhoto"
                    className="file_upload"
                  >
                    <PlusIcon className="upload_image" />
                  </label>
                </div>
                <div className="driver_img_container">
                  <img alt="" src={driverData?.vehiclePhoto} />
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mb-2 col-sm-12 col-md-6">
              <Form.Label className="label_grey">
                Number Plate
                <Tooltip title="Please enter the Number Plate" placement="top">
                  <InfoIcon className="info_icon" />
                </Tooltip>
              </Form.Label>
              <InputGroup className="mb-2">
                <FormControl
                  placeholder="Enter the number plate"
                  aria-label="price"
                  aria-describedby="basic-addon1"
                  onChange={(e) => handleInput(e)}
                  name="numberPlate"
                  value={driverData.numberPlate}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2 col-sm-12 col-md-12">
              <Form.Label className="label_grey">
                Driver Image
                <Tooltip title="Please enter the Driver Image" placement="top">
                  <InfoIcon className="info_icon" />
                </Tooltip>
              </Form.Label>
              <div className="d-flex">
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  id="contained-button-file-image"
                  name="image"
                  onChange={(e) => handleImage(e)}
                />
                <div className="file">
                  <label
                    htmlFor="contained-button-file-image"
                    className="file_upload"
                  >
                    <PlusIcon className="upload_image" />
                  </label>
                </div>

                <div className="driver_img_container">
                  {/* <div
                    className="close_icon"
                    // onClick={() => handleDeleteImage(item)}
                  >
                    {driverData.image == "" ? null : <HighlightOffIcon />}
                  </div> */}
                  <img alt="" src={driverData?.image} />
                </div>
              </div>
            </Form.Group>

            <Form.Group className="mb-2 col-sm-12 col-md-12">
              <div className="submit_product text-right btn_driver_user">
                <Button
                  className="btn_cancel"
                  onClick={() => history.push("/driver_user")}
                >
                  Cancel
                </Button>
                {editAction ? (
                  <Button
                    className="btn_submit"
                    onClick={(e) => handleEditSubmit(e)}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    className="btn_submit"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Submit
                  </Button>
                )}
              </div>
            </Form.Group>
          </Form>
        </div>
      </main>
    </div>
  );
}

export default AddDriver;
