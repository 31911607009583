import React from "react";
import styled from "styled-components";
import { Droppable } from "react-beautiful-dnd";

import { Task } from "./CategoryTask";
import { SubCategoryTask } from "./SubCategoryTask";
import { SubSubCategoryTask } from "./SubSubCategoryTask";

const TaskList = styled.div`
  transition: background-color 0.2s ease;
  background-color: ${(props) => (props.isDraggingOver ? "skyblue" : "white")};
`;

export const Column = ({ column, isDropDisabled, tasks, type }) => {
  return (
    <Droppable droppableId={column.id} isDropDisabled={isDropDisabled}>
      {(provided, snapshot) => (
        <TaskList
          ref={provided.innerRef}
          {...provided.droppableProps}
          isDraggingOver={snapshot.isDraggingOver}
        >
          {tasks.map((task, index) => (
            <div key={task._id}>
              {type === "Category" && (
                <Task key={task._id} task={task} index={index} />
              )}
              {type === "SubCategory" && (
                <SubCategoryTask key={task._id} task={task} index={index} />
              )}
              {type === "SubSubCategory" && (
                <SubSubCategoryTask key={task._id} task={task} index={index} />
              )}
            </div>
          ))}
          {provided.placeholder}
        </TaskList>
      )}
    </Droppable>
  );
};
