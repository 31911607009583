import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { deleteCustomAPI } from "../../middleware/apiMiddleware";
import { getAttributeAction } from "../../services/actions/attribute.action";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import {
  categoryModalAction,
  getSubCategoryAction,
  toggleSubCategoryAction,
  toggleSubSubCategoryAction,
} from "../../services/actions/category.action";
import { Api } from "../../utils/API/Api";
import { loaderAction } from "./../../services/actions/loader.action";
import CategoryModal from "./CategoryModal";
import DragCategories from "./DragCategories";
function AddSubCategory() {
  let type = "EDIT_SUB_CATEGORY";
  const dispatch = useDispatch();
  const alert = useAlert();

  const { categorySubList, reOrderCategorySubList } = useSelector(
    (state) => state.categorySubList
  );
  const { categoryData } = useSelector((state) => state.subCategoryToggle);
  const { categorySubData } = useSelector(
    (state) => state.subSubCategoryToggle
  );
  let location = useLocation();
  const [action, setAction] = useState(true);

  useEffect(() => {
    if (location.pathname === "/add_products") {
      setAction(false);
    } else {
      setAction(true);
    }
  }, []);

  const handleDelete = async (id) => {
    dispatch(loaderAction(true));

    let body = {
      _id: id,
    };
    const { data, error } = await deleteCustomAPI(Api.deleteSubCategory, body);
    if (!error) {
      if (data.status === 1) {
        dispatch(getSubCategoryAction(categoryData));

        if (id == categorySubData?._id) {
          dispatch(toggleSubCategoryAction(categoryData, true));
        }
        alert.success(data.message);
      } else {
        alert.error(data.message);
      }
    } else {
      alert.error(error.message);
    }
    dispatch(loaderAction(false));
  };
  const [modalShow, setModalShow] = React.useState(false);
  const handleOpen = async () => {
    dispatch(loaderAction(true));

    let open = true;
    let type = "ADD_SUB_CATEGORY";
    let id = null;

    let data = null;
    dispatch(categoryModalAction(type, id, data, open));
    setModalShow(true);
    dispatch(loaderAction(false));
  };

  return (
    <div>
      <div className="card_box">
        <div className="d-flex justify-content-between ">
          <div>
            <h6>
              {location.pathname !== "/add_products" && "Add "} Sub Category
            </h6>
          </div>
          <div>
            {location.pathname !== "/add_products" && (
              <AddCircleIcon
                onClick={() => {
                  handleOpen();
                }}
                className="btn_add_Category"
              />
            )}
            <CategoryModal modalShow={modalShow} setModalShow={setModalShow} />
            {/* <CategoryModal ADD_SUB_CATEGORY={"ADD_SUB_CATEGORY"} /> */}
          </div>
        </div>
        {action ? (
          <DragCategories
            reOrderCategoryList={reOrderCategorySubList}
            type={"SubCategory"}
          />
        ) : (
          <div className="category_list">
            {categorySubList &&
              categorySubList.map((item, index) => (
                <div
                  key={index}
                  className={
                    item?._id === categorySubData?._id
                      ? "menu_btn_active"
                      : null
                  }
                >
                  <div className="d-flex justify-content-start category_box_container">
                    <div
                      className="category_name_text"
                      onClick={() => {
                        dispatch(toggleSubSubCategoryAction(item, true));
                      }}
                    >
                      <h5 className="category_name">{item.subCategoryName}</h5>
                    </div>
                    <div
                      onClick={() => {
                        dispatch(toggleSubSubCategoryAction(item, true));
                      }}
                    >
                      <img
                        src="assets/images/arrow_right.svg"
                        className="category_arrow"
                        alt=""
                      />
                    </div>
                  </div>

                  <hr className="m-0" />
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default AddSubCategory;
