import axios from "axios";
import { Api } from "../../utils/API/Api";
import { constants } from "../constants";
import { loaderAction } from "./loader.action";

export const userAction = (user) => async (dispatch) => {
  dispatch(loaderAction(true));
  dispatch({ type: constants.USER_REQUEST });

  const { data } = await axios.post(Api.getUsers, {
    filter: false,
  });

  if (data.status === 0) {
    dispatch({
      type: constants.USER_FAIL,
      payload: { message: data.message, data },
    });
    dispatch(loaderAction(false));
  } else {
    let arr = [];

    if (data && data.userData.length !== 0) {
      data &&
        data.userData.forEach((item, index) => {
          item.index = index + 1;

          arr.push(item);
        });
    } else {
      arr = [];
    }

    dispatch({
      type: constants.USER_SUCCESS,
      payload: { message: data.message, data: arr },
    });
    dispatch(loaderAction(false));
  }

  dispatch(loaderAction(false));
};
