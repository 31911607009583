import { constants } from "../constants";
import { loaderAction } from "./loader.action";
import axios from "axios";
import { Api } from "../../utils/API/Api";
import { productPageReloadAction } from "./product.action";
import {
  authenticate,
  removeAuthentication,
  removeSession,
  setEmail,
  setSession,
} from "../../helper/Cookies";

export const loginAdmin = (loginData, keepMe) => async (dispatch) => {
  dispatch(loaderAction(true));
  dispatch({ type: constants.LOGIN_REQUEST });

  const { data } = await axios.post(Api.login, {
    email: loginData.email,
    password: loginData.password,
  });

  if (data.status === 0) {
    dispatch({
      type: constants.LOGIN_FAIL,
      payload: { error: "Email and password is incorrect", data },
    });
  } else {
    localStorage.setItem("adminToken", data.token);
    let token = data.token;
    let email = data.email;
    if (keepMe) {
      authenticate(token, () => {
        setEmail(email, () => {
          dispatch({
            type: constants.LOGIN_SUCCESS,
            payload: {
              message: data.message,
              data: data.data,
              token: data.token,
            },
          });
          dispatch(loaderAction(false));
        });
      });
    } else {
      setSession(token, () => {
        setEmail(email, () => {
          dispatch({
            type: constants.LOGIN_SUCCESS,
            payload: {
              message: data.message,
              data: data.data,
              token: data.token,
            },
          });
          dispatch(loaderAction(false));
        });
      });
    }
  }
  dispatch(loaderAction(false));
};

// export const isUserLoggedIn = (adminType) => {
export const isUserLoggedIn = () => async (dispatch) => {
  const token = localStorage.getItem("adminToken");

  // if (token) {
  //   dispatch({
  //     type: constants.LOGIN_SUCCESS,
  //     payload: token,
  //   });
  //   // dispatch(loaderAction(false));
  // } else {
  //   dispatch({
  //     type: constants.LOGIN_FAIL,
  //     payload: { error: "" },
  //   });
  //   // dispatch(loaderAction(false));
  // }
};
// };

export const logoutSuperAdmin = () => async (dispatch) => {
  localStorage.removeItem("adminToken");
  console.clear();
  // window.location.reload();
  dispatch({
    type: constants.LOGOUT_REQUEST,
  });

  removeAuthentication(() => {
    removeSession(() => {
      dispatch({
        type: constants.LOGOUT_SUCCESS,
      });
      setTimeout(() => {
        // window.location.href = "/";
      }, 500);
    });
  });

  dispatch(productPageReloadAction(false));
};
