export const TopSellingProductList = [
  {
    title: "Camisoles & Tubes",
    max: 10,
  },
  {
    title: "Maxi Dresses",
    max: 20,
  },
  {
    title: "Shirts",
    max: 30,
  },
  {
    title: "Jackets and Coats",
    max: 50,
  },
  {
    title: "Gowns",
    max: 20,
  },
  {
    title: "Kurtis",
    max: 100,
  },
];
