import React from "react";

import { Link } from "react-router-dom";

function AddDriverButton() {
  return (
    <Link to="/add_driver" className="add_driver_link">
      <div className="add_product_btn">Add Driver</div>
    </Link>
  );
}

export default AddDriverButton;
