import { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loaderAction } from "./../services/actions/loader.action";

const useFetch = (url) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const search = useLocation().search;
  const driverId = new URLSearchParams(search).get("id");

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(loaderAction(true));

    if (driverId) {
      const fetchData = async () => {
        await axios
          .post(url, { _id: driverId })
          .then((res) => {
            if (res.data.status === 1) {
              setData(res.data.data);
              setLoading(false);
            } else {
              setError(res.data.message);
            }
          })
          .catch((err) => {
            setError(err.message);
          });
      };
      fetchData();
    } else {
      const fetchData = async () => {
        await axios
          .get(url)
          .then((res) => {
            if (res.data.status === 1 || res.status === 200) {
              setData(res.data.data);
              setLoading(false);
              dispatch(loaderAction(false));
            } else {
              setError(res.data.message);
              setLoading(false);
            }
          })
          .catch((err) => {
            setError(err.message);
            dispatch(loaderAction(false));
          });
      };
      fetchData();
    }
  }, [url]);
  const reFetchData = async () => {
    dispatch(loaderAction(true));

    await axios
      .get(url)
      .then((res) => {
        if (res.data.status === 1 || res.status === 200) {
          setData(res.data.data);
          setLoading(false);
          dispatch(loaderAction(false));
        } else {
          setError(res.data.message);
          dispatch(loaderAction(false));
        }
      })
      .catch((err) => {
        setError(err.message);
        dispatch(loaderAction(false));
      });
  };
  return { data, loading, reFetchData, error };
};

export { useFetch };
