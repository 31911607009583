import React from 'react'
import { Col, Row } from "react-bootstrap";
function UserProductShared() {
  return (
    <div className="box_product ">

      <Row className='pt-4 pl-3 card_product'>
        <Col xs={12} lg={6}>
          <Row>
            <Col xs={12} md={4} className="pr-0">
              <div className="img_container">
                <img src='assets/images/product-img-two.png' />
              </div>
            </Col>
            <Col xs={12} md={8} className="pl-0">
              <div className='pl-2'>

                <div className="shared_badge"><img src="assets/images/fb_facebook.svg" />Facebook</div>
                <h5>Chic Fuchsia Pink Power Crop Shoulders Top</h5>
                <p className='mb-1'>$20.00 <span>Size-M</span><span>Qty 1</span></p>
                <p className="shared_date">24 March 2022 - 10.00 AM</p>
              </div>
            </Col>
          </Row>
        </Col>

        <Col xs={12} lg={6}>
          <Row>
            <Col xs={12} md={4} className="pr-0">
              <div className="img_container">
                <img src='assets/images/product-img-two.png' />
              </div>
            </Col>
            <Col xs={12} md={8} className="pl-0">
              <div className='pl-2'>

                <div className="shared_badge"><img src="assets/images/fb_facebook.svg" />Facebook</div>
                <h5>Chic Fuchsia Pink Power Crop Shoulders Top</h5>
                <p className='mb-1'>$20.00 <span>Size-M</span><span>Qty 1</span></p>
                <p className="shared_date">24 March 2022 - 10.00 AM</p>
              </div>
            </Col>
          </Row>
        </Col>

      </Row>



      {/* <div className="d-flex card_product">
        <div className="img_container">
          <img src='assets/images/product-img-two.png' className='profile_img' />
        </div>
        <div>
          <div className="shared_badge"><img src="assets/images/fb_facebook.svg" />Facebook</div>
          <h5>Chic Fuchsia Pink Power Crop Shoulders Top</h5>
          <p className='mb-1'>$20.00 <span>Size-M</span><span>Qty 1</span></p>
          <p className="shared_date">24 March 2022 - 10.00 AM</p>
        </div>
      </div> */}

      {/* <div className="d-flex card_product">
        <div className="img_container">
          <img src='assets/images/product-img-two.png' className='profile_img' />
        </div>
        <div>
          <div className="shared_badge"><img src="assets/images/whatsapp_logo_icon.svg" />Whatsapp</div>
          <h5>Chic Fuchsia Pink Power Crop Shoulders Top</h5>
          <p className='mb-1'>$20.00 <span>Size-M</span><span>Qty 1</span></p>
          <p className="shared_date">24 March 2022 - 10.00 AM</p>
        </div>
      </div> */}

      {/* <div className="d-flex card_product">
        <div className="img_container">
          <img src='assets/images/product-img-two.png' className='profile_img' />
        </div>
        <div>
          <div className="shared_badge"><img src="assets/images/whatsapp_logo_icon.svg" />Whatsapp</div>
          <h5>Chic Fuchsia Pink Power Crop Shoulders Top</h5>
          <p className='mb-1'>$20.00 <span>Size-M</span><span>Qty 1</span></p>
          <p className="shared_date">24 March 2022 - 10.00 AM</p>
        </div>
      </div> */}
    </div >
  )
}

export default UserProductShared