import Sidebar from "../Sidebar/Sidebar";
import Heading from "../Heading/Heading";
import { useStyles } from "../../utils/useStyles";
import { Button, Form, FormControl } from "react-bootstrap";
import { PlusIcon } from "../../utils/MenusList/Icons";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { FormControlLabel, Radio, RadioGroup, TextareaAutosize } from "@mui/material";
import { DatePicker } from "@mui/lab";
import { ageRange } from "../Dashboard/Reseller/AgeRange";
import CommissionEarnedSlider from "../Dashboard/Reseller/CommissionEarnedSlider";
function AddNotifications() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading />

        {/* Data table */}

        <Form className="form_activity">
          <Form.Group className="mb-2 col-sm-12 col-md-6">
            <Form.Label className="label_grey">
              Driver License Photo
            </Form.Label>
            <div className="d-flex driver_uploader gap-3">
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="contained-button-file"
              />
              <div className="file">
                <label
                  htmlFor="contained-button-file"
                  className="file_upload"
                >
                  <PlusIcon className="upload_image" />
                </label>
              </div>
              <div className="file_upload border_none">
                <div class="close_icon">
                  <HighlightOffIcon className="upload_image" />
                </div>
                <img src="assets/images/product-img-two.png" />
              </div>
            </div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="label_grey">Title</Form.Label>
            <FormControl
              placeholder="Enter the Title"
              aria-label="salesVolumne"
              aria-describedby="basic-addon1"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="label_grey">Description</Form.Label><br />
            <TextareaAutosize
              aria-label="empty textarea"
              placeholder="Empty"
              minRows={3}
              className="text_description"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="label_grey">Select User</Form.Label>
            <Form.Select aria-label="Default select example">
              <option>Select User</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </Form.Group>
          <div className="row mb-4">
            <Form.Group className="col-6">
              <Form.Label className="label_grey">Date</Form.Label>
              <Form.Select aria-label="Default select example">
                <option>Select User</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="col-6">
              <Form.Label className="label_grey">Time</Form.Label>
              <Form.Select aria-label="Default select example">
                <option>Select User</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="row mb-3">
            <Form.Group className="mb-3 col-6">
              <Form.Label >By Age </Form.Label>
              <div className="age_selection">
                {ageRange.map((range) => (
                  <Button>
                    {range.min}
                    <span>{range.min && range.min ? "-" : null}</span>
                    {range.max}
                  </Button>
                ))}
              </div>
            </Form.Group>
            <Form.Group className="mb-3 col-6">
              <Form.Label >Gender </Form.Label>
              <RadioGroup row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
              >
                <FormControlLabel value="female" control={<Radio />} label="Female" />
                <FormControlLabel value="male" control={<Radio />} label="Male" />
              </RadioGroup>
            </Form.Group>
          </div>
          <div className="row mb-3">
            <Form.Group className="mb-3 col-6">

              <RadioGroup row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="month"
                name="radio-buttons-group"
              >
                <FormControlLabel value="month" control={<Radio />} label="Shop LAst Month" />

              </RadioGroup>
            </Form.Group>
            <Form.Group className="mb-3 col-6">
              <Form.Label className="label_grey">By Commissions Earned</Form.Label>
              <CommissionEarnedSlider />
            </Form.Group>
          </div>
          <div className="row mb-3">
            <Form.Group className="mb-3 col-6">
              <Form.Label className="label_grey">Sales Volume</Form.Label>
              <FormControl
                placeholder="Enter the Sales Volume"
                aria-label="salesVolumne"
                aria-describedby="basic-addon1"
              />
            </Form.Group>
            <Form.Group className="mb-3 col-6">
              <Form.Label className="label_grey">Number of Shares</Form.Label>
              <FormControl
                placeholder="Enter the Sales Volume"
                aria-label="salesVolumne"
                aria-describedby="basic-addon1"
              />
            </Form.Group>
          </div>
          <div className="row">
            <Form.Group className=" col-6">
              <Form.Label className="label_ft_large">Latest Activity</Form.Label>
              <div className="mb-3 inline_form_field">
                <Form.Label className="label_grey">Usage Type</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>Select Usage type</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </div>
              <div className="mb-3 inline_form_field">
                <Form.Label className="label_grey">Quantity</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>Select Quantity</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </div>
              <div className="mb-3 inline_form_field">
                <Form.Label className="label_grey">Time Period</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>Select time period</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </div>
            </Form.Group>
            <Form.Group className=" col-6">
              <Form.Label className="label_ft_large">Recurrance</Form.Label>
              <div className="mb-3 inline_form_field ip_width">
                <FormControl placeholder="2"></FormControl>
                <Form.Select aria-label="Default select example">
                  <option>Weeks</option>
                  <option value="1">Weeks</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </div>
              <TextareaAutosize
                aria-label="empty textarea"
                minRows={4}
                className="textarea1"
              />
            </Form.Group>
          </div>
          <Form.Group className="mb-3">
            <Form.Label className="label_grey">Product URL</Form.Label>
            <FormControl
              placeholder="Enter the Title"
              aria-label="salesVolumne"
              aria-describedby="basic-addon1"
            />
          </Form.Group>
          <div class="submit_product text-right">
            <Button type="button" className="btn_cancel btn btn-primary">Cancel</Button>
            <Button type="button" className="btn_submit btn btn-primary">Submit</Button>
          </div>
        </Form>

      </main>
    </div>
  );
}

export default AddNotifications;
