import { constants } from "../constants";

const initialState = {
  userData: null,
  authentication: false,
  isAuthenticated: false,
  message: null,
  error: null,
  token: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_REQUEST:
      return {
        ...initialState,
      };
    case constants.LOGOUT_SUCCESS:
      return {
        ...state,
        message: "Logout successfully",
      };
    case constants.LOGIN_REQUEST:
      return {
        ...initialState,
      };

    case constants.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        userData: action.payload.data,
        authentication: true,
        token: action.payload.token,
      };
    case constants.LOGIN_FAIL:
      return {
        ...initialState,
        userData: action.payload.data,
        error: action.payload.error,
      };

    default:
      return state;
  }
};
