import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function EditCategoryAttribute() {
  const { getSingleAttributeData } = useSelector(
    (state) => state.getSingleAttributeData
  );
  const history = useHistory();

  useEffect(() => {
    if (getSingleAttributeData && getSingleAttributeData) {
    } else {
      history.push("/attributes");
    }
  }, [getSingleAttributeData, history]);

  return (
    <div>
      <Form className="form_activity form_add_product row mt-4 ">
        <Form.Group className="mb-2 col-sm-12 col-md-6">
          <Form.Label className="label_grey">Select Category</Form.Label>
          <Form.Select aria-label="Default select example" className="mb-2">
            <option>
              {getSingleAttributeData && getSingleAttributeData.category}
            </option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-2 col-sm-12 col-md-6">
          <Form.Label className="label_grey">Select Sub-Category</Form.Label>
          <Form.Select aria-label="Default select example" className="mb-2">
            <option>
              {getSingleAttributeData && getSingleAttributeData.subCategory}
            </option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-2 col-sm-12 col-md-6">
          <Form.Label className="label_grey">Select Sub-subcategory</Form.Label>
          <Form.Select aria-label="Default select example" className="mb-2">
            <option>
              {getSingleAttributeData && getSingleAttributeData.subSubCategory}
            </option>
          </Form.Select>
        </Form.Group>
      </Form>
    </div>
  );
}

export default EditCategoryAttribute;
