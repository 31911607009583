import React from "react";

import MaterialTable from "material-table";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import SwitchButton from "../Users/SwitchButton";
import { DeleteIcon, EditIcon } from "../../utils/MenusList/Icons";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

const options = {
  print: true,
  download: true,
  filter: true,
  viewColumns: false,
  selectableRowsHideCheckboxes: true,
  rowsPerPage: 10,
  rowsPerPageOptions: [5, 10, 50, 100],
};
function NotificationDatatable() {
  const [loading, setLoading] = React.useState(false);
  const columns = [
    {
      title: "#",
      field: "index",
    },
    { title: "User Name", field: "fullName" },
    { title: "Title", field: "title" },
    { title: "Date", field: "date" },
    {
      title: "Time",
      field: "time",

    },
    { title: "Description", field: "description" },
  ];
  const options = {
    print: true,
    download: true,
    filter: true,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 50, 100],
    actionsColumnIndex: -1,
  };
  let driverData = [{ index: 1, fullName: "Test" }];
  const actions = [
    {
      icon: ModeEditIcon,
      tooltip: "Edit Order",
      // onClick: (event, rowData) => history.push("/users_details"),
      className: "view_icon",
    },
    {
      icon: DeleteIcon,
      tooltip: "Delete Order",
      // onClick: (event, rowData) => history.push("/orders-detail"),
    },
  ];

  return (
    <>
      <Box>
        {loading ? (
          <CircularProgress />
        ) : (
          <MaterialTable
            options={options && options}
            data={driverData && driverData}
            columns={columns && columns}
            title=""
            onRowClick={(event, rowData) => console.log(rowData)}
            actions={actions && actions}
          />
        )}
      </Box>
    </>
  );
}

export default NotificationDatatable;
