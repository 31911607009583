import React from "react";
import "./userDetails.css";
function UserInfo() {
  return <div className="user_details">
    <div>
      <h6>Image:</h6>
      <img src="" />
    </div>
    <div>
      <h6>Name:</h6>
      <p>John Doe</p>
    </div>
    <div>
      <h6>Email Address:</h6>
      <p>johndoe@gmail.com</p>
    </div>
    <div>
      <h6>Create On:</h6>
      <p>01/3/2000</p>
    </div>
    <div>
      <h6>Country Code:</h6>
      <p>+71</p>
    </div>
    <div>
      <h6>Phone Number:</h6>
      <p>123456789</p>
    </div>
    <div>
      <h6>Product Purchased:</h6>
      <p>20</p>
    </div>
    <div>
      <h6>Purchase Per Month:</h6>
      <p>10</p>
    </div>
    <div>
      <h6>Net Profit:</h6>
      <p>$10</p>
    </div>
    <div>
      <h6>Total Commission:</h6>
      <p>$10</p>
    </div>
    <div>
      <h6>Total Share:</h6>
      <p>10K</p>
    </div>
    <div>
      <h6>Sales Value:</h6>
      <p>$100</p>
    </div>
    <div>
      <h6>Mobile Number:</h6>
      <p>123456789</p>
    </div>
  </div>;
}

export default UserInfo;
