import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAttributeAction } from "../../../services/actions/attribute.action";
import {
  addMultipleProductImageAction,
  addFrontImageProductAction,
} from "../../../services/actions/product.action";
import { useStyles } from "../../../utils/useStyles";
import Heading from "../../Heading/Heading";
import Sidebar from "../../Sidebar/Sidebar";
import AddProductForm from "./AddProductForm";

function AddProductDetails() {
  const classes = useStyles();

  const { singleProductDetailData, isEditStatus } = useSelector(
    (state) => state.singleProductDetailData
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditStatus) {
      dispatch(getAttributeAction(singleProductDetailData?.subSubCategoryName));
      dispatch(
        addMultipleProductImageAction(singleProductDetailData?.multipleImages)
      );
      dispatch(addFrontImageProductAction(singleProductDetailData?.images));
    }
  }, [isEditStatus, dispatch, singleProductDetailData]);

  // console.log(singleProductDetailData, "singleProductDetailData", isEditStatus);

  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading />

        {/* Data table */}

        <AddProductForm />
      </main>
    </div>
  );
}

export default AddProductDetails;
