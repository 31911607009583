import axios from "axios";

export const customPostAPI = async (url, body) => {
  try {
    const { data } = await axios.post(url, body);
    return { data };
  } catch (error) {
    return { error };
  }
};

export const customPutAPI = async (url, body) => {
  try {
    const { data } = await axios.put(url, body);
    return { data };
  } catch (error) {
    return { error };
  }
};

export const deleteCustomAPI = async (url, body) => {
  try {
    const { data } = await axios.put(url, body);
    return { data };
  } catch (error) {
    return { error };
  }
};

export const reOrderCategoriesAPI = async (url, body) => {
  try {
    const { data } = await axios.put(url, body);
    return { data };
  } catch (error) {
    return { error };
  }
};

export const deleteAPI = async (url, id) => {
  try {
    const { data } = await axios.delete(url, {
      data: {
        _id: id,
      },
    });
    return { data };
  } catch (error) {
    return { error };
  }
};

export const deleteAPIFun = async (url, token) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const { data } = await axios.delete(url, config);
    return { data };
  } catch (err) {
    const { data } = err.response;
    return { error: data };
  }
};

export const getCoordinates = async (address) => {
  let API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  return await axios.get(
    "https://maps.googleapis.com/maps/api/geocode/json?address=" +
      address +
      "&key=" +
      API_KEY
  );
};
