import axios from "axios";
import * as React from "react";
import { useAlert } from "react-alert";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { loaderAction } from "../../services/actions/loader.action";
import { Api } from "./../../utils/API/Api";

export default function SwitchButton({ item, driver }) {
  const [toggle, setToggle] = React.useState(item.isActive);
  const alert = useAlert();
  const dispatch = useDispatch();

  const handleActive = async (e) => {
    dispatch(loaderAction(true));

    driver &&
      (await axios
        .post(Api.driverAction, { _id: item._id, isActive: e.target.checked })
        .then((res) => {
          if (res.data.status === 1) {
            alert.success(res.data.message);
          } else {
            alert.error(res.data.message);
          }
        })
        .catch((err) => {
          alert.error(err.message);
        }));
    dispatch(loaderAction(false));
  };

  return (
    <div style={{ marginLeft: "5px" }}>
      <Form.Check
        type="switch"
        id="custom-switch"
        checked={toggle}
        onChange={(e) => {
          setToggle(!toggle);
          handleActive(e);
        }}
      />
    </div>
  );
}
