import React from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import LocationOnIcon from "@mui/icons-material/LocationOn";

function LatestActivity() {
  return (
    <>
      <Form className="form_activity">
        <Form.Group className="mb-3">
          <Form.Label>By Location </Form.Label>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="U.S.A"
              aria-label="location"
              aria-describedby="basic-addon1"
            />
            <LocationOnIcon className="location_on_icon" />
            {/* <InputGroup.Text id="basic-addon1">
            </InputGroup.Text> */}
          </InputGroup>
        </Form.Group>

        <Form.Group>
          <Form.Label>Latest Activity</Form.Label>
        </Form.Group>

        <Form.Group className="mb-3 inline_form_field">
          <Form.Label>Usage Type</Form.Label>
          <Form.Select aria-label="Default select example">
            <option>Select Usage type</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3 inline_form_field">
          <Form.Label>Quantity</Form.Label>
          <Form.Select aria-label="Default select example">
            <option>Select Quantity</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3 inline_form_field">
          <Form.Label>Time Period</Form.Label>
          <Form.Select aria-label="Default select example">
            <option>Select Time Period</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>
        </Form.Group>
      </Form>
    </>
  );
}

export default LatestActivity;
