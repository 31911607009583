import React from "react";

import { Button, Col, Form, Row } from "react-bootstrap";

import LatestActivity from "./LatestActivity";
import ByAge from "./ByAge";
import ByCommissionEarned from "./ByCommissionEarned";
import "./Reseller.css";
function AToZFilter() {
  const [age, setAge] = React.useState("");

  const alphabet = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  return (
    <div className="card_box mb-4">
      <Row className="mb-4">
        <Col xs lg={6}>
          <div className="aplhalist">
            <Form.Select aria-label="Default select" className="aToZ">
              <option value="" disabled selected>
                A to Z
              </option>
              {alphabet.map((item, index) => (
                <option value={index}>{item}</option>
              ))}
            </Form.Select>
          </div>
        </Col>
        <Col xs lg={6}>
          <div className="text-right btn_reset_filter">
            <Button variant="primary">Reset Filter</Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs lg={4}>
          <LatestActivity />
        </Col>
        <Col xs lg={4}>
          <ByAge />
        </Col>
        <Col xs lg={4}>
          <ByCommissionEarned />
        </Col>
      </Row>
    </div>
  );
}

export default AToZFilter;
