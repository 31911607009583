import React from "react";
import { Col, Row } from "react-bootstrap";
import { DashboardCardMenus } from "../../utils/MenusList/DashboardCardMenus";

function CountCard() {
  return (
    <>
      {DashboardCardMenus &&
        DashboardCardMenus.map((card, index) => (
          <div className="card_box" key={index}>
            <Row>
              <Col md={8}>
                <h3>{card.count}</h3>
                <p>{card.title}</p>
              </Col>
              <Col md={4}>
                <img src={card.icons} alt="" srcSet="" />
              </Col>
            </Row>
          </div>
        ))}
    </>
  );
}

export default CountCard;
