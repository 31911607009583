export const constants = {
  // loader constants
  LOADER_START: "LOADER_START",
  LOADER_STOP: "LOADER_STOP",

  // login constants
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",

  // get users

  USER_REQUEST: "USER_REQUEST",
  USER_SUCCESS: "USER_SUCCESS",
  USER_FAIL: "USER_FAIL",

  // category constants
  CATEGORY_TOGGLE_TRUE: "CATEGORY_TOGGLE_TRUE",
  CATEGORY_TOGGLE_FALSE: "CATEGORY_TOGGLE_FALSE",
  CLEAR_CATEGORY: "CLEAR_CATEGORY",

  CATEGORY_MODAL_REQUEST: "CATEGORY_MODAL_REQUEST",
  CATEGORY_MODAL_SUCCESS: "CATEGORY_MODAL_SUCCESS",
  CATEGORY_MODAL_FAIL: "CATEGORY_MODAL_FAIL",
  CATEGORY_MODAL_OPEN: "CATEGORY_MODAL_OPEN",
  CATEGORY_MODAL_CLOSE: "CATEGORY_MODAL_CLOSE",

  CATEGORY_MODAL_IMAGE_RESET: "CATEGORY_MODAL_IMAGE_RESET",
  CATEGORY_MODAL_IMAGE_SUCCESS: "CATEGORY_MODAL_IMAGE_SUCCESS",

  // add category constants
  ADD_CATEGORY_REQUEST: "ADD_CATEGORY_REQUEST",
  ADD_CATEGORY_SUCCESS: "ADD_CATEGORY_SUCCESS",
  ADD_CATEGORY_FAIL: "ADD_CATEGORY_FAIL",

  //category sub toggle

  CATEGORY_SUB_TOGGLE_REQUEST: "CATEGORY_SUB_TOGGLE_REQUEST",
  CATEGORY_SUB_TOGGLE_SUCCESS: "CATEGORY_SUB_TOGGLE_SUCCESS",
  CATEGORY_SUB_TOGGLE_FAIL: "CATEGORY_SUB_TOGGLE_FAIL",

  //category sub sub toggle

  CATEGORY_SUB_SUB_TOGGLE_REQUEST: "CATEGORY_SUB_SUB_TOGGLE_REQUEST",
  CATEGORY_SUB_SUB_TOGGLE_SUCCESS: "CATEGORY_SUB_SUB_TOGGLE_SUCCESS",
  CATEGORY_SUB_SUB_TOGGLE_FAIL: "CATEGORY_SUB_SUB_TOGGLE_FAIL",

  // get category

  CATEGORY_REQUEST: "CATEGORY_REQUEST",
  CATEGORY_SUCCESS: "CATEGORY_SUCCESS",
  CATEGORY_FAIL: "CATEGORY_FAIL",

  // get sub category
  CATEGORY_SUB_REQUEST: "CATEGORY_SUB_REQUEST",
  CATEGORY_SUB_SUCCESS: "CATEGORY_SUB_SUCCESS",
  CATEGORY_SUB_FAIL: "CATEGORY_SUB_FAIL",

  // get sub-sub category
  CATEGORY_SUB_SUB_REQUEST: "CATEGORY_SUB_SUB_REQUEST",
  CATEGORY_SUB_SUB_SUCCESS: "CATEGORY_SUB_SUB_SUCCESS",
  CATEGORY_SUB_SUB_FAIL: "CATEGORY_SUB_SUB_FAIL",

  // ************************************* Product constants *********

  // show add product image

  PRODUCT_PAGE_RELOAD_TRUE: "PRODUCT_PAGE_RELOAD_TRUE",
  PRODUCT_PAGE_RELOAD_FALSE: "PRODUCT_PAGE_RELOAD_FALSE",

  PRODUCT_IMAGE_BUTTON_SHOW: "PRODUCT_IMAGE_BUTTON_SHOW",
  PRODUCT_IMAGE_BUTTON_HIDE: "PRODUCT_IMAGE_BUTTON_HIDE",

  PRODUCT_WITH_FILTER_REQUEST: "PRODUCT_WITH_FILTER_REQUEST",
  PRODUCT_WITH_FILTER_SUCCESS: "PRODUCT_WITH_FILTER_SUCCESS",
  PRODUCT_WITH_FILTER_FAIL: "PRODUCT_WITH_FILTER_FAIL",

  PRODUCT_PRICE_COLOR_REQUEST: "PRODUCT_PRICE_COLOR_REQUEST",
  PRODUCT_PRICE_COLOR_SUCCESS: "PRODUCT_PRICE_COLOR_SUCCESS",
  PRODUCT_PRICE_COLOR_FAIL: "PRODUCT_PRICE_COLOR_FAIL",

  PRODUCT_FILTER_CATEGORY_REQUEST: "PRODUCT_FILTER_CATEGORY_REQUEST",
  PRODUCT_FILTER_CATEGORY_SUCCESS: "PRODUCT_FILTER_CATEGORY_SUCCESS",
  PRODUCT_FILTER_CATEGORY_FAIL: "PRODUCT_FILTER_CATEGORY_FAIL",

  // Filter Product

  PRODUCT_FILTER_WITH_CATEGORY_START: "PRODUCT_FILTER_WITH_CATEGORY_START",
  PRODUCT_FILTER_WITH_CATEGORY_END: "PRODUCT_FILTER_WITH_CATEGORY_END",

  PRODUCT_FILTER_INPUT_VALUE_SAVE: "PRODUCT_FILTER_INPUT_VALUE_SAVE",
  PRODUCT_FILTER_INPUT_VALUE_RESET: "PRODUCT_FILTER_INPUT_VALUE_RESET",

  PRODUCT_FILTER_REQUEST: "PRODUCT_FILTER_REQUEST",
  PRODUCT_FILTER_SUCCESS: "PRODUCT_FILTER_SUCCESS",
  PRODUCT_FILTER_FAIL: "PRODUCT_FILTER_FAIL",

  // Add Product details constants

  ADD_PRODUCT_IDS: "ADD_PRODUCT_IDS",
  RESET_PRODUCT_IDS: "RESET_PRODUCT_IDS",

  ADDED_PRODUCT_LISTS: "ADDED_PRODUCT_LISTS",
  RESET_PRODUCT_LISTS: "RESET_PRODUCT_LISTS",

  FRONT_IMAGE_LIST: "FRONT_IMAGE_LIST",
  RESET_FRONT_IMAGE_LIST: "RESET_FRONT_IMAGE_LIST",
  MULTIPLE_IMAGE_LIST: "MULTIPLE_IMAGE_LIST",
  RESET_MULTIPLE_IMAGE_LIST: "RESET_MULTIPLE_IMAGE_LIST",

  // Save single product details constants

  SINGLE_PRODUCT_DETAILS_SAVE: "SINGLE_PRODUCT_DETAILS_SAVE",
  SINGLE_PRODUCT_DETAILS_RESET: "SINGLE_PRODUCT_DETAILS_RESET",

  // store

  SAVE_PRICE: "SAVE_PRICE",
  RESET_PRICE: "RESET_PRICE",
  SAVE_COLOR: "SAVE_COLOR",
  RESET_COLOR: "RESET_COLOR",
  SAVE_SIZE: "SAVE_SIZE",
  RESET_SIZE: "RESET_SIZE",
  SAVE_CATEGORY: "SAVE_CATEGORY",
  SAVE_SUB_CATEGORY: "SAVE_SUB_CATEGORY",
  RESET_SUB_CATEGORY: "RESET_SUB_CATEGORY",
  SAVE_SUB_SUB_CATEGORY: "SAVE_SUB_SUB_CATEGORY",
  RESET_SUB_SUB_CATEGORY: "RESET_SUB_SUB_CATEGORY",
  RESET_CATEGORY: "RESET_CATEGORY",

  // Attribute constants

  GET_ALL_ATTRIBUTES_REQUEST: "GET_ALL_ATTRIBUTES_REQUEST",
  GET_ALL_ATTRIBUTES_SUCCESS: "GET_ALL_ATTRIBUTES_SUCCESS",
  GET_ALL_ATTRIBUTES_FAIL: "GET_ALL_ATTRIBUTES_FAIL",

  GET_CATEGORY_IDS: "GET_CATEGORY_IDS",
  GET_ATTRIBUTE_SIZES: "GET_ATTRIBUTE_SIZES",
  GET_ATTRIBUTE_COLOR: "GET_ATTRIBUTE_COLOR",
  BACK_TO_ATTRIBUTE_PAGE: "BACK_TO_ATTRIBUTE_PAGE",

  GET_ATTRIBUTES_REQUEST: "GET_ATTRIBUTES_REQUEST",
  GET_ATTRIBUTES_SUCCESS: "GET_ATTRIBUTES_SUCCESS",
  GET_ATTRIBUTES_FAIL: "GET_ATTRIBUTES_FAIL",

  ADD_ATTRIBUTE_REQUEST: "ADD_ATTRIBUTE_REQUEST",
  ADD_ATTRIBUTE_SUCCESS: "ADD_ATTRIBUTE_SUCCESS",
  ADD_ATTRIBUTE_FAIL: "ADD_ATTRIBUTE_FAIL",

  SEND_ATTRIBUTE_DATA: "SEND_ATTRIBUTE_DATA",

  DELETE_ATTRIBUTE_REQUEST: "DELETE_ATTRIBUTE_REQUEST",
  DELETE_ATTRIBUTE_SUCCESS: "DELETE_ATTRIBUTE_SUCCESS",
  DELETE_ATTRIBUTE_FAIL: "DELETE_ATTRIBUTE_FAIL",

  // DELIVERY FEE
  DELIVERY_SINGLE_DATA: "DELIVERY_SINGLE_DATA",
  DELIVERY_SINGLE_RESET: "DELIVERY_SINGLE_RESET",

  DELIVERY_REQUEST: "DELIVERY_REQUEST",
  DELIVERY_SUCCESS: "DELIVERY_SUCCESS",
  DELIVERY_FAIL: "DELIVERY_FAIL",

  DELIVERY_INPUT_DATA: "DELIVERY_INPUT_DATA",
  DELIVERY_INPUT_RESET: "DELIVERY_INPUT_RESET",
};
