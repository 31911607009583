import { FormControl } from "@material-ui/core";
import React from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  sizeProductFilterAction,
  filterProductWithAllFiltersAction,
  saveSizeFilterDataInStore,
} from "../../../services/actions/product.action";

function SelectSize() {
  const dispatch = useDispatch();
  const { size } = useSelector((state) => state.priceAndColor);

  const { categoryData } = useSelector((state) => state.categoryData);
  const { categorySubData } = useSelector((state) => state.categorySubData);
  const { categorySubSubData } = useSelector(
    (state) => state.categorySubSubData
  );

  const { colorData } = useSelector((state) => state.colorData);
  const { priceData } = useSelector((state) => state.priceData);

  const handleFilter = (e) => {
    let data = {
      price: priceData ? priceData : "",
      size: e.target.value,
      color: colorData ? colorData : "",
      subSubCateId: categorySubSubData ? categorySubSubData : "",
      categoryId: categoryData ? categoryData : "",
      subCategoryId: categorySubData ? categorySubData : "",
    };
    dispatch(filterProductWithAllFiltersAction(data));

    dispatch(saveSizeFilterDataInStore(e.target.value));
  };

  return (
    <>
      <FormControl>
        {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
        <Form.Select
          aria-label="Default select example"
          onChange={(e) => handleFilter(e)}
        >
          {size && size.length > 0 ? (
            size &&
            size.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))
          ) : (
            <option value="No size"> No size</option>
          )}
        </Form.Select>
      </FormControl>
    </>
  );
}

export default SelectSize;
