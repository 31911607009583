import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { categoryModalAction } from "../../services/actions/category.action";

function AddProductButton() {
  const dispatch = useDispatch();
  let type = "ADD_CATEGORY";
  return (
    <Link
      to="/add_products"
      onClick={() => dispatch(categoryModalAction(type))}
    >
      <div className="add_product_btn">Add Product</div>
    </Link>
  );
}

export default AddProductButton;
