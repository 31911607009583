import React, { useCallback, useState } from "react";

import { DragDropContext } from "react-beautiful-dnd";

import initialData from "./initial-data";
import { Column } from "./column";
import { getCategoryAction } from "../../services/actions/category.action";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { reOrderCategoriesAPI } from "../../middleware/apiMiddleware";
import { Api } from "../../utils/API/Api";

function DragCategories({ reOrderCategoryList, type }) {
  const [state, setState] = useState(null);
  const dispatch = useDispatch();

  const handleDragStart = useCallback(
    (start) => {
      document.body.style.color = "orange";
      document.body.style.transition = "background-color 0.2s ease";

      setState({
        ...state,
        homeIndex: state.columnOrder.indexOf(start.source.droppableId),
      });
    },
    [state]
  );

  const handleDragUpdate = useCallback(
    (update) => {
      const opacity = update.destination
        ? update.destination.index / Object.keys(state.tasks).length
        : 0;

      document.body.style.backgroundColor = `rgba(153, 141, 217, ${opacity})`;
    },
    [state]
  );

  const handleDragEnd = useCallback(
    async (result) => {
      document.body.style.color = "inherit";
      document.body.style.backgroundColor = "inherit";

      setState({
        ...state,
        homeIndex: null,
      });

      if (!result.destination) {
        return;
      }

      if (
        result.destination.droppableId === result.source.droppableId &&
        result.destination.index === result.source.index
      ) {
        return;
      }

      const start = state.columns[result.source.droppableId];
      const finish = state.columns[result.destination.droppableId];

      if (start === finish) {
        // console.log(result.draggableId, "result.draggableId");
        console.log(result.source.index, "result.source.index");
        console.log(result.destination.index, "result.destination.index");
        let body = {
          _id: result.draggableId,
          index: result.destination.index,
        };

        const newTaskIds = Array.from(start.taskIds);
        newTaskIds.splice(result.source.index, 1);
        newTaskIds.splice(result.destination.index, 0, result.draggableId);

        const newColumn = {
          ...start,
          taskIds: newTaskIds,
        };

        setState({
          ...state,
          columns: {
            ...state.columns,
            [newColumn.id]: newColumn,
          },
        });

        if (type) {
          let url =
            (type == "Category" && Api.swapCategory) ||
            (type == "SubCategory" && Api.subSwapCategory) ||
            (type == "SubSubCategory" && Api.subSubSwapCategory);
          await reOrderCategoriesAPI(url, body);
        }

        return;
      }

      const startTaskIds = Array.from(start.taskIds);
      startTaskIds.splice(result.source.index, 1);
      const newStart = {
        ...start,
        taskIds: startTaskIds,
      };

      const finishTaskIds = Array.from(finish.taskIds);
      finishTaskIds.splice(result.destination.index, 0, result.draggableId);
      const newFinish = {
        ...finish,
        taskIds: finishTaskIds,
      };

      setState({
        ...state,
        columns: {
          ...state.columns,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish,
        },
      });
    },
    [state]
  );
  useEffect(() => {
    dispatch(getCategoryAction());
  }, []);

  useEffect(() => {
    setState(reOrderCategoryList);
  }, [reOrderCategoryList]);

  return (
    <>
      {state && state.length != 0 && (
        <DragDropContext
          onDragStart={handleDragStart}
          onDragUpdate={handleDragUpdate}
          onDragEnd={handleDragEnd}
        >
          {state && (
            <div className="category_list">
              {state &&
                state.columnOrder.map((columnId, index) => {
                  const column = state && state.columns[columnId];

                  const tasks =
                    column &&
                    column.length !== 0 &&
                    column.taskIds.map(
                      (taskId) => state && state.tasks[taskId]
                    );

                  const isDropDisabled = index < state && state.homeIndex;

                  return (
                    <Column
                      type={type}
                      key={column.id}
                      column={column}
                      tasks={tasks}
                      isDropDisabled={isDropDisabled}
                    />
                  );
                })}
            </div>
          )}
        </DragDropContext>
      )}
    </>
  );
}

export default DragCategories;
