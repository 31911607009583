import React from "react";
import { Col, Row } from "react-bootstrap";
import SortByButton from "./SortByButton";

const socialPost = [
  {
    title: "Whatsapp",
    icon: "assets/images/whatsapp.svg",
    shared_count: "100",
  },
  {
    title: "Facebook",
    icon: "assets/images/fb.svg",
    shared_count: "100",
  },
  {
    title: "Instagram",
    icon: "assets/images/instagram.svg",
    shared_count: "100",
  },
];

function SocialSource() {
  return (
    <>
      <Row>
        <Col md={8}>
          <h6>Top-Selling Product</h6>
        </Col>
        <Col md={4}>
          <SortByButton />
        </Col>
      </Row>

      <div className="shared_count">
        <h3>1000</h3>
        <p>Number of products</p>
        <div className="social_image d-flex">
          {socialPost.map((post, index) => (
            <div key={index}>
              <img src={post.icon} alt="" srcSet="" />
              <p className="title">{post.title}</p>
              <p className="share">{post.shared_count} Share</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default SocialSource;
