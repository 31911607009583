import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  saveSingleProductDetailsAction,
  showProductWithFilterAction,
} from "../../../services/actions/product.action";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import axios from "axios";
import { Api } from "../../../utils/API/Api";
import { useAlert } from "react-alert";
import { loaderAction } from "../../../services/actions/loader.action";
import ConfirmModal from "../../ConfirmModal/ConfirmModal";

export default function ProductCard({ product }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [id, seTid] = React.useState("");
  const [confirmOpen, setConfirmOpen] = React.useState(false);

  const handleEdit = () => {
    dispatch(saveSingleProductDetailsAction(product));
    history.push("/edit_product_details");
  };

  const handleDelete = (data) => {
    seTid(data);
    setConfirmOpen(true);
  };

  // showProductWithFilterAction;

  return (
    <Card sx={{ maxWidth: 245 }} style={{ cursor: "pointer" }}>
      <ConfirmModal
        id={id}
        confirmOpen={confirmOpen}
        setConfirmOpen={setConfirmOpen}
        show={confirmOpen}
        onHide={() => setConfirmOpen(false)}
      />
      <div className="p-rel">
        <CardMedia
          component="img"
          alt=""
          height="250"
          image={product.images}
          onClick={() => handleEdit()}
        />
        <div className="delete_icon" onClick={() => handleDelete(product._id)}>
          <HighlightOffIcon />
        </div>
      </div>
      <Typography
        variant="body2"
        color="text.dark"
        className="mt-2 p-1 product_card_typography"
      >
        {product.productName}
      </Typography>
      <CardActions>
        <span className="card_product_price">{product.price} FCFA</span>
      </CardActions>
    </Card>
  );
}
