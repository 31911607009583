import EditViewIcon from "@material-ui/icons/Edit";

export const LoginIcon = () => {
  return (
    <img
      src="assets/images/logo-login.png"
      alt=""
      className="logo_login_icon"
    />
  );
};

export const EyeIcon = () => {
  return (
    <img src="assets/images/eye.svg" alt="eye" className="table_action_icons" />
  );
};
export const DeleteIcon = () => {
  return (
    <img
      src="assets/images/trash.svg"
      alt="eye"
      className="table_action_icons"
    />
  );
};
export const CheckIcon = () => {
  return (
    <img
      src="assets/images/check.svg"
      alt="eye"
      className="table_action_icons"
    />
  );
};

export const CloseIcon = () => {
  return (
    <img
      src="assets/images/close.svg"
      alt="eye"
      className="table_action_icons"
    />
  );
};
export const EditIcon = () => {
  return (
    <img
      src="assets/images/eye.svg"
      alt="edit"
      className="table_action_icons"
    />
  );
};
export const EditTableIcon = () => {
  return <EditViewIcon className="edit_table" />;
};

export const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
    >
      <g id="plus" transform="translate(-40 -76)">
        <rect
          id="Rectangle_2578"
          data-name="Rectangle 2578"
          width="33"
          height="3"
          rx="1.5"
          transform="translate(40 91)"
          fill="#707070"
        />
        <rect
          id="Rectangle_2579"
          data-name="Rectangle 2579"
          width="33"
          height="3"
          rx="1.5"
          transform="translate(58 76) rotate(90)"
          fill="#707070"
        />
      </g>
    </svg>
  );
};
