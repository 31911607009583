import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

function valueText(value) {
  return `${value}°C`;
}

export default function CommissionEarnedSlider() {
  const [value, setValue] = React.useState([20, 37]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const marks = [
    {
      value: 0,
      label: "Min",
    },

    {
      value: 100,
      label: "Max",
    },
  ];

  return (
    <Box sx={{ width: 300 }} className="commission_earned_slider">
      <Slider
        getAriaLabel={() => "Temperature range"}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="on"
        getAriaValueText={valueText}
        marks={marks}
        className="commission_slider"
      />
    </Box>
  );
}
