import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";

export const SidebarMenuTitleIcons = [
  {
    title: "Dashboard",
    icons: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="23"
        viewBox="0 0 23 23"
      >
        <path className="change_active"
          id="dashboard"
          d="M4.5,17.278H14.722V4.5H4.5ZM4.5,27.5H14.722V19.833H4.5Zm12.778,0H27.5V14.722H17.278Zm0-23v7.667H27.5V4.5Z"
          transform="translate(-4.5 -4.5)"
          fill="#6a6a6a"
        />
      </svg>
    ),
  },
  {
    title: "Users",
    icons: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26.049"
        height="18.235"
        viewBox="0 0 26.049 18.235"
      >
        <path className="change_active" 
          id="users"
          d="M3.907,10.065a2.6,2.6,0,1,0-2.6-2.6A2.607,2.607,0,0,0,3.907,10.065Zm18.235,0a2.6,2.6,0,1,0-2.6-2.6A2.607,2.607,0,0,0,22.142,10.065Zm1.3,1.3h-2.6A2.6,2.6,0,0,0,19,12.124a5.954,5.954,0,0,1,3.057,4.453h2.686a1.3,1.3,0,0,0,1.3-1.3v-1.3A2.607,2.607,0,0,0,23.445,11.367Zm-10.42,0A4.559,4.559,0,1,0,8.466,6.809,4.556,4.556,0,0,0,13.025,11.367Zm3.126,1.3h-.338a6.294,6.294,0,0,1-5.576,0H9.9A4.69,4.69,0,0,0,5.21,17.359v1.172a1.954,1.954,0,0,0,1.954,1.954H18.886a1.954,1.954,0,0,0,1.954-1.954V17.359A4.69,4.69,0,0,0,16.151,12.67Zm-9.105-.545a2.6,2.6,0,0,0-1.836-.757H2.6a2.607,2.607,0,0,0-2.6,2.6v1.3a1.3,1.3,0,0,0,1.3,1.3H3.985A5.969,5.969,0,0,1,7.046,12.124Z"
          transform="translate(0 -2.25)"
          fill="#6a6a6a"
        />
      </svg>
    ),
  },
  {
    title: "Category",
    icons: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23.002"
        height="26.145"
        viewBox="0 0 23.002 26.145"
      >
        <g id="category" transform="translate(0.5 0.5)" className="change_active">
          <g
            id="Group_4743"
            data-name="Group 4743"
            transform="translate(0 8.644)"
          >
            <path className="change_active" 
              id="Path_31641"
              data-name="Path 31641"
              d="M9.072,18.858H2.786A.785.785,0,0,1,2,18.072V11.786A.785.785,0,0,1,2.786,11H9.072a.785.785,0,0,1,.786.786v6.286A.785.785,0,0,1,9.072,18.858Zm-5.5-1.572H8.286V12.572H3.572Z"
              transform="translate(-2 -11)"
              fill="#6a6a6a"
              stroke="#6a6a6a"
              strokeWidth="1"
            />
          </g>
          <g
            id="Group_4744"
            data-name="Group 4744"
            transform="translate(14.144 14.144)"
          >
            <path className="change_active" 
              id="Path_31642"
              data-name="Path 31642"
              d="M27.072,25.858H20.786A.785.785,0,0,1,20,25.072V18.786A.785.785,0,0,1,20.786,18h6.286a.785.785,0,0,1,.786.786v6.286A.785.785,0,0,1,27.072,25.858Zm-5.5-1.572h4.715V19.572H21.572Z"
              transform="translate(-20 -18)"
              fill="#6a6a6a"
              stroke="#6a6a6a"
              strokeWidth="1"
            />
          </g>
          <g
            id="Group_4745"
            data-name="Group 4745"
            transform="translate(14.144 1.572)"
          >
            <path className="change_active"
              id="Path_31643"
              data-name="Path 31643"
              d="M27.072,9.858H20.786A.785.785,0,0,1,20,9.072V2.786A.785.785,0,0,1,20.786,2h6.286a.785.785,0,0,1,.786.786V9.072A.785.785,0,0,1,27.072,9.858Zm-5.5-1.572h4.715V3.572H21.572Z"
              transform="translate(-20 -2)"
              fill="#6a6a6a"
              stroke="#6a6a6a"
              strokeWidth="1"
            />
          </g>
          <g
            id="Group_4746"
            data-name="Group 4746"
            transform="translate(10.215)"
          >
            <path className="change_active"
              id="Path_31644"
              data-name="Path 31644"
              d="M15.786,25.145A.785.785,0,0,1,15,24.359V.786a.786.786,0,1,1,1.572,0V24.359A.785.785,0,0,1,15.786,25.145Z"
              transform="translate(-15)"
              fill="#6a6a6a"
              stroke="#6a6a6a"
              strokeWidth="1"
            />
          </g>
          <g
            id="Group_4747"
            data-name="Group 4747"
            transform="translate(6.286 11.787)"
          >
            <path className="change_active" 
              id="Path_31645"
              data-name="Path 31645"
              d="M14.715,16.572H10.786a.786.786,0,1,1,0-1.572h3.929a.786.786,0,1,1,0,1.572Z"
              transform="translate(-10 -15)"
              fill="#6a6a6a"
              stroke="#6a6a6a"
              strokeWidth="1"
            />
          </g>
          <g
            id="Group_4748"
            data-name="Group 4748"
            transform="translate(10.215 4.715)"
          >
            <path className="change_active" 
              id="Path_31646"
              data-name="Path 31646"
              d="M19.715,7.572H15.786a.786.786,0,0,1,0-1.572h3.929a.786.786,0,1,1,0,1.572Z"
              transform="translate(-15 -6)"
              fill="#6a6a6a"
              stroke="#6a6a6a"
              strokeWidth="1"
            />
          </g>
          <g
            id="Group_4749"
            data-name="Group 4749"
            transform="translate(10.215 17.287)"
          >
            <path className="change_active" 
              id="Path_31647"
              data-name="Path 31647"
              d="M19.715,23.572H15.786a.786.786,0,1,1,0-1.572h3.929a.786.786,0,1,1,0,1.572Z"
              transform="translate(-15 -22)"
              fill="#6a6a6a"
              stroke="#6a6a6a"
              strokeWidth="1"
            />
          </g>
        </g>
      </svg>
    ),
  },
  {
    title: "Orders",
    icons: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24.5"
        height="28"
        viewBox="0 0 24.5 28"
      >
        <path className="change_active" 
          id="orders"
          d="M19.25,8.75V7a7,7,0,0,0-14,0V8.75H0V23.625A4.375,4.375,0,0,0,4.375,28h15.75A4.375,4.375,0,0,0,24.5,23.625V8.75ZM8.75,7a3.5,3.5,0,0,1,7,0V8.75h-7Zm8.75,6.563a1.313,1.313,0,1,1,1.313-1.312A1.312,1.312,0,0,1,17.5,13.563Zm-10.5,0A1.313,1.313,0,1,1,8.313,12.25,1.312,1.312,0,0,1,7,13.563Z"
          fill="#6a6a6a"
        />
      </svg>
    ),
  },
  {
    title: "Products",
    icons: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28.572"
        height="20"
        viewBox="0 0 28.572 20"
      >
        <path className="change_active" 
          id="Products"
          d="M19,12.249A2.164,2.164,0,0,1,17.155,11.2l-2.87-4.759L11.418,11.2a2.171,2.171,0,0,1-1.853,1.049,2.053,2.053,0,0,1-.594-.085l-6.116-1.75v7.946a1.424,1.424,0,0,0,1.08,1.384l9.651,2.415a2.9,2.9,0,0,0,1.384,0l9.66-2.415a1.432,1.432,0,0,0,1.08-1.384V10.418L19.6,12.164A2.053,2.053,0,0,1,19,12.249ZM28.494,7.24l-2.3-4.589a.729.729,0,0,0-.746-.4L14.284,3.678l4.094,6.79a.734.734,0,0,0,.826.326l8.835-2.522a.735.735,0,0,0,.455-1.031ZM2.374,2.651.075,7.24A.728.728,0,0,0,.526,8.267L9.36,10.789a.734.734,0,0,0,.826-.326l4.1-6.785L3.115,2.254a.729.729,0,0,0-.741.4Z"
          transform="translate(0.004 -2.247)"
          fill="#6a6a6a"
        />
      </svg>
    ),
  },
  {
    title: "Commission",
    icons: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22.564"
        height="24.981"
        viewBox="0 0 22.564 24.981"
      >
        <g id="commission" transform="translate(0.283 0.25)">
          <circle className="change_active"
            id="Ellipse_335"
            data-name="Ellipse 335"
            cx="0.478"
            cy="0.478"
            r="0.478"
            transform="translate(5.738 11.476)"
            fill="#6a6a6a"
            stroke="#6a6a6a"
            strokeWidth="0.5"
          />
          <circle  className="change_active"
            id="Ellipse_336"
            data-name="Ellipse 336"
            cx="0.478"
            cy="0.478"
            r="0.478"
            transform="translate(3.825 7.65)"
            fill="#6a6a6a"
            stroke="#6a6a6a"
            strokeWidth="0.5"
          />
          <circle className="change_active"
            id="Ellipse_337"
            data-name="Ellipse 337"
            cx="0.478"
            cy="0.478"
            r="0.478"
            transform="translate(2.869 20.656)"
            fill="#6a6a6a"
            stroke="#6a6a6a"
            strokeWidth="0.5"
          />
          <path className="change_active" 
            id="Path_31658"
            data-name="Path 31658"
            d="M346.951,236.761h0a.856.856,0,0,1-.617-.361.478.478,0,0,0-.733.614,1.981,1.981,0,0,0,.867.621v.581a.478.478,0,0,0,.956,0v-.577a1.435,1.435,0,0,0-.47-2.79.478.478,0,0,1,0-.956.756.756,0,0,1,.522.25.478.478,0,0,0,.665-.687,1.845,1.845,0,0,0-.712-.443v-.534a.478.478,0,0,0-.956,0v.544a1.434,1.434,0,0,0,.486,2.783.478.478,0,0,1,0,.956Z"
            transform="translate(-330.207 -220.907)"
            fill="#6a6a6a"
            stroke="#6a6a6a"
            strokeWidth="0.5"
          />
          <path className="change_active" 
            id="Path_31659"
            data-name="Path 31659"
            d="M31.214,15.3A5.262,5.262,0,0,0,35.9,7.65h2.012a3.353,3.353,0,0,0,3.024-1.913h6.539a.478.478,0,0,0,.478-.478V.478A.478.478,0,0,0,47.475,0H30.643a.478.478,0,0,0-.371.176l-4.21,5.166a.478.478,0,0,0,.033.64,2.384,2.384,0,0,0,1.126.631A5.263,5.263,0,0,0,31.214,15.3ZM45.08.956H47V4.781H45.08Zm-1.913,0h.956V4.781h-.956Zm-16.05,4.6L30.87.956H42.211V4.781h-.987a3.37,3.37,0,0,0,.035-.478.478.478,0,0,0-.956,0,2.389,2.389,0,0,1-2.391,2.391H34.647A1.44,1.44,0,0,1,36,5.738h1.913a.478.478,0,0,0,.478-.478A2.393,2.393,0,0,0,36,2.869H31.435a.478.478,0,0,0-.338.14l-2.3,2.3A1.435,1.435,0,0,1,27.117,5.561Zm7.023.11a5.271,5.271,0,0,0-1.09-.559l1.287-1.287H36a1.437,1.437,0,0,1,1.352.956H36a2.4,2.4,0,0,0-1.86.89ZM32.984,3.825,31.973,4.836a5.244,5.244,0,0,0-1.328-.023l.988-.988Zm-1.77,1.913a4.284,4.284,0,0,1,2.483.789,2.384,2.384,0,0,0-.088.645.478.478,0,0,0,.478.478h.7a4.264,4.264,0,0,1,.725,2.391,4.3,4.3,0,1,1-4.3-4.3Z"
            transform="translate(-25.954)"
            fill="#6a6a6a"
            stroke="#6a6a6a"
            strokeWidth="0.5"
          />
          <path className="change_active" 
            id="Path_31660"
            data-name="Path 31660"
            d="M86.771,164.641l3.825-3.825a.478.478,0,0,0-.676-.676l-3.825,3.825a.478.478,0,0,0,.676.676Z"
            transform="translate(-83.085 -152.349)"
            fill="#6a6a6a"
            stroke="#6a6a6a"
            strokeWidth="0.5"
          />
          <circle className="change_active"
            id="Ellipse_338"
            data-name="Ellipse 338"
            cx="0.478"
            cy="0.478"
            r="0.478"
            transform="translate(11.476 23.525)"
            fill="#6a6a6a"
            stroke="#6a6a6a"
            strokeWidth="0.5"
          />
          <path className="change_active" 
            id="Path_31661"
            data-name="Path 31661"
            d="M26.433,207.3h9.324a.478.478,0,1,0,0-.956H31.693v-3.826h.991a3.384,3.384,0,0,0-.035.479.478.478,0,1,0,.956,0A2.388,2.388,0,0,1,36,200.606h3.265a1.44,1.44,0,0,1-1.353.957H36a.478.478,0,0,0-.478.478,2.393,2.393,0,0,0,2.391,2.391h4.565a.478.478,0,0,0,.338-.14l2.3-2.3a1.432,1.432,0,0,1,1.682-.255l-3.753,4.605H40.06a.478.478,0,0,0,0,.956h3.205a.478.478,0,0,0,.371-.176l4.211-5.166a.478.478,0,0,0-.033-.64,2.388,2.388,0,0,0-1.132-.634A5.258,5.258,0,1,0,38,199.65H36a3.354,3.354,0,0,0-3.024,1.913H26.433a.478.478,0,0,0-.478.478v4.781a.478.478,0,0,0,.478.478Zm13.138-3.825H37.909a1.437,1.437,0,0,1-1.352-.956h1.352a2.4,2.4,0,0,0,1.858-.888,5.27,5.27,0,0,0,1.091.558Zm2.7,0H40.924l1.011-1.011a5.251,5.251,0,0,0,1.329.023Zm.415-10.519a4.3,4.3,0,1,1-2.48,7.82,2.379,2.379,0,0,0,.089-.648.478.478,0,0,0-.478-.478h-.709a4.264,4.264,0,0,1-.725-2.391A4.308,4.308,0,0,1,42.69,192.956Zm-15.779,9.563h3.825v3.825H26.911Z"
            transform="translate(-25.955 -182.82)"
            fill="#6a6a6a"
            stroke="#6a6a6a"
            strokeWidth="0.5"
          />
        </g>
      </svg>
    ),
  },
  {
    title: "Driver User",
    icons: (
      <svg
        id="driver_"
        data-name="driver)"
        xmlns="http://www.w3.org/2000/svg"
        width="22.693"
        height="25.821"
        viewBox="0 0 22.693 25.821"
      >
        <path className="change_active"
          id="Path_31662"
          data-name="Path 31662"
          d="M102.963,3.681A7.543,7.543,0,0,0,90.09,3.54h0a1.458,1.458,0,0,0-.282,1.726,1.5,1.5,0,0,0,1.353.836h10.59a1.513,1.513,0,0,0,1.211-2.421Zm-5.436.151L96.457,4.9l-1.07-1.07,1.07-1.07Zm0,0"
          transform="translate(-85.11)"
          fill="#6a6a6a"
        />
        <path className="change_active"
          id="Path_31663"
          data-name="Path 31663"
          d="M257.506,290.855l-.028.029-3.322,11.123h1.15l2.558-3.888-.8-1.607,2.258-2.259Zm0,0"
          transform="translate(-241.339 -276.187)"
          fill="#6a6a6a"
        />
        <path className="change_active"
          id="Path_31664"
          data-name="Path 31664"
          d="M97.485,302.008h1.15l-3.322-11.124-.028-.029-1.813,3.4,2.258,2.259-.8,1.607Zm0,0"
          transform="translate(-88.759 -276.188)"
          fill="#6a6a6a"
        />
        <path className="change_active"
          id="Path_31665"
          data-name="Path 31665"
          d="M169.5,318.777l2.8,9.381,2.8-9.381a6.729,6.729,0,0,1-2.042.568v5.343h-1.513v-5.343A6.731,6.731,0,0,1,169.5,318.777Zm0,0"
          transform="translate(-160.948 -302.701)"
          fill="#6a6a6a"
        />
        <path className="change_active"
          id="Path_31666"
          data-name="Path 31666"
          d="M0,276.3v6.859H6.908l-2.486-3.778.709-1.418-2.281-2.28L5.345,271H5.3A5.3,5.3,0,0,0,0,276.3Zm0,0"
          transform="translate(0 -257.333)"
          fill="#6a6a6a"
        />
        <path className="change_active"
          id="Path_31667"
          data-name="Path 31667"
          d="M319.931,276.3a5.3,5.3,0,0,0-5.3-5.3h-.05l2.495,4.678-2.28,2.28.709,1.418-2.486,3.778h6.908Zm0,0"
          transform="translate(-297.237 -257.333)"
          fill="#6a6a6a"
        />
        <path className="change_active"
          id="Path_31668"
          data-name="Path 31668"
          d="M157.728,152.513a2.274,2.274,0,0,0,2.14-1.513h-7.306a2.274,2.274,0,0,0,2.14,1.513Zm0,0"
          transform="translate(-144.869 -143.385)"
          fill="#6a6a6a"
        />
        <path className="change_active"
          id="Path_31669"
          data-name="Path 31669"
          d="M120.077,151H120v2.269a5.3,5.3,0,0,0,10.59,0V151h-.077a3.788,3.788,0,0,1-3.706,3.026h-3.026A3.788,3.788,0,0,1,120.077,151Zm0,0"
          transform="translate(-113.949 -143.385)"
          fill="#6a6a6a"
        />
      </svg>
    ),
  },

  {
    title: "Content",
    icons: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18.75"
        height="25"
        viewBox="0 0 18.75 25"
      >
        <path className="change_active"
          id="content"
          d="M16.406,3.125H12.5a3.125,3.125,0,1,0-6.25,0H2.344A2.344,2.344,0,0,0,0,5.469V22.656A2.344,2.344,0,0,0,2.344,25H16.406a2.344,2.344,0,0,0,2.344-2.344V5.469A2.344,2.344,0,0,0,16.406,3.125ZM4.687,20.7a1.172,1.172,0,1,1,1.172-1.172A1.169,1.169,0,0,1,4.687,20.7Zm0-4.687a1.172,1.172,0,1,1,1.172-1.172A1.169,1.169,0,0,1,4.687,16.015Zm0-4.687a1.172,1.172,0,1,1,1.172-1.172A1.169,1.169,0,0,1,4.687,11.328ZM9.375,1.953A1.172,1.172,0,1,1,8.2,3.125,1.169,1.169,0,0,1,9.375,1.953Zm6.25,17.969a.392.392,0,0,1-.391.391H8.2a.392.392,0,0,1-.391-.391V19.14A.392.392,0,0,1,8.2,18.75h7.031a.392.392,0,0,1,.391.391Zm0-4.687a.392.392,0,0,1-.391.391H8.2a.392.392,0,0,1-.391-.391v-.781a.392.392,0,0,1,.391-.391h7.031a.392.392,0,0,1,.391.391Zm0-4.687a.392.392,0,0,1-.391.391H8.2a.392.392,0,0,1-.391-.391V9.765A.392.392,0,0,1,8.2,9.375h7.031a.392.392,0,0,1,.391.391Z"
          fill="#6a6a6a"
        />
      </svg>
    ),
  },
  {
    title: "Sub Admin",
    icons: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18.75"
        height="25"
        viewBox="0 0 18.75 25"
      >
        <path className="change_active"
          id="sub-admin"
          d="M16.406,3.125H12.5a3.125,3.125,0,1,0-6.25,0H2.344A2.344,2.344,0,0,0,0,5.469V22.656A2.344,2.344,0,0,0,2.344,25H16.406a2.344,2.344,0,0,0,2.344-2.344V5.469A2.344,2.344,0,0,0,16.406,3.125ZM4.687,20.7a1.172,1.172,0,1,1,1.172-1.172A1.169,1.169,0,0,1,4.687,20.7Zm0-4.687a1.172,1.172,0,1,1,1.172-1.172A1.169,1.169,0,0,1,4.687,16.015Zm0-4.687a1.172,1.172,0,1,1,1.172-1.172A1.169,1.169,0,0,1,4.687,11.328ZM9.375,1.953A1.172,1.172,0,1,1,8.2,3.125,1.169,1.169,0,0,1,9.375,1.953Zm6.25,17.969a.392.392,0,0,1-.391.391H8.2a.392.392,0,0,1-.391-.391V19.14A.392.392,0,0,1,8.2,18.75h7.031a.392.392,0,0,1,.391.391Zm0-4.687a.392.392,0,0,1-.391.391H8.2a.392.392,0,0,1-.391-.391v-.781a.392.392,0,0,1,.391-.391h7.031a.392.392,0,0,1,.391.391Zm0-4.687a.392.392,0,0,1-.391.391H8.2a.392.392,0,0,1-.391-.391V9.765A.392.392,0,0,1,8.2,9.375h7.031a.392.392,0,0,1,.391.391Z"
          fill="#6a6a6a"
        />
      </svg>
    ),
  },
  {
    title: "Delivery Fees",
    icons: (
      <svg
        id="delivery-fees"
        xmlns="http://www.w3.org/2000/svg"
        width="23.648"
        height="23.649"
        viewBox="0 0 23.648 23.649"
      >
        <path className="change_active"
          id="Path_31687"
          data-name="Path 31687"
          d="M92.252,4.2h5.174a12.3,12.3,0,0,1,2.055-3.025A.657.657,0,0,0,99.633.43.691.691,0,0,0,99,0,4,4,0,0,0,96.53.8a2.554,2.554,0,0,1-1.691.582A2.554,2.554,0,0,1,93.148.8,4,4,0,0,0,90.682,0a.691.691,0,0,0-.637.43.657.657,0,0,0,.152.748A12.3,12.3,0,0,1,92.252,4.2Z"
          transform="translate(-85.832 0)"
          fill="#6a6a6a"
        />
        <path className="change_active"
          id="Path_31688"
          data-name="Path 31688"
          d="M301,452.693v1.386a.693.693,0,0,0,.693.693h8.36a.693.693,0,0,0,.693-.693v-1.386a.693.693,0,0,0-.693-.693h-8.36A.693.693,0,0,0,301,452.693Z"
          transform="translate(-287.097 -431.123)"
          fill="#6a6a6a"
        />
        <path className="change_active"
          id="Path_31689"
          data-name="Path 31689"
          d="M310.746,364.079v-1.386a.693.693,0,0,0-.693-.693h-8.36a.693.693,0,0,0-.693.693v1.386a.693.693,0,0,0,.693.693h8.36A.693.693,0,0,0,310.746,364.079Z"
          transform="translate(-287.097 -345.28)"
          fill="#6a6a6a"
        />
        <path className="change_active"
          id="Path_31690"
          data-name="Path 31690"
          d="M13.815,126.617c-1.358-1.33-2.466-2.457-2.688-4.231h.651a.693.693,0,1,0,0-1.386H6.236a.693.693,0,1,0,0,1.386h.651c-.222,1.774-1.33,2.9-2.688,4.231-1.968,1.926-4.2,4.115-4.2,9.118a3.448,3.448,0,0,0,3.464,3.326H12.6a2.055,2.055,0,0,1-.127-.693v-5.543a2.081,2.081,0,0,1,2.079-2.079h2.482A14.058,14.058,0,0,0,13.815,126.617Zm-4.808,4.129a2.073,2.073,0,0,1,.693,4.029v.82a.693.693,0,0,1-1.386,0v-.82a2.075,2.075,0,0,1-1.386-1.951.693.693,0,1,1,1.386,0,.693.693,0,1,0,.693-.693,2.073,2.073,0,0,1-.693-4.029v-.82a.693.693,0,1,1,1.386,0v.82a2.075,2.075,0,0,1,1.386,1.951.693.693,0,0,1-1.386,0,.693.693,0,1,0-.693.693Z"
          transform="translate(0 -115.411)"
          fill="#6a6a6a"
        />
      </svg>
    ),
  },
  {
    title: "Transactions",
    icons: (
      <svg
        id="Transactions"
        xmlns="http://www.w3.org/2000/svg"
        width="26.248"
        height="26.248"
        viewBox="0 0 26.248 26.248"
      >
        <path className="change_active"
          id="Path_31687"
          data-name="Path 31687"
          d="M92.5,4.665h5.743a13.655,13.655,0,0,1,2.281-3.358.729.729,0,0,0,.169-.831A.767.767,0,0,0,99.986,0a4.441,4.441,0,0,0-2.738.892,2.835,2.835,0,0,1-1.876.646A2.835,2.835,0,0,1,93.5.892,4.441,4.441,0,0,0,90.758,0a.767.767,0,0,0-.707.477.729.729,0,0,0,.169.831A13.653,13.653,0,0,1,92.5,4.665Z"
          transform="translate(-85.375 0)"
          fill="#6a6a6a"
        />
        <path className="change_active"
          id="Path_31688"
          data-name="Path 31688"
          d="M301,452.769v1.538a.769.769,0,0,0,.769.769h9.279a.769.769,0,0,0,.769-.769v-1.538a.769.769,0,0,0-.769-.769h-9.279A.769.769,0,0,0,301,452.769Z"
          transform="translate(-285.569 -428.828)"
          fill="#6a6a6a"
        />
        <path className="change_active"
          id="Path_31689"
          data-name="Path 31689"
          d="M311.817,364.307v-1.538a.769.769,0,0,0-.769-.769h-9.279a.769.769,0,0,0-.769.769v1.538a.769.769,0,0,0,.769.769h9.279A.769.769,0,0,0,311.817,364.307Z"
          transform="translate(-285.569 -343.442)"
          fill="#6a6a6a"
        />
        <path className="change_active"
          id="Path_31690"
          data-name="Path 31690"
          d="M15.333,127.234c-1.507-1.476-2.738-2.727-2.984-4.7h.723a.769.769,0,1,0,0-1.538H6.921a.769.769,0,1,0,0,1.538h.723c-.246,1.969-1.476,3.219-2.984,4.7C2.476,129.372,0,131.8,0,137.354a3.827,3.827,0,0,0,3.845,3.691H13.983a2.281,2.281,0,0,1-.141-.769v-6.152a2.31,2.31,0,0,1,2.307-2.307H18.9A15.6,15.6,0,0,0,15.333,127.234ZM10,131.817a2.3,2.3,0,0,1,.769,4.472v.911a.769.769,0,0,1-1.538,0v-.911a2.3,2.3,0,0,1-1.538-2.165.769.769,0,1,1,1.538,0,.769.769,0,1,0,.769-.769,2.3,2.3,0,0,1-.769-4.472v-.911a.769.769,0,1,1,1.538,0v.911a2.3,2.3,0,0,1,1.538,2.165.769.769,0,0,1-1.538,0,.769.769,0,1,0-.769.769Z"
          transform="translate(0 -114.797)"
          fill="#6a6a6a"
        />
      </svg>
    ),
  },
  {
    title: "Notifications",
    icons: (
      <svg
        id="Notifications"
        xmlns="http://www.w3.org/2000/svg"
        width="21.996"
        height="27.513"
        viewBox="0 0 21.996 27.513"
      >
        <path className="change_active"
          id="Path_31691"
          data-name="Path 31691"
          d="M17.918,31.981c2.139,0,3.308-1.513,3.308-3.645H14.6C14.6,30.468,15.773,31.981,17.918,31.981Z"
          transform="translate(-6.933 -4.467)"
          fill="#6a6a6a"
        />
        <path className="change_active"
          id="Path_31692"
          data-name="Path 31692"
          d="M28.48,24.306c-1.059-1.4-3.143-2.214-3.143-8.465,0-6.416-2.833-8.995-5.474-9.613-.248-.062-.426-.144-.426-.406v-.2a1.678,1.678,0,1,0-3.356,0v.2c0,.254-.179.344-.426.406-2.647.626-5.474,3.2-5.474,9.613,0,6.251-2.084,7.062-3.143,8.465a1.365,1.365,0,0,0,1.093,2.187H27.394A1.365,1.365,0,0,0,28.48,24.306Z"
          transform="translate(-6.761 -3.93)"
          fill="#6a6a6a"
        />
      </svg>
    ),
  },
  {
    title: "Report/Feedback",
    icons: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25.375"
        height="25.375"
        viewBox="0 0 25.375 25.375"
      >
        <path className="change_active"
          id="Report_Feedback_"
          data-name="Report/Feedback "
          d="M22.446,4.5H11.929L4.5,11.929V22.446l7.429,7.429H22.446l7.429-7.429V11.929ZM17.188,24.659a1.833,1.833,0,1,1,1.833-1.833A1.829,1.829,0,0,1,17.188,24.659ZM18.6,18.6H15.778V10.139H18.6Z"
          transform="translate(-4.5 -4.5)"
          fill="#6a6a6a"
        />
      </svg>
    ),
  },
  {
    title: "Video",
    icons: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23.16"
        height="15.44"
        viewBox="0 0 23.16 15.44"
      >
        <path className="change_active"
          id="Video"
          d="M13.518,4.5H1.922A1.922,1.922,0,0,0,0,6.422v11.6A1.922,1.922,0,0,0,1.922,19.94h11.6a1.922,1.922,0,0,0,1.922-1.922V6.422A1.922,1.922,0,0,0,13.518,4.5Zm7.616,1.516-4.407,3.04v6.329l4.407,3.036a1.289,1.289,0,0,0,2.027-1.037V7.053A1.29,1.29,0,0,0,21.134,6.016Z"
          transform="translate(0 -4.5)"
          fill="#6a6a6a"
        />
      </svg>
    ),
  },
  {
    title: "Attributes",
    icons: (
      <svg
        id="attributes"
        xmlns="http://www.w3.org/2000/svg"
        width="19.068"
        height="19.036"
        viewBox="0 0 19.068 19.036"
      >
        <path className="change_active"
          id="XMLID_481_"
          d="M0,6.231H5.8V.434H0ZM3.465,3.272v.06a.567.567,0,0,1-1.133,0v-.06a.567.567,0,0,1,1.133,0Z"
          transform="translate(0 -0.434)"
          fill="#6a6a6a"
        />
        <path className="change_active"
          id="XMLID_487_"
          d="M179.86.434v5.8h12.275V.434Zm4.7,3.465V2.766h2.876V3.9Z"
          transform="translate(-173.066 -0.434)"
          fill="#6a6a6a"
        />
        <path className="change_active"
          id="XMLID_492_"
          d="M0,181.059H5.8v-5.8H0Zm3.465-2.929v.06a.567.567,0,0,1-1.133,0v-.06a.567.567,0,1,1,1.133,0Z"
          transform="translate(0 -168.659)"
          fill="#6a6a6a"
        />
        <path className="change_active"
          id="XMLID_1102_"
          d="M179.86,181.059h12.275v-5.8H179.86Zm7.575-3.465v1.133H184.56v-1.133Z"
          transform="translate(-173.066 -168.659)"
          fill="#6a6a6a"
        />
        <path className="change_active"
          id="XMLID_1105_"
          d="M0,356.736H5.8v-5.8H0Zm3.465-2.93v.06a.567.567,0,0,1-1.133,0v-.06a.567.567,0,1,1,1.133,0Z"
          transform="translate(0 -337.7)"
          fill="#6a6a6a"
        />
        <path className="change_active"
          id="XMLID_1108_"
          d="M179.86,356.736h12.275v-5.8H179.86Zm7.575-3.5v1.133H184.56v-1.133Z"
          transform="translate(-173.066 -337.7)"
          fill="#6a6a6a"
        />
      </svg>
    ),
  },
];

// Super admin menus

export const SidebarRestaurantMenuTitleIcons = [
  {
    title: "All Orders",
    icons: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24.734"
        height="17.313"
        viewBox="0 0 24.734 17.313"
      >
        <path className="change_active"
          id="orders"
          d="M16.449,10.905a1.873,1.873,0,0,1-1.6-.9L12.365,5.881,9.884,10a1.88,1.88,0,0,1-1.6.908,1.777,1.777,0,0,1-.514-.073L2.472,9.321V16.2a1.233,1.233,0,0,0,.935,1.2l8.355,2.091a2.512,2.512,0,0,0,1.2,0L21.322,17.4a1.239,1.239,0,0,0,.935-1.2V9.321l-5.294,1.511A1.777,1.777,0,0,1,16.449,10.905Zm8.216-4.336L22.675,2.6a.631.631,0,0,0-.645-.344L12.365,3.485l3.544,5.878a.635.635,0,0,0,.715.282l7.648-2.183a.636.636,0,0,0,.394-.893ZM2.055,2.6.064,6.569a.63.63,0,0,0,.39.889L8.1,9.641a.635.635,0,0,0,.715-.282l3.548-5.874L2.7,2.253A.631.631,0,0,0,2.055,2.6Z"
          transform="translate(0.003 -2.247)"
        />
      </svg>
    ),
  },
  {
    title: "Menu",
    icons: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24.734"
        height="17.313"
        viewBox="0 0 24.734 17.313"
      >
        <path className="change_active"
          id="orders"
          d="M16.449,10.905a1.873,1.873,0,0,1-1.6-.9L12.365,5.881,9.884,10a1.88,1.88,0,0,1-1.6.908,1.777,1.777,0,0,1-.514-.073L2.472,9.321V16.2a1.233,1.233,0,0,0,.935,1.2l8.355,2.091a2.512,2.512,0,0,0,1.2,0L21.322,17.4a1.239,1.239,0,0,0,.935-1.2V9.321l-5.294,1.511A1.777,1.777,0,0,1,16.449,10.905Zm8.216-4.336L22.675,2.6a.631.631,0,0,0-.645-.344L12.365,3.485l3.544,5.878a.635.635,0,0,0,.715.282l7.648-2.183a.636.636,0,0,0,.394-.893ZM2.055,2.6.064,6.569a.63.63,0,0,0,.39.889L8.1,9.641a.635.635,0,0,0,.715-.282l3.548-5.874L2.7,2.253A.631.631,0,0,0,2.055,2.6Z"
          transform="translate(0.003 -2.247)"
        />
      </svg>
    ),
  },
  {
    title: "Promo code",
    icons: <LocalGroceryStoreIcon />,
  },
  {
    title: "Profile",
    icons: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17.242"
        height="17.242"
        viewBox="0 0 17.242 17.242"
      >
        <path className="change_active"
          id="profile"
          d="M8.621,9.7A4.849,4.849,0,1,0,3.772,4.849,4.851,4.851,0,0,0,8.621,9.7Zm4.311,1.078H11.076a5.862,5.862,0,0,1-4.91,0H4.311A4.31,4.31,0,0,0,0,15.087v.539a1.617,1.617,0,0,0,1.616,1.616H15.626a1.617,1.617,0,0,0,1.616-1.616v-.539A4.31,4.31,0,0,0,12.932,10.776Z"
        />
      </svg>
    ),
  },
  {
    title: "Logout",
    icons: (
      <svg
        id="logout"
        xmlns="http://www.w3.org/2000/svg"
        width="21.68"
        height="21.68"
        viewBox="0 0 21.68 21.68"
      >
        <path className="change_active"
          id="Path_31720"
          data-name="Path 31720"
          d="M9.936,13.55a2.71,2.71,0,1,1,0-5.42h4.517V2.484A2.486,2.486,0,0,0,11.969,0H2.484A2.486,2.486,0,0,0,0,2.484V19.2a2.486,2.486,0,0,0,2.484,2.484h9.485A2.486,2.486,0,0,0,14.453,19.2V13.55Z"
        />
        <path className="change_active"
          id="Path_31721"
          data-name="Path 31721"
          d="M17.784,15.964a.9.9,0,0,1-.557-.835V12.42H10.9a.9.9,0,0,1,0-1.807h6.323V7.9a.9.9,0,0,1,1.542-.639l3.613,3.613a.9.9,0,0,1,0,1.277l-3.613,3.613A.9.9,0,0,1,17.784,15.964Z"
          transform="translate(-0.967 -0.677)"
        />
      </svg>
    ),
  },
];
