import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
  Tooltip,
} from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FormGroup from "@mui/material/FormGroup";
import { useDispatch, useSelector } from "react-redux";
import { addAttributeAction } from "../../services/actions/attribute.action";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import InfoIcon from "@mui/icons-material/Info";

function AddParamAttributes() {
  const dispatch = useDispatch();
  const { singleAttributeData } = useSelector(
    (state) => state.singleAttributeData
  );
  const history = useHistory();
  const alert = useAlert();
  const [parameterArr, setParameterArr] = useState([]);
  const [parameter, setParameter] = useState("");
  const [subParameter, setSubParameter] = useState("");
  const [subParameterArr, setSubParameterArr] = useState([]);
  const [toggleSubParameter, setToggleSubParameter] = useState(false);
  const [paraData, setParaData] = useState(null);
  const [subParaData, setSubParaData] = useState(null);
  const [editParaAction, setEditParaAction] = useState(false);
  const [editSubParaAction, setEditSubParaAction] = useState(false);
  const [count, setCount] = useState([]);

  useEffect(() => {
    if (singleAttributeData && singleAttributeData.parameter) {
      setParameterArr(singleAttributeData && singleAttributeData.parameter);
      let arr = singleAttributeData && singleAttributeData?.parameter
      let temp = []
      arr.map(item => { if (item?.showInTopFilters == true) { temp.push(item.id) } })

      setCount(temp)

    }
  }, [singleAttributeData]);

  console.log(count)


  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (event.target.value) {
        let Boolean = false;
        if (parameterArr.length !== 0) {
          const ele = parameterArr.map((element) => {
            return element.parameterName;
          });
          let isExist = ele.length !== 0 && ele.includes(event.target.value);
          if (isExist) {
            Boolean = isExist;
          } else {
            Boolean = false;
          }
        }

        if (Boolean) {
          alert.info(`${event.target.value} Parameter already exists`);
        } else {
          if (editParaAction) {
            updateParameter(event);
          } else {
            setParameterArr((pre) => [
              ...pre,
              {
                id: Math.floor(Math.random() * 10000 + 1),
                parameterName: event.target.value,
                subParameter: [],
                showInFilters: false,
                showInTopFilters: false,
              },
            ]);
            setParameter("");
          }
        }
      }
    }
  };

  const handleSubKeyDown = (event) => {
    if (event.target.value) {
      if (event.key === "Enter") {
        let Boolean = false;
        if (subParameterArr.length !== 0) {
          let isExist = subParameterArr.includes(event.target.value);
          if (isExist) {
            Boolean = isExist;
          } else {
            Boolean = false;
          }
        }

        if (Boolean) {
          alert.info(`${event.target.value} sub-Parameter already exists`);
        } else {
          if (editSubParaAction) {
            updateSubParameter(event);
          } else {
            setSubParameterArr((pre) => [...pre, event.target.value]);
          }
          setSubParameter("");
        }
      }
    }
  };

  const updateParameter = (e) => {
    if (parameterArr.length > 0) {
      for (let obj of parameterArr) {
        if (obj.parameterName == paraData.parameterName) {
          obj.parameterName = e.target.value;

          setEditParaAction(false);
          break;
        }
      }
    } else {
      setEditParaAction(false);
    }
  };

  const updateSubParameter = (e) => {
    if (subParameterArr.length > 0) {
      let index = subParameterArr.findIndex((item) => item == subParaData);

      subParameterArr[index] = e.target.value;
      setEditSubParaAction(false);
    } else {
      setEditSubParaAction(false);
    }
  };

  const deleteParameter = (item) => {
    setToggleSubParameter(false);
    let data = parameterArr.filter(
      (item1) => item1.parameterName !== item.parameterName
    );
    setParameterArr(data);
  };

  const deleteSubParameter = (item) => {
    let data = subParameterArr.filter((item1) => item1 !== item);
    setSubParameterArr(data);
  };

  useEffect(() => {
    if (paraData) {
      if (parameterArr.length > 0) {
        for (let obj of parameterArr) {
          if (obj.parameterName == paraData.parameterName) {
            obj.subParameter = subParameterArr;

            setEditParaAction(false);
            break;
          }
        }
      }
    }
  }, [parameterArr, paraData, subParameterArr]);


  const handleChecked = (e, item, type) => {
    if (type !== "top") {
      if (parameterArr.length > 0) {
        for (let obj of parameterArr) {
          if (obj.parameterName == item.parameterName) {
            obj.showInFilters = e.target.checked;

            break;
          }
        }
      }
    } else {
      if (parameterArr.length > 0) {
        for (let obj of parameterArr) {
          if (obj.parameterName == item.parameterName) {

            if (e.target.checked == false) {
              let temp = count.length > 0 && count.filter(c => c != item.id)

              setCount(temp)

            } else {

              setCount(pre => [...pre, item.id])
            }
            obj.showInTopFilters = e.target.checked; break;
          }
        }
      }
    }
  };




  return (
    <div>
      <Row>
        <Col xs={12}>
          <h6>Add Parameter</h6>


        </Col>
      </Row>
      <div className="form_activity form_add_product row mt-2">
        <Form.Group className="mb-2 col-sm-12 col-md-6">
          <InputGroup className="mb-2">
            <FormControl
              placeholder="Enter Text"
              aria-label="parameter"
              aria-describedby="basic-addon1"
              value={parameter}
              onChange={(e) => setParameter(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </InputGroup>
        </Form.Group>
      </div>

      <Row>
        <Col xs={12} md={6} className="pb-4">
          {count.length == 2 && <p className="text-danger m-2"><InfoIcon className="info_icon mr-1" />Only two Top filters are allowed.</p>}
          <div className="brand_select_box ">
            {parameterArr &&
              parameterArr.length !== 0 &&
              parameterArr.map((item, index) => (
                <div key={index}>


                  <div
                    className={
                      paraData && paraData.parameterName == item.parameterName
                        ? "brand_btn"
                        : "brand_btn brand_grey_btn"
                    }
                    onClick={() => {
                      setParaData(item);
                      setSubParameterArr(item.subParameter);
                    }}
                  >
                    <p onClick={() => setToggleSubParameter(true)}>
                      {item.parameterName}
                    </p>
                    <div>
                      <EditIcon
                        onClick={() => {
                          setEditParaAction(true);
                          setParameter(item.parameterName);
                        }}
                      />
                      <DeleteIcon onClick={() => deleteParameter(item)} />
                    </div>
                  </div>

                  <FormGroup className="mt-2 brand_check_box">
                    <Form.Check
                      type="checkbox"
                      label="Show in filter"
                      defaultChecked={item.showInFilters}
                      onChange={(e) => {
                        handleChecked(e, item, "in");
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Show in top filter"
                      defaultChecked={item.showInTopFilters}
                      disabled={count.includes(item.id) ? false : count.length > 1 ? true : false}
                      onChange={(e) => {
                        handleChecked(e, item, "top");
                      }}
                    />
                  </FormGroup>
                </div>
              ))}
          </div>
        </Col>
        {toggleSubParameter && toggleSubParameter && (
          <Col xs={12} className="pb-3">
            <div className="add_brand_box">
              <h6>Add {paraData && paraData.parameterName}</h6>
              <Form.Group>
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Enter the brand"
                    aria-label="price"
                    aria-describedby="basic-addon1"
                    value={subParameter}
                    onChange={(e) => setSubParameter(e.target.value)}
                    onKeyDown={handleSubKeyDown}
                  />
                </InputGroup>
              </Form.Group>
              {subParameterArr &&
                subParameterArr.length > 0 &&
                subParameterArr.map((item, index) => (
                  <div key={index}>
                    <div className="brand_list">
                      <h5> {item}</h5>
                      <div>
                        <EditIcon
                          onClick={() => {
                            setEditSubParaAction(true);
                            setSubParaData(item);
                            setSubParameter(item);
                          }}
                        />
                        <DeleteIcon onClick={() => deleteSubParameter(item)} />
                      </div>
                    </div>
                    <hr />
                  </div>
                ))}
            </div>
          </Col>
        )}
        <Col sm={12} xl={12}>
          <div className="submit_product text-right">
            <Button
              className="btn_cancel"
              onClick={() => history.push("/attributes")}
            >
              Cancel
            </Button>
            <Button
              className="btn_submit"
              onClick={() => dispatch(addAttributeAction(parameterArr))}
            >
              Submit
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default AddParamAttributes;
