import React, { useEffect, useState } from "react";

import MaterialTable from "material-table";
import Box from "@mui/material/Box";
import { DeleteIcon } from "../../utils/MenusList/Icons";
import { useAlert } from "react-alert";
import Skeleton from "@mui/material/Skeleton";
import CheckButton from "./CheckButton";
import { Button } from "react-bootstrap";
import { useFetch } from "../../Hooks/useFetch";
import { Api } from "../../utils/API/Api";
import axios from "axios";
function CommissionDataTable() {
  const { data, loading, reFetchData } = useFetch(Api.getCommissions);
  const alert = useAlert();

  const [payList, setPayList] = React.useState([]);

  const handleDelete = async (id) => {
    await axios
      .put(Api.deleteCommission, { _id: id })
      .then((res) => {
        if (res.data.status === 1) {
          alert.success(res.data.message);
          reFetchData();
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => {
        alert.error(err.message);
      });
  };

  const columns = [
    {
      title: "#",
      field: "index",
    },
    { title: "User Name", field: "userName" },
    { title: "Product Name", field: "productName" },
    {
      title: "Earned Commission",
      field: "earnedCommission",
    },
    { title: "Mobile Money no", field: "moneyPhoneNumber" },
    { title: "Date", field: "createdAt" },
    { title: "Order", field: "order" },
    {
      title: "Status",
      field: "status",
      render: (item) => (
        <span>
          {item.status === "false" ? (
            <span className="text-warning">
              <strong>Pending</strong>
            </span>
          ) : (
            <span className="text-success ">
              <strong>Success</strong>
            </span>
          )}
        </span>
      ),
    },
    { title: "Pay", field: "pay" },
    {
      title: "Action",
      field: "action",
      render: (item) => (
        <span onClick={() => handleDelete(item.action)}>
          <DeleteIcon />
        </span>
      ),
    },
  ];
  const options = {
    print: true,
    download: true,
    filter: true,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 50, 100],
    actionsColumnIndex: -1,
    selection: true,
    showSelectAllCheckbox: true,
    showTitle: false,
  };

  let attributeData =
    data === null
      ? []
      : data &&
        data.map((item, index) => {
          return {
            index: index + 1,
            username: item.userId?.fullName,
            productName: item.productId?.productName,
            earnedCommission: item.productId?.commission,
            order: item.orderId,
            status: item.paidToUsers ? "true" : "false",
            pay: item.paymentMode,
            createdAt: item?.createdAt,
            moneyPhoneNumber: item?.userId?.moneyPhoneNumber,
            _id: item._id,
            action: item._id,
          };
        });

  const handlePay = async () => {
    await axios
      .put(Api.payCommissions, { commission: payList })
      .then((res) => {
        if (res.data.status === 1) {
          alert.success(res.data.message);
          reFetchData();
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => {
        alert.error(err.message);
      });
  };

  const handleSelect = (item) => {
    if (payList.length > 0) {
      if (payList.includes(item)) {
        let temp = payList.filter((s) => s !== item);

        setPayList(temp);
      } else {
        setPayList((pre) => [...pre, item]);
      }
    } else {
      setPayList((pre) => [...pre, item]);
    }
  };

  useEffect(() => {
    setPayList([]);
  }, []);

  return (
    <>
      <Box className="commission_table">
        {loading ? (
          <Box>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        ) : (
          <>
            <Button className="btn_pay" onClick={() => handlePay()}>
              Pay
            </Button>
            <MaterialTable
              options={options && options}
              data={attributeData && attributeData}
              columns={columns && columns}
              title=""
              onRowClick={(event, rowData) => handleSelect(rowData._id)}
              // actions={actions && actions}
            />
          </>
        )}
      </Box>
    </>
  );
}

export default CommissionDataTable;
