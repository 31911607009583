const baseUri =
  // process.env.NODE_ENV == "development"
  //   ? process.env.REACT_APP_API_LOCAL_URI + "/admin/"
  //   :
  process.env.REACT_APP_API_URI + "/admin/";
const baseUserUri = process.env.REACT_APP_API_URI + "/user/";

export const Api = {
  login: `${baseUri}admin-login`,
  getUsers: `${baseUri}get-users`,
  addCategories: `${baseUri}add-categories`,
  addSubCategories: `${baseUri}add-sub_category`,
  addSubSubCategories: `${baseUri}add-sub_sub_categories`,
  getCategories: `${baseUserUri}get-categories`,
  getSubCategory: `${baseUserUri}get-sub_category`,
  getSubSubCategory: `${baseUri}get-sub_sub_categories`,

  // EDIT Category

  editCategory: `${baseUri}edit-categories`,
  editSubCategory: `${baseUri}edit-sub_categories`,
  editSubSubCategory: `${baseUri}edit-sub-sub_categories`,
  deleteCategory: `${baseUri}delete-category`,
  deleteSubCategory: `${baseUri}delete-sub-category`,
  deleteSubSubCategory: `${baseUri}delete-sub-sub-category`,

  // Swapping categories
  swapCategory: `${baseUri}swapping`,
  subSwapCategory: `${baseUri}sub_category_swapping`,
  subSubSwapCategory: `${baseUri}subsub_category_swapping`,

  // add product api
  getProducts: `${baseUri}get-products`,
  getProductsWithFilter: `${baseUserUri}get-products-with-filter`,
  subcategoryAndSubSubCategory: `${baseUri}subcategory-and-sub-sub-category`,
  getSizeColor: `${baseUri}get-attribute`,
  uploadSingleImage: `${baseUri}upload-single-image`,
  uploadMultipleImages: `${baseUri}upload-image`,
  addProducts: `${baseUri}add-products`,
  editProducts: `${baseUri}edit-product`,
  deleteProducts: `${baseUri}delete-product`,

  // Attributes api

  addAttribute: `${baseUri}add-attribute`,
  allAttribute: `${baseUri}all-attribute`,
  getAttribute: `${baseUri}product-attribute`,
  deleteAttribute: `${baseUri}delete-attribute`,

  // commission api

  getCommissions: `${baseUri}get-commissions`,
  payCommissions: `${baseUri}pay-commission`,
  deleteCommission: `${baseUri}delete-commission`,

  // driver
  getDrivers: `${baseUri}get-drivers`,
  addDriver: `${baseUri}add-driver`,
  editDriver: `${baseUri}edit-driver`,
  deleteDriver: `${baseUri}delete-driver`,
  driverAction: `${baseUri}driver-action`,
  singleDriver: `${baseUri}driver`,

  // delivery fee
  deliveryFee: `${baseUri}delivery_fee`,
  addDeliveryFee: `${baseUri}add_delivery_fee`,
  editDeliveryFee: `${baseUri}edit_delivery_fee`,
  deleteDeliveryFee: `${baseUri}delete_delivery_fee`,

  // orders

  getAllOrders: `${baseUri}get_all_orders`,
  deleteOrder: `${baseUri}delete_order`,
};
