import React from "react";

import MaterialTable from "material-table";
import Box from "@mui/material/Box";
import { DeleteIcon, EditIcon } from "../../utils/MenusList/Icons";
import { useFetch } from "../../Hooks/useFetch";
import { Api } from "./../../utils/API/Api";
import { Skeleton } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { deleteAPIFun } from "../../middleware/apiMiddleware";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";

export default function OrderTable() {
  const { data, loading, reFetchData } = useFetch(Api.getAllOrders);

  const { token } = useSelector((state) => state.auth);

  const alert = useAlert();
  const [driverList, setDriverList] = useState([]);

  useEffect(() => {
    setDriverList(data);
  }, [data]);

  const deleteOrder = async (id) => {
    const copy = [...driverList];

    if (id) {
      const { data, error } = await deleteAPIFun(
        Api.deleteOrder + "/" + id,
        token
      );
      if (error) {
        alert.error(error?.message);
      } else {
        if (data?.success) {
          alert.success(data?.message);
          setDriverList(copy.filter((item) => item?._id !== id));
        } else {
          alert.error(data?.message);
        }
      }
    }
  };

  const columns = [
    {
      title: "#",
      field: "index",
    },
    { title: "Order ID", field: "orderId" },
    { title: "User Name", field: "username" },
    { title: "Date", field: "date" },
    { title: "Quantity", field: "quantity" },
    { title: "Total Price (FCFA)", field: "orderTotal" },
    { title: "Order Status", field: "orderStatus" },
    { title: "Assign", field: "assign" },
    { title: "Client", field: "client" },
    { title: "Address", field: "orderAddress" },
  ];

  let driverData =
    driverList.length > 0
      ? driverList.map((driver, index) => {
          return {
            index,
            _id: driver?._id,
            orderId: driver?.orderId,
            username: driver?.orderAddress?.contactDetails?.name,
            date: driver?.createdAt?.slice(0, 10),
            quantity: driver?.quantity,
            orderTotal: driver?.orderTotal,
            orderStatus: driver?.orderStatus,
            orderAddress:
              driver?.orderAddress?.address?.houseNo &&
              `${driver?.orderAddress?.address?.houseNo} 
              ${driver?.orderAddress?.address?.area} ${driver?.orderAddress?.address?.city} ${driver?.orderAddress?.address?.state}`.toUpperCase(),
          };
        })
      : [];

  const options = {
    print: true,
    download: true,
    filter: true,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 50, 100],
    actionsColumnIndex: -1,
  };

  const actions = [
    // {
    //   icon: EditIcon,
    //   tooltip: "Edit Order",
    //   // onClick: (event, rowData) => history.push("/orders-detail"),
    // },
    {
      icon: DeleteIcon,
      tooltip: "Delete Order",
      onClick: (event, rowData) => deleteOrder(rowData?._id),
    },
  ];

  return (
    <>
      <Box>
        {loading ? (
          <Box>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        ) : (
          <MaterialTable
            options={options && options}
            data={driverData && driverData}
            columns={columns && columns}
            title=""
            onRowClick={(event, rowData) => console.log(rowData)}
            actions={actions && actions}
          />
        )}
      </Box>
    </>
  );
}
