import { constants } from "../constants";

const initialState = {
  userList: [],
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.USER_REQUEST:
      return {
        ...initialState,
      };

    case constants.USER_SUCCESS:
      return {
        ...state,
        userList: action.payload.data,
      };

    case constants.USER_FAIL:
      return {
        ...state,
      };

    default:
      return state;
  }
};
