export const categoryReOrder = (data) => {
  let initialData = {
    tasks: {},
    columns: {
      "column-1": {
        id: "column-1",
        title: "To do",
        taskIds: [],
      },
    },
    // Facilitate reordering of the columns
    columnOrder: ["column-1"],
  };
  let taskIds = [];
  let tasks = {};
  if (data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      taskIds.push(data[i]._id);
      let temp = { ...tasks };
      temp[data[i]._id] = {
        _id: data[i]._id,
        content: data[i],
      };
      tasks = temp;
    }
    initialData.tasks = tasks;
    initialData.columns["column-1"].taskIds = taskIds;
    return initialData;
  } else {
    return initialData;
  }
};
