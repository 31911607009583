import React from "react";
import { Col, Row } from "react-bootstrap";
import { useStyles } from "../../../utils/useStyles";
import Heading from "../../Heading/Heading";
import Sidebar from "../../Sidebar/Sidebar";
import { Link } from "react-router-dom"
function OrderDetails() {
  const classes = useStyles();
  return <>

    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading />

        {/* Data table */}
        <div className="card_box ">
          <div className="user_details">
            <div>
              <h6>Order ID:</h6>
              <p>1234556</p>
            </div>
            <div>
              <h6>Customer Name:</h6>
              <p>John Doe</p>
            </div>
            <div>
              <h6>Pickup Location:</h6>
              <p>abca city</p>
            </div>
            <div>
              <h6>Drop Location:</h6>
              <p>Wayward Wine</p>
            </div>
            <div>
              <h6>Payment:</h6>
              <p>$500 | Online</p>
            </div>
            <div>
              <h6>Drop Date:</h6>
              <p>28-03-2022</p>
            </div>
            <div>
              <h6>Drop Time:</h6>
              <p>12:00 PM</p>
            </div>
            <div>
              <h6>Order Status:</h6>
              <p>Complete</p>
            </div>
          </div>
          <hr />
          <Row>
            <Col xs={12} md={6}>
              <Row>
                <Col xs={12} md={3} className="pr-0">
                  <div className="img_container">
                    <img src='assets/images/product-img-two.png' />
                  </div>
                </Col>
                <Col xs={12} md={9} className="pl-0  ">
                  <div className='pl-2 order_details pt-2'>
                    <h5 className="pt-0">Chic Fuchsia Pink Power Crop Shoulders Top</h5>
                    <p className='mb-1'>$20.00 </p>
                    <p><span>Size-M</span><span>Qty 1</span></p>
                  </div>
                </Col>
              </Row>
              {/* <div className="d-flex card_product  order_details pl-3">
                <div className="img_container">
                  <img src='assets/images/product-img-two.png' /></div>
                <div className='pl-2 line_h'>

                  <h5>Chic Fuchsia Pink Power Crop Shoulders Top</h5>
                  <p className='mb-1'>$20.00 </p>
                  <p><span>Size-M</span><span>Qty 1</span></p>


                </div>
              </div> */}
            </Col>
            <Col xs={12} md={6}>
              <Row>
                <Col xs={12} md={3} className="pr-0">
                  <div className="img_container">
                    <img src='assets/images/product-img-two.png' />
                  </div>
                </Col>
                <Col xs={12} md={9} className="pl-0  ">
                  <div className='pl-2 order_details pt-2'>
                    <h5 className="pt-0">Chic Fuchsia Pink Power Crop Shoulders Top</h5>
                    <p className='mb-1'>$20.00 </p>
                    <p><span>Size-M</span><span>Qty 1</span></p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </main>

    </div>

  </>;
}

export default OrderDetails;
