import Sidebar from "../Sidebar/Sidebar";
import Heading from "../Heading/Heading";
import { useStyles } from "../../utils/useStyles";
import DeliveryFeesTable from "./DeliveryFeesTable";
function DeliveryFees() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading deliveryFeesButton={true} />

        {/* Data table */}
        <DeliveryFeesTable />
      </main>
    </div>
  );
}

export default DeliveryFees;
