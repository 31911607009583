import React from "react";
import ProductCard from "./ProductFilter/ProductCard";
import ProductFilter from "./ProductFilter/ProductFilter";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { showProductWithFilterAction } from "../../services/actions/product.action";
import { loaderAction } from "../../services/actions/loader.action";
import { Box, Skeleton } from "@mui/material";
import Loader from "../../Loader/Loader";
function ProductInnerPage() {
  const { productList, loader } = useSelector((state) => state.productList);

  const { filter, randomFilterData, filterLoader } = useSelector(
    (state) => state.filterCatData
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(loaderAction(true));
    dispatch(showProductWithFilterAction());
    dispatch(loaderAction(false));
  }, []);

  let appLoader = filterLoader
    ? filterLoader
    : loader
    ? loader
    : filterLoader || false;

  return (
    <div className="row product_page">
      <div className="col-4 product_container">
        <ProductFilter />
      </div>
      <div className="col-8">
        <div className="product_list ">
          {appLoader ? (
            <Box sx={{ width: "auto" }}>
              <Skeleton />

              <Skeleton variant="rectangular" height={118} />

              <Skeleton animation="wave" />
              <Skeleton variant="rectangular" height={118} />
              <Skeleton animation="wave" />

              <Skeleton animation={false} />
            </Box>
          ) : (
            <Row className="product_list_container">
              {filter && filter ? (
                randomFilterData && randomFilterData.length === 0 ? (
                  <>Product Not Found</>
                ) : (
                  randomFilterData.map((product, index) => (
                    <Col lg={4} className="mb-3" key={index}>
                      <ProductCard product={product} />
                    </Col>
                  ))
                )
              ) : (
                productList &&
                productList.map((product, index) => (
                  <Col lg={4} className="mb-3" key={index}>
                    <ProductCard product={product} />
                  </Col>
                ))
              )}
            </Row>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductInnerPage;
