import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getAttributeAction,
  getCategoriesIds,
} from "../../services/actions/attribute.action";
import { getCategoryAction } from "../../services/actions/category.action";
import { showFilterSubCategoriesAction } from "../../services/actions/product.action";
function CategoryAttributes() {
  const { categoryList } = useSelector((state) => state.categoryList);
  const { subCategoryList } = useSelector((state) => state.subCategoryList);
  const [subSubCategory, setSubSubCategory] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategoryAction());
  }, [dispatch]);

  const handleCategory = (e) => {
    let category = JSON.parse(e.target.value);

    dispatch(showFilterSubCategoriesAction(category));
  };

  const handleSubCategory = (e) => {
    let obj = JSON.parse(e.target.value);
    setSubSubCategory(obj.subSubCategory);
  };

  const handleSubSubCategory = (e) => {
    let obj = JSON.parse(e.target.value);
    dispatch(getCategoriesIds(obj));
    dispatch(getAttributeAction(obj._id));
  };

  return (
    <div>
      <Form className="form_activity form_add_product row mt-4 ">
        <Form.Group className="mb-2 col-sm-12 col-md-6">
          <Form.Label className="label_grey">Select Category</Form.Label>
          <Form.Select
            aria-label="Default select example"
            className="mb-2"
            onChange={handleCategory}
          >
            <option>Select Category</option>
            {categoryList && categoryList.length !== 0 ? (
              categoryList.map((category, index) => (
                <option value={JSON.stringify(category)} key={index}>
                  {category.categoryName}
                </option>
              ))
            ) : (
              <option disabled>No category found</option>
            )}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-2 col-sm-12 col-md-6">
          <Form.Label className="label_grey">Select Sub-Category</Form.Label>
          <Form.Select
            aria-label="Default select example"
            className="mb-2"
            onChange={handleSubCategory}
          >
            <option>Select Sub Category</option>
            {subCategoryList && subCategoryList.length !== 0 ? (
              subCategoryList.map((item, index) => (
                <option value={JSON.stringify(item)} key={index}>
                  {item.subCategory.subCategoryName}
                </option>
              ))
            ) : (
              <option disabled>No sub category found</option>
            )}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-2 col-sm-12 col-md-6">
          <Form.Label className="label_grey">Select Sub-subcategory</Form.Label>
          <Form.Select
            aria-label="Default select example"
            className="mb-2"
            onChange={handleSubSubCategory}
          >
            <option>Select Sub Sub Category</option>
            {subSubCategory && subSubCategory.length !== 0 ? (
              subSubCategory.map((item, index) => (
                <option value={JSON.stringify(item)} key={index}>
                  {item.subSubCategoryName}
                </option>
              ))
            ) : (
              <option disabled>No sub category found</option>
            )}
          </Form.Select>
        </Form.Group>
      </Form>
    </div>
  );
}

export default CategoryAttributes;
