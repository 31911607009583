import React, { useEffect, useState } from "react";

import Sidebar from "../Sidebar/Sidebar";
import Heading from "../Heading/Heading";
import { useStyles } from "../../utils/useStyles";
import { Row, Col } from "react-bootstrap";
import AddSubSubCategory from "./AddSubSubCategory";
import AddSubCategory from "./AddSubCategory";
import AddCategory from "./AddCategory";
import "./Category.css";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { categoryModalAction } from "../../services/actions/category.action";
import { useDispatch } from "react-redux";
import DragCategories from "./DragCategories";
function Category() {
  const { subCategoryToggle } = useSelector((state) => state.subCategoryToggle);

  const { subSubCategoryToggle } = useSelector(
    (state) => state.subSubCategoryToggle
  );

  const dispatch = useDispatch();

  const { message, error } = useSelector((state) => state.addCategoryMessage);
  const alert = useAlert();

  React.useEffect(() => {
    message && message && alert.success(message);
    error && error && alert.error(error);
  }, [message, error, alert]);

  const classes = useStyles();
  let type = "ADD_CATEGORY";

  useEffect(() => {
    dispatch(categoryModalAction(type));
  }, []);
  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading />

        {/* Data table */}

        <Row className="category_div">
          <Col xs={12} lg={4}>
            <AddCategory />
            {/* <DragCategories /> */}
          </Col>

          <Col xs={12} lg={4}>
            {subCategoryToggle && subCategoryToggle && <AddSubCategory />}
          </Col>

          <Col xs={12} lg={4}>
            {subSubCategoryToggle && subSubCategoryToggle && (
              <AddSubSubCategory />
            )}
          </Col>
        </Row>
      </main>
    </div>
  );
}

export default Category;
