import { constants } from "../constants";

const initialState = {
  message: null,
  error: null,
};
const allAttributeState = {
  error: null,
  allAttributes: null,
};
const categoryIdsState = {
  category: null,
  subcategory: null,
  subSubCategory: null,
};
const attributeSizeState = {
  size: null,
};
const attributeColorState = {
  color: null,
};
const attributeState = {
  singleAttributeData: null,
};
const sendAttributeState = {
  getSingleAttributeData: null,
};

const deleteAttributeState = {
  error: null,
  message: null,
};

const addAttributeReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.BACK_TO_ATTRIBUTE_PAGE:
      return {
        ...initialState,
      };
    case constants.ADD_ATTRIBUTE_REQUEST:
      return {
        ...state,
      };
    case constants.ADD_ATTRIBUTE_FAIL:
      return {
        ...initialState,
        error: action.error,
      };
    case constants.ADD_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        message: action.message,
      };
    default:
      return state;
  }
};

const getAllAttributesReducer = (state = allAttributeState, action) => {
  switch (action.type) {
    case constants.GET_ALL_ATTRIBUTES_REQUEST:
      return {
        ...allAttributeState,
      };
    case constants.GET_ALL_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        allAttributes: action.payload,
      };
    case constants.GET_ALL_ATTRIBUTES_FAIL:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

const getCategoriesIdReducer = (state = categoryIdsState, action) => {
  switch (action.type) {
    case constants.BACK_TO_ATTRIBUTE_PAGE:
      return {
        ...categoryIdsState,
      };
    case constants.GET_CATEGORY_IDS: {
      return {
        ...state,
        category: action.payload.category,
        subCategory: action.payload.subCategory,
        subSubCategory: action.payload._id,
      };
    }

    default:
      return state;
  }
};

const getAttributeSizesReducer = (state = attributeSizeState, action) => {
  switch (action.type) {
    case constants.BACK_TO_ATTRIBUTE_PAGE:
      return {
        ...attributeSizeState,
      };
    case constants.GET_ATTRIBUTE_SIZES: {
      return {
        ...state,
        size: action.payload,
      };
    }

    default:
      return state;
  }
};

const getAttributeColorReducer = (state = attributeColorState, action) => {
  switch (action.type) {
    case constants.BACK_TO_ATTRIBUTE_PAGE:
      return {
        ...attributeColorState,
      };
    case constants.GET_ATTRIBUTE_COLOR: {
      return {
        ...state,
        color: action.payload,
      };
    }

    default:
      return state;
  }
};

const getAttributesReducer = (state = attributeState, action) => {
  switch (action.type) {
    case constants.BACK_TO_ATTRIBUTE_PAGE:
      return {
        ...attributeState,
      };
    case constants.GET_ATTRIBUTES_REQUEST:
      return {
        ...attributeState,
      };
    case constants.GET_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        singleAttributeData: action.payload,
      };
    case constants.GET_ATTRIBUTES_FAIL:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

const sendAttributeDataReducer = (state = sendAttributeState, action) => {
  switch (action.type) {
    case constants.BACK_TO_ATTRIBUTE_PAGE:
      return {
        ...sendAttributeState,
      };
    case constants.SEND_ATTRIBUTE_DATA:
      return {
        ...state,
        getSingleAttributeData: action.payload,
      };

    default:
      return state;
  }
};

const deleteAttributeReducer = (state = deleteAttributeState, action) => {
  switch (action.type) {
    case constants.BACK_TO_ATTRIBUTE_PAGE:
      return {
        ...deleteAttributeState,
      };
    case constants.DELETE_ATTRIBUTE_REQUEST:
      return {
        ...state,
        getSingleAttributeData: action.payload,
      };
    case constants.DELETE_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        message: action.message,
      };
    case constants.DELETE_ATTRIBUTE_FAIL:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
};

export {
  addAttributeReducer,
  getAllAttributesReducer,
  getAttributeSizesReducer,
  getAttributeColorReducer,
  getCategoriesIdReducer,
  getAttributesReducer,
  sendAttributeDataReducer,
  deleteAttributeReducer,
};
