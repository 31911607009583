import { constants } from "../constants";

const initialState = {
  showProductImageBtn: false,
  subSubCategoryData: null,
};

const sizeColorState = {
  color: null,
  size: null,
};

const reloadState = {
  reloadStatus: false,
};

const productState = {
  productList: null,
  min: 0,
  max: 0,
  loader: false,
};

const productCategoriesState = {
  subCategoryList: null,
  message: "",
  loader: null,
};

const productFilterState = {
  filterCatData: null,
  filter: false,
  randomFilterData: null,
  filterLoader: false,
};

const catToggleState = {
  filter: false,
};

const filterState = {
  filterData: null,
};

const categoryIdsState = {
  categoryId: null,
  subCategoryId: null,
  subSubCategoryId: null,
};

const frontImageListState = {
  frontImageList: [],
};
const multiImageListState = {
  multiImageList: [],
};

const sizeState = {
  sizeData: "",
};

const colorState = {
  colorData: "",
};

const priceState = {
  priceData: "",
};

const categoryState = {
  categoryData: "",
};
const categorySubState = {
  categorySubData: "",
};
const categorySubSubState = {
  categorySubSubData: "",
};

const singleProductDetailState = {
  singleProductDetailData: null,
  isEditStatus: false,
};

const productPageReloadReducer = (state = reloadState, action) => {
  switch (action.type) {
    case constants.PRODUCT_PAGE_RELOAD_TRUE:
      return {
        ...state,
        reloadStatus: action.payload,
      };
    case constants.PRODUCT_PAGE_RELOAD_FALSE:
      return {
        ...state,
        reloadStatus: action.payload,
      };
    default:
      return state;
  }
};

const showColorAndPriceReducer = (state = sizeColorState, action) => {
  switch (action.type) {
    case constants.PRODUCT_PRICE_COLOR_REQUEST:
      return {
        ...state,
      };
    case constants.PRODUCT_PRICE_COLOR_SUCCESS:
      return {
        ...state,
        color: action.payload.color,
        size: action.payload.size,
      };
    case constants.PRODUCT_PRICE_COLOR_FAIL:
      return {
        ...sizeColorState,
      };
    default:
      return state;
  }
};

const showAddProductImageReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.PRODUCT_IMAGE_BUTTON_HIDE:
      return {
        ...initialState,
      };

    case constants.PRODUCT_IMAGE_BUTTON_SHOW:
      return {
        ...state,
        showProductImageBtn: true,
        subSubCategoryData: action.payload,
      };

    default:
      return state;
  }
};

const showProductWithFilterReducer = (state = productState, action) => {
  switch (action.type) {
    case constants.PRODUCT_WITH_FILTER_FAIL:
      return {
        ...productState,
        loader: action.loader,
      };

    case constants.PRODUCT_WITH_FILTER_REQUEST:
      return {
        ...state,
        loader: action.loader,
      };

    case constants.PRODUCT_WITH_FILTER_SUCCESS:
      return {
        ...state,
        productList: action.payload.data,
        min: action.payload.min,
        max: action.payload.max,
        loader: action.loader,
      };

    default:
      return state;
  }
};

const showFilterSubCategoriesReducer = (
  state = productCategoriesState,
  action
) => {
  switch (action.type) {
    case constants.PRODUCT_FILTER_CATEGORY_REQUEST:
      return {
        ...productCategoriesState,
        loader: action.loader,
      };
    case constants.PRODUCT_FILTER_CATEGORY_FAIL:
      return {
        ...state,
        message: action.message,
        loader: action.loader,
      };

    case constants.PRODUCT_FILTER_CATEGORY_SUCCESS:
      return {
        ...state,
        subCategoryList: action.payload,
        loader: action.loader,
      };

    default:
      return state;
  }
};

const filterProductWithCategoryReducer = (
  state = productFilterState,
  action
) => {
  switch (action.type) {
    case constants.PRODUCT_FILTER_REQUEST:
      return {
        ...state,
        filterLoader: action.payload.loader,
      };

    case constants.PRODUCT_FILTER_SUCCESS:
      return {
        ...state,
        filterLoader: action.payload.loader,
      };

    case constants.PRODUCT_FILTER_WITH_CATEGORY_START:
      return {
        ...state,
        filter: action.filter,
        filterCatData: action.payload.filterCatData,
        randomFilterData: action.payload.randomData,
        filterLoader: action.payload.loader,
      };
    case constants.PRODUCT_FILTER_WITH_CATEGORY_END:
      return {
        ...state,
        filter: action.filter,
        filterLoader: action.payload.loader,
      };

    default:
      return state;
  }
};

const categoryToggleReducer = (state = catToggleState, action) => {
  switch (action.type) {
    case constants.CATEGORY_TOGGLE_TRUE:
      return {
        ...state,
        filter: action.filter,
      };

    case constants.CATEGORY_TOGGLE_FALSE:
      return {
        ...catToggleState,
      };

    default:
      return state;
  }
};

const filterProductWithAllFiltersReducer = (state = filterState, action) => {
  switch (action.type) {
    case constants.PRODUCT_FILTER_INPUT_VALUE_SAVE:
      return {
        ...state,
        filterData: action.payload,
      };
    case constants.PRODUCT_FILTER_INPUT_VALUE_RESET:
      return {
        ...filterState,
      };

    default:
      return state;
  }
};

const addProductCategoriesIdReducer = (state = categoryIdsState, action) => {
  switch (action.type) {
    case constants.ADD_PRODUCT_IDS:
      return {
        ...state,
        categoryId: action.payload.category,
        subCategoryId: action.payload.subCategory,
        subSubCategoryId: action.payload._id,
      };
    case constants.RESET_PRODUCT_IDS:
      return {
        ...categoryIdsState,
      };
    default:
      return state;
  }
};

const addFrontImageProductReducer = (state = frontImageListState, action) => {
  switch (action.type) {
    case constants.FRONT_IMAGE_LIST:
      return {
        ...state,
        frontImageList: action.payload,
      };
    case constants.RESET_FRONT_IMAGE_LIST:
      return {
        ...frontImageListState,
      };
    default:
      return state;
  }
};

const addMultiImageProductReducer = (state = multiImageListState, action) => {
  switch (action.type) {
    case constants.MULTIPLE_IMAGE_LIST:
      return {
        ...state,
        multiImageList: action.payload,
      };
    case constants.RESET_MULTIPLE_IMAGE_LIST:
      return {
        ...multiImageListState,
      };
    default:
      return state;
  }
};

const saveSizeFilterDataInStoreReducer = (state = sizeState, action) => {
  switch (action.type) {
    case constants.SAVE_SIZE:
      return {
        ...state,
        sizeData: action.payload,
      };
    case constants.RESET_SIZE:
      return {
        ...sizeState,
      };
    default:
      return state;
  }
};

const savePriceFilterDataInStoreReducer = (state = priceState, action) => {
  switch (action.type) {
    case constants.SAVE_PRICE:
      return {
        ...state,
        priceData: action.payload,
      };
    case constants.RESET_PRICE:
      return {
        ...priceState,
      };
    default:
      return state;
  }
};

const saveColorFilterDataInStoreReducer = (state = colorState, action) => {
  switch (action.type) {
    case constants.SAVE_COLOR:
      return {
        ...state,
        colorData: action.payload,
      };
    case constants.RESET_COLOR:
      return {
        ...colorState,
      };
    default:
      return state;
  }
};

const saveCategoryIdsInStoreReducer = (state = categoryState, action) => {
  switch (action.type) {
    case constants.SAVE_CATEGORY:
      return {
        ...state,
        categoryData: action.payload,
      };
    case constants.RESET_CATEGORY:
      return {
        ...categoryState,
      };
    default:
      return state;
  }
};

const saveSubCategoryIdsInStoreReducer = (state = categorySubState, action) => {
  switch (action.type) {
    case constants.SAVE_SUB_CATEGORY:
      return {
        ...state,
        categorySubData: action.payload,
      };
    case constants.RESET_SUB_CATEGORY:
      return {
        ...categorySubState,
      };
    case constants.RESET_CATEGORY:
      return {
        ...categorySubState,
      };
    default:
      return state;
  }
};

const saveSubSubCategoryIdsInStoreReducer = (
  state = categorySubSubState,
  action
) => {
  switch (action.type) {
    case constants.SAVE_SUB_SUB_CATEGORY:
      return {
        ...state,
        categorySubSubData: action.payload,
      };
    case constants.RESET_SUB_SUB_CATEGORY:
      return {
        ...categorySubSubState,
      };
    case constants.RESET_CATEGORY:
      return {
        ...categorySubSubState,
      };
    default:
      return state;
  }
};

const saveSingleProductDetailsReducer = (
  state = singleProductDetailState,
  action
) => {
  switch (action.type) {
    case constants.SINGLE_PRODUCT_DETAILS_SAVE:
      return {
        ...state,
        singleProductDetailData: action.payload.data,
        isEditStatus: action.payload.isEditStatus,
      };
    case constants.SINGLE_PRODUCT_DETAILS_RESET:
      return {
        ...singleProductDetailState,
      };
    default:
      return state;
  }
};

export {
  saveSubCategoryIdsInStoreReducer,
  saveSubSubCategoryIdsInStoreReducer,
  saveSizeFilterDataInStoreReducer,
  savePriceFilterDataInStoreReducer,
  saveColorFilterDataInStoreReducer,
  filterProductWithAllFiltersReducer,
  showAddProductImageReducer,
  showColorAndPriceReducer,
  showProductWithFilterReducer,
  productPageReloadReducer,
  showFilterSubCategoriesReducer,
  filterProductWithCategoryReducer,
  categoryToggleReducer,
  addProductCategoriesIdReducer,
  addFrontImageProductReducer,
  addMultiImageProductReducer,
  saveCategoryIdsInStoreReducer,
  saveSingleProductDetailsReducer,
};
