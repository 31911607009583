import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// import { EditIcon } from "../../../utils/Icons";
import { useHistory } from "react-router-dom";

import DataTable from "../DataTable/DataTable";
import MaterialTable from "material-table";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//   },

//   toolbar: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "flex-end",
//     padding: theme.spacing(0, 1),
//     // necessary for content to be below app bar
//     ...theme.mixins.toolbar,
//   },
//   content: {
//     flexGrow: 1,
//     padding: theme.spacing(3),
//   },
// }));

export default function RecentSaleProduct() {
  // const classes = useStyles();
  const columns = [
    { title: "#", field: "id" },
    { title: "Product Name", field: "productName" },
    { title: "Price", field: "price" },
    { title: "Size", field: "size" },
    { title: "Quantity", field: "quantity" },
    { title: "Color", field: "color" },
  ];

  const tableData = [
    {
      id: 1,
      productName: "Shirts",
      price: "$10",
      size: "M",
      quantity: 10,

      color: "Red",
    },
  ];

  const options = {
    print: true,
    download: true,
    filter: true,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 50, 100],
    actionsColumnIndex: -1,
    search: false,
  };

  return (
    <>
      <div className={"table_dashboard"}>
        <MaterialTable
          options={options}
          data={tableData && tableData}
          columns={columns && columns}
          title={"Recent sale products"}
          onRowClick={(event, rowData) => console.log(rowData)}
        />
      </div>
    </>
  );
}
