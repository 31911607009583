import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { getAttributeAction } from "../../services/actions/attribute.action";
import {
  addProductCategoriesIdAction,
  showAddProductImageAction,
} from "../../services/actions/product.action";
import {
  categoryModalAction,
  editSubSubCategoryModalAction,
  getSubSubCategoryAction,
  toggleSubSubCategoryAction,
} from "../../services/actions/category.action";
import { deleteCustomAPI } from "../../middleware/apiMiddleware";
import { Api } from "../../utils/API/Api";
import { useAlert } from "react-alert";
import { loaderAction } from "../../services/actions/loader.action";

import CategoryModal from "./CategoryModal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DragCategories from "./DragCategories";

function AddSubSubCategory() {
  let type = "EDIT_SUB_SUB_CATEGORY";
  const alert = useAlert();
  const dispatch = useDispatch();
  const { categorySubSubList, reOrderCategorySubSubList } = useSelector(
    (state) => state.categorySubSubList
  );
  const { categorySubData } = useSelector(
    (state) => state.subSubCategoryToggle
  );

  const [modalShow, setModalShow] = React.useState(false);

  let location = useLocation();

  const handleProductImage = (data) => {
    location.pathname === "/add_products"
      ? dispatch(showAddProductImageAction(true, data))
      : dispatch(showAddProductImageAction(false));
  };

  const [action, setAction] = useState(true);
  const [activeId, setActiveId] = useState(null);

  useEffect(() => {
    if (location.pathname === "/add_products") {
      setAction(false);
    } else {
      setAction(true);
    }
  }, []);

  const handleDelete = async (id) => {
    dispatch(loaderAction(true));

    let body = {
      _id: id,
    };
    const { data, error } = await deleteCustomAPI(
      Api.deleteSubSubCategory,
      body
    );
    if (!error) {
      if (data.status === 1) {
        dispatch(getSubSubCategoryAction(categorySubData));
        alert.success(data.message);
      } else {
        alert.error(data.message);
      }
    } else {
      alert.error(error.message);
    }
    dispatch(loaderAction(false));
  };
  const handleOpen = async () => {
    dispatch(loaderAction(true));

    let open = true;
    let type = "ADD_SUB_SUB_CATEGORY";
    let id = null;

    let data = null;
    dispatch(categoryModalAction(type, id, data, open));
    setModalShow(true);
    dispatch(loaderAction(false));
  };

  return (
    <div>
      <div className="card_box">
        <div className="d-flex justify-content-between ">
          <div>
            <h6>
              {location.pathname !== "/add_products" && "Add "} Sub-subcategory
            </h6>
          </div>
          <div>
            {location.pathname !== "/add_products" && (
              <AddCircleIcon
                onClick={() => {
                  handleOpen();
                }}
                className="btn_add_Category"
              />
            )}
            <CategoryModal modalShow={modalShow} setModalShow={setModalShow} />
          </div>
        </div>
        {action ? (
          <DragCategories
            reOrderCategoryList={reOrderCategorySubSubList}
            type={"SubSubCategory"}
          />
        ) : (
          <div className="category_list">
            {categorySubSubList &&
              categorySubSubList.map((item, index) => (
                <div
                  key={index}
                  className={item?._id === activeId ? "menu_btn_active" : null}
                >
                  <div className="d-flex justify-content-start category_box_container">
                    <div>
                      <img src={item.image} className="category_icon" alt="" />
                    </div>
                    <div
                      className="category_name_text"
                      onClick={() => {
                        handleProductImage(item);
                        dispatch(getAttributeAction(item._id));
                        dispatch(addProductCategoriesIdAction(item));
                        setActiveId(item._id);
                      }}
                    >
                      <h5 className="category_name">
                        {item.subSubCategoryName}
                      </h5>
                    </div>
                  </div>

                  <hr className="m-0" />
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default AddSubSubCategory;
