import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// import { EditIcon } from "../../../utils/Icons";
import { useHistory } from "react-router-dom";

import MaterialTable from "material-table";
import EyeIcon from "./eye.svg";
import TrashIcon from "./trash.svg";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const EditIcon = () => {
  return <img src={EyeIcon} alt="edit" className="table_action_icons" />;
};

const DeleteIcon = () => {
  return <img src={TrashIcon} alt="edit" className="table_action_icons" />;
};

export default function TransactionTable() {
  const options = {
    print: true,
    download: true,
    filter: true,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 50, 100],
    actionsColumnIndex: -1,
  };

  const columns = [
    { title: "#", field: "id" },
    { title: "Order Id", field: "orderId" },
    { title: "User Name", field: "userName" },

    { title: "Date", field: "createdOn" },
    {
      title: "Total",
      field: "total",
      render: (item) => <p> $ {item.total}</p>,
    },
    { title: "Mobile money no", field: "mobileMoneyNo" },
    {
      title: "Status",
      field: "status",
      render: (item) => <p> {item.status}</p>,
    },
    { title: "Method", field: "method" },
  ];

  const tableData = [
    {
      id: 1,
      orderId: "GHH301",
      userName: "Jon Doe",
      createdOn: "23/04/2022",
      total: "400",
      mobileMoneyNo: "1234554321",
      status: "Complete",
      method: "MasterCard",
    },
  ];
  const actions = [
    {
      icon: EditIcon,
      tooltip: "Edit Order",
      // onClick: (event, rowData) => history.push("/orders-detail"),
    },
    {
      icon: DeleteIcon,
      tooltip: "Delete Order",
      // onClick: (event, rowData) => history.push("/orders-detail"),
    },
  ];

  return (
    <MaterialTable
      options={options}
      data={tableData && tableData}
      columns={columns && columns}
      title={""}
      onRowClick={(event, rowData) => console.log(rowData)}
      actions={actions && actions}
    />
  );
}
