import React, { useEffect } from "react";

import Sidebar from "../../Sidebar/Sidebar";
import Heading from "../../Heading/Heading";
import { useStyles } from "../../../utils/useStyles";
import { Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { PlusIcon } from "../../../utils/MenusList/Icons";
import { useSelector } from "react-redux";
import AddCategory from "../../Category/AddCategory";
import AddSubCategory from "../../Category/AddSubCategory";
import AddSubSubCategory from "../../Category/AddSubSubCategory";
import { addFrontImageProductAction } from "../../../services/actions/product.action";
import { useDispatch } from "react-redux";
import { Api } from "../../../utils/API/Api";
import axios from "axios";
import { useAlert } from "react-alert";
function AddProducts() {
  const classes = useStyles();
  const { subCategoryToggle } = useSelector((state) => state.subCategoryToggle);
  const alert = useAlert();
  const { showProductImageBtn } = useSelector(
    (state) => state.showProductImageBtn
  );

  const { subSubCategoryToggle } = useSelector(
    (state) => state.subSubCategoryToggle
  );

  const dispatch = useDispatch();

  const history = useHistory();

  const handleImage = async (e) => {
    e.preventDefault();
    const file = e.target.files;

    const fileData = file === null ? null : file[0];

    var myForm = new FormData();
    myForm.append("image", fileData);
    e.target.value = null;
    const { data } = await axios.post(Api.uploadSingleImage, myForm);
    if (data.status === 1) {
      dispatch(addFrontImageProductAction(data.image));
      alert.success(data.message);
      history.push("/add_product_details");
    } else {
      alert.error(data.message);
    }
  };

  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading />

        {/* Data table */}

        <Row className="category_div">
          <Col xs={12} lg={8}>
            <Row>
              <Col xs={12} lg={4}>
                <AddCategory />
              </Col>

              <Col xs={12} lg={4}>
                {subCategoryToggle && subCategoryToggle && <AddSubCategory />}
              </Col>

              <Col xs={12} lg={4}>
                {subSubCategoryToggle && subSubCategoryToggle && (
                  <AddSubSubCategory />
                )}
              </Col>
            </Row>
          </Col>
          {showProductImageBtn && (
            <Col xs={12} lg={4}>
              {/* <Link to="/add_product_details">Add Product Detail</Link> */}
              <div className="add_product_aside_content">
                <h5>Men Fashion/ Western Wear/ T-shirt</h5>
                <div>
                  <h6>Please provide only front image for each product</h6>
                  <input
                    type="file"
                    accept="image/*"
                    id="contained-button-file"
                    style={{ display: "none" }}
                    onChange={handleImage}
                  />
                  <div className="file">
                    <label
                      htmlFor="contained-button-file"
                      className="file_upload"
                    >
                      <PlusIcon className="upload_image" />
                    </label>
                  </div>
                  <Button
                    htmlFor="contained-button-file"
                    onClick={() => dispatch(addFrontImageProductAction("test"))}
                  >
                    <img src="assets/images/upload-product-img.svg" alt="" />
                    Add Product Image
                  </Button>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </main>
    </div>
  );
}

export default AddProducts;
