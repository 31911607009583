export const ageRange = [
  {
    min: 10,
    max: 20,
  },
  {
    min: 20,
    max: 30,
  },
  {
    min: 30,
    max: 40,
  },
  {
    min: 40,
    max: 50,
  },
  {
    min: 50,
    max: 60,
  },
  {
    max: "60+",
  },
];
