import React, { useState, useEffect } from "react";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { AddProductTabs, TabPanel } from "./AddProductTabs";
import { Col, Row } from "react-bootstrap";
import { PlusIcon } from "../../../utils/MenusList/Icons";
import { Api } from "../../../utils/API/Api";
import axios from "axios";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import {
  addMultipleProductImageAction,
  resetProductDetails,
} from "../../../services/actions/product.action";
import { useHistory, useLocation } from "react-router-dom";
import { loaderAction } from "../../../services/actions/loader.action";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { EditProductModal } from "./EditImageModal";
import LinearProgressWithLabel from "../../../Loader/LinearProgessWithLabel";
function AddProductForm() {
  const { singleAttributeData } = useSelector(
    (state) => state.singleAttributeData
  );

  const { multiImageList } = useSelector((state) => state.multiImageList);
  const { frontImageList } = useSelector((state) => state.frontImageList);
  const [modalShow, setModalShow] = React.useState(false);
  const { singleProductDetailData, isEditStatus } = useSelector(
    (state) => state.singleProductDetailData
  );

  const { categoryId, subCategoryId, subSubCategoryId } = useSelector(
    (state) => state.categoriesId
  );

  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  let location = useLocation();
  const [imagesPreview, setImagesPreview] = useState([]);
  const [images, setImages] = useState([]);
  const [upload, setUpload] = useState(null);
  const [tab, setTab] = React.useState(0);

  const [arr, setArr] = React.useState([]);
  const [sizeInventoryAction, setSizeInventoryAction] = useState(false);
  const [sizeAction, setSizeAction] = React.useState(false);

  const [lengthNumber, setLengthNumber] = useState([0]);
  const [chestNumber, setChestNumber] = useState([0]);
  const [commissionLength, setCommissionLength] = useState([0]);

  const [price, setPrice] = useState("");
  const [mrp, setMrp] = useState("");
  const [gst, setGst] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [weight, setWeight] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [color, setColor] = useState("");
  const [fabric, setFabric] = useState("");
  const [countryOrigin, setCountryOrigin] = useState("");
  const [manufactureDetails, setManufactureDetails] = useState("");
  const [commissionWithPercentage, setCommissionWithPercentage] = useState("");
  const [commission, setCommission] = useState("");
  const [careInstructions, setCareInstructions] = useState("");
  const [fitType, setFitType] = useState("");
  const [type, setType] = useState("");
  const [commissionAction, setCommissionAction] = useState(false);

  const [addProductDetailsArray, setAddProductDetailsArray] = useState([]);

  const [packerDetails, setPackerDetails] = useState("");

  const [otherAttributes, setOtherAttributes] = useState(() =>
    singleAttributeData?.parameter.map((item, index) => ({
      attributeName: item.parameterName,
      attributeValue:
        singleProductDetailData?.attributes[index]?.attributeName ===
          item.parameterName
          ? singleProductDetailData?.attributes[index]?.attributeValue
          : "",
    }))
  );

  const [sizeServiceList, setSizeServiceList] = useState([
    {
      size: "",
      inventory: "",
      skuId: "",
      chestSize: "",
      lengthSize: "",
    },
  ]);
  const regex = /[%]/g;

  const handleSizeCheckbox = (item) => {
    if (arr.length > 0) {
      if (arr.includes(item)) {
        let temp = arr.filter((s) => s !== item);

        let inventoryList =
          sizeServiceList.length > 0 &&
          sizeServiceList.filter((inventory) => inventory.size !== item);
        setSizeServiceList(inventoryList);

        setArr(temp);
      } else {
        setArr((pre) => [...pre, item]);
        setSizeServiceList((pre) => [
          ...pre,
          {
            size: item,
            inventory: "",
            skuId: "",
            chestSize: "",
            lengthSize: "",
          },
        ]);
      }
    } else {
      setArr((pre) => [...pre, item]);
      setSizeServiceList([
        { size: item, inventory: "", skuId: "", chestSize: "", lengthSize: "" },
      ]);
    }
  };

  const handleRemoveSize = (item, index) => {
    handleSizeCheckbox(item.size, index);
    const list = [...sizeServiceList];
    list.splice(index, 1);
    setSizeServiceList(list);
  };

  useEffect(() => {
    if (arr.length === 0) {
      setSizeInventoryAction(false);
    }
  }, [arr]);

  const handleNumber = () => {
    for (let index = 1; index < 200; index++) {
      setLengthNumber((pre) => [...pre, index / 2]);
      setChestNumber((pre) => [...pre, index]);
    }
  };

  const handleCommission = () => {
    for (let index = 2; index < 99; index++) {
      setCommissionLength((pre) => [...pre, index / 2]);
    }
  };

  useEffect(() => {
    handleCommission();
  }, []);

  const handleSizeChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...sizeServiceList];
    list[index][name] = value;

    setSizeServiceList(list);
  };

  const handleAttributeChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...otherAttributes];
    list[index][name] = value;

    // setSizeServiceList(list);
    setOtherAttributes(list);
  };

  const addHandleProductDetails = () => {
    let arr = {
      title: "",
      description: "",
    };
    setAddProductDetailsArray((pre) => [...pre, arr]);
  };

  const removeHandleProductDetails = (item, index) => {
    const list = [...addProductDetailsArray];
    list.splice(index, 1);
    setAddProductDetailsArray(list);
  };

  const handleAddProductDetailsChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...addProductDetailsArray];
    list[index][name] = value;

    setAddProductDetailsArray(list);
  };

  const handleSubmitForm = async (e) => {
    let Boolean = true;

    if (!price || !productName) {
      Boolean = false;
    }
    if (Boolean) {
      let body = {
        productName: productName,
        subSubCategoryName:
          (subSubCategoryId && subSubCategoryId) ||
          singleProductDetailData?.subSubCategoryName, // subSubCategoryName is sub sub category ID
        category:
          (categoryId && categoryId) || singleProductDetailData?.category,
        subCategory:
          (subCategoryId && subCategoryId) ||
          singleProductDetailData?.subCategory,
        price: price,
        mrp: mrp,
        gst: gst,
        stateCode: stateCode,
        hsnCode: hsnCode,
        description: productDescription,
        size: arr,
        productWeight: weight,
        fabric: fabric,
        countryOfOrigin: countryOrigin,
        manufacturingDetails: manufactureDetails,
        packerDetails: packerDetails,
        colour: color,
        inventory: sizeServiceList,
        attributes: otherAttributes,
        multipleImages: multiImageList && multiImageList,
        images: frontImageList && frontImageList,
        commission: commissionAction
          ? commissionWithPercentage + "%"
          : commission,
        // careInstructions: careInstructions,
        // fitType: fitType,
        // type: type,
        productDetails: addProductDetailsArray,
      };

      try {
        if (isEditStatus) {
          dispatch(loaderAction(true));

          body["_id"] = singleProductDetailData?._id;

          const { data } = await axios.put(Api.editProducts, body);
          if (data.status === 1) {
            alert.success(data.message);
            dispatch(loaderAction(true));
            history.push("/products");
          } else {
            alert.error(data.message);
            dispatch(loaderAction(false));
          }
          dispatch(loaderAction(false));
        } else {
          dispatch(loaderAction(true));
          const { data } = await axios.post(Api.addProducts, body);
          if (data.status === 1) {
            alert.success(data.message);
            history.push("/products");
            dispatch(loaderAction(false));
          } else {
            alert.error(data.message);
            dispatch(loaderAction(false));
          }
        }
      } catch (error) {
        alert.error(error.message);
      }
    } else {
      alert.error("Please fill all fields");
    }
  };

  const [imageArr, setImageArr] = useState([]);
  const [progressLoading, setProgressLoading] = useState(false);

  const handleImage = async (e) => {
    e.preventDefault();
    // console.log(e.target.files);
    // setImageArr(e.target.files);
    // return false;
    let file = e.target.files;
    if (file.length > 6) {
      alert.info("Only 6 files are allowed at the same time");
      return false;
    }
    setProgressLoading(true);

    const fileData = file === null ? null : file;

    if (fileData.length > 0) {
      let myForm = new FormData();

      for (var i = 0; i < fileData.length; i++) {
        myForm.append("images", fileData[i]);
      }

      const { data } = await axios.post(Api.uploadMultipleImages, myForm);

      if (data.status === 1) {
        let multiImage = [...multiImageList];
        // console.log(multiImage, "multiImage");
        const children = multiImage.concat(data.image);

        dispatch(addMultipleProductImageAction(children));
        setProgressLoading(false);
        e.target.value = null;

        alert.success("Image uploaded");
      } else {
        setProgressLoading(false);

        alert.error(data.message);
      }
    } else {
      setProgressLoading(false);

      alert.error("Please select images");
    }
  };

  const handleDeleteImage = (link) => {
    let tempArr =
      multiImageList &&
      multiImageList.length > 0 &&
      multiImageList &&
      multiImageList.filter((item) => item !== link);
    dispatch(addMultipleProductImageAction(tempArr));
  };

  useEffect(() => {
    if (isEditStatus) {
      setPrice(singleProductDetailData?.price);
      setMrp(singleProductDetailData?.mrp);
      setGst(singleProductDetailData?.gst);
      setHsnCode(singleProductDetailData?.hsnCode);
      setGst(singleProductDetailData?.gst);

      setWeight(singleProductDetailData?.productWeight);

      setStateCode(singleProductDetailData?.stateCode);

      setProductName(singleProductDetailData?.productName);
      setProductDescription(singleProductDetailData?.description);
      // setCareInstructions(singleProductDetailData?.careInstructions);
      // setFitType(singleProductDetailData?.fitType);
      // setType(singleProductDetailData?.type);
      setAddProductDetailsArray(singleProductDetailData?.productDetails);
      // handleSizeChange(singleProductDetailData?.stateCode);
      setColor(singleProductDetailData?.colour);
      setFabric(singleProductDetailData?.fabric);
      setCountryOrigin(singleProductDetailData?.countryOfOrigin);

      setManufactureDetails(singleProductDetailData?.manufacturingDetails);
      setPackerDetails(singleProductDetailData?.packerDetails);
      setArr(singleProductDetailData?.size);

      let tempArray = [];
      singleProductDetailData?.size.length > 0 &&
        singleProductDetailData?.size.forEach((item, index) => {
          tempArray.push({
            size: singleProductDetailData?.inventory[index]?.size,
            inventory: singleProductDetailData?.inventory[index]?.inventory,
            skuId: singleProductDetailData?.inventory[index]?.skuId,
            chestSize: singleProductDetailData?.inventory[index]?.chestSize,
            lengthSize: singleProductDetailData?.inventory[index]?.lengthSize,
          });
        });

      setSizeServiceList(tempArray);
      let commissionData = `${singleProductDetailData?.commissionSetValue}`;
      setCommission(commissionData.match(regex) ? "" : commissionData);
      setCommissionWithPercentage(
        commissionData.match(regex) ? commissionData.slice(0, -1) : ""
      );

      setCommissionAction(commissionData.match(regex) ? true : false);
    }
  }, [singleProductDetailData, isEditStatus]);

  useEffect(() => {
    if (
      isEditStatus === false &&
      location.pathname.substring(1) === "edit_product_details"
    ) {
      history.push("/products");
    }
  }, [history, isEditStatus, location]);

  return (
    <>
      <Row className="product_add_section">
        {/* import LinearProgress from '@mui/material/LinearProgress'; */}
        <Col xs={12} lg={8}>
          <div className="add_product_details">
            <div className="card_box">
              <div className="btn_add_product_form">
                {/* <AddProductTabs tab={tab} setTab={setTab} /> */}
                {/* <Button>
                  <PlusIcon className="upload_image" />
                  <span>Add</span>
                </Button> */}

                <EditProductModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />

                <Button className="front_image_btn">
                  <img src={frontImageList && frontImageList} alt="" />
                  <span>Product</span>
                  <span className="edit_icon">
                    <img
                      src="/assets/images/edit.png"
                      alt="Edit"
                      onClick={() => setModalShow(true)}
                    />
                  </span>
                </Button>
              </div>

              <TabPanel value={tab} index={0}>
                <Form className="form_activity form_add_product row mt-4">
                  <Form.Group>
                    <Form.Label className="one">
                      Prize , Size and Inventory:
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-2 col-sm-12 col-md-6">
                    <Form.Label className="label_grey">
                      ChapShop Price
                    </Form.Label>
                    <InputGroup className="mb-2">
                      <FormControl
                        placeholder="Enter the ChapShop Price"
                        aria-label="price"
                        aria-describedby="basic-addon1"
                        value={price}
                        type="number"
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-2 col-sm-12 col-md-6">
                    <Form.Label className="label_grey">MRP</Form.Label>
                    <InputGroup className="mb-2">
                      <FormControl
                        placeholder="Enter the MRP"
                        aria-label="price"
                        aria-describedby="basic-addon1"
                        value={mrp}
                        type="number"
                        onChange={(e) => setMrp(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-2 col-sm-12 col-md-6">
                    <Form.Label className="label_grey">Product Name</Form.Label>
                    <InputGroup className="mb-2">
                      <FormControl
                        placeholder="Enter the Product Name"
                        aria-label="price"
                        aria-describedby="basic-addon1"
                        value={productName}
                        onChange={(e) => setProductName(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-2 col-sm-12 col-md-6">
                    <Form.Label className="label_grey">
                      Product Description
                    </Form.Label>
                    <InputGroup className="mb-2">
                      <FormControl
                        placeholder="Enter the Product Description"
                        aria-label="price"
                        aria-describedby="basic-addon1"
                        value={productDescription}
                        onChange={(e) => setProductDescription(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>

                  {/* Instruction */}
                  <Form.Group className="mb-2 col-sm-12 col-md-6">
                    <Form.Label className="label_grey">
                      Add Product Details{" "}
                      <AddCircleOutlineIcon
                        className="add_product_details_icon"
                        onClick={() => addHandleProductDetails()}
                      />
                    </Form.Label>
                  </Form.Group>

                  {/* {arr && arr.length > 0 && sizeInventoryAction && ( */}
                  <div className="product_form_table">
                    { }
                    <Table>
                      {addProductDetailsArray.length > 0 &&
                        addProductDetailsArray[0] && (
                          <thead>
                            <tr>
                              <th>Product Detail Title</th>
                              <th>Product Detail Description</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                        )}
                      <tbody>
                        {addProductDetailsArray.length > 0 &&
                          addProductDetailsArray.map((product, index) => (
                            <tr key={index}>
                              <td>
                                <InputGroup>
                                  <FormControl
                                    name="title"
                                    aria-label="price"
                                    aria-describedby="basic-addon1"
                                    type="text"
                                    value={product.title}
                                    onChange={(e) =>
                                      handleAddProductDetailsChange(e, index)
                                    }
                                  />
                                </InputGroup>
                              </td>
                              <td>
                                <InputGroup className="pt-2">
                                  <FormControl
                                    name="description"
                                    as="textarea"
                                    rows={1}
                                    value={product.description}
                                    onChange={(e) =>
                                      handleAddProductDetailsChange(e, index)
                                    }
                                  />
                                </InputGroup>
                              </td>
                              <td>
                                <Button
                                  onClick={() => {
                                    removeHandleProductDetails(product, index);
                                  }}
                                >
                                  <img
                                    src="assets/images/trash.svg"
                                    alt=""
                                    className="trash_icon"
                                  />
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>

                  <Form.Group className="mb-2 col-sm-12 col-md-6">
                    <Form.Label className="label_grey">GST</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      className="mb-2"
                      value={gst}
                      onChange={(e) => setGst(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="12">12%</option>
                      <option value="15">15%</option>
                      <option value="18">18%</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-2 col-sm-12 col-md-6">
                    <Form.Label className="label_grey">HSN Code</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      className="mb-2"
                      value={hsnCode}
                      onChange={(e) => setHsnCode(e.target.value)}
                    >
                      <option value=""></option>

                      <option value="210111">210111</option>
                      <option value="210210">210210</option>
                      <option value="210390">210390</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-2 col-sm-12 col-md-6">
                    <Form.Label className="label_grey">
                      Commission(%){" "}
                      <Tooltip
                        title="Please enter the below of 3 character"
                        placement="top"
                      >
                        <InfoIcon className="info_icon" />
                      </Tooltip>
                    </Form.Label>
                    <InputGroup className="mb-2">
                      <FormControl
                        onClick={() => setCommissionAction(true)}
                        placeholder="Enter the product commission in percentage"
                        aria-label="price"
                        aria-describedby="basic-addon1"
                        type="text"
                        value={
                          commissionAction === true
                            ? commissionWithPercentage
                            : ""
                        }
                        onChange={(e) =>
                          setCommissionWithPercentage(
                            e.target.value.replace(/\D/g, "").length == 3
                              ? ""
                              : e.target.value.replace(/\D/g, "")
                          )
                        }
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-2 col-sm-12 col-md-6">
                    <Form.Label className="label_grey">
                      Commission(Amt)
                    </Form.Label>
                    <InputGroup className="mb-2">
                      <FormControl
                        onClick={() => setCommissionAction(false)}
                        placeholder="Enter the product commission in Amt."
                        aria-label="price"
                        aria-describedby="basic-addon1"
                        type="text"
                        value={commissionAction === false ? commission : ""}
                        onChange={(e) =>
                          setCommission(e.target.value.replace(/\D/g, ""))
                        }
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-2 col-sm-12 col-md-6">
                    <Form.Label className="label_grey">
                      Product Weight(gms)
                    </Form.Label>
                    <InputGroup className="mb-2">
                      <FormControl
                        placeholder="Enter the product weight"
                        aria-label="price"
                        aria-describedby="basic-addon1"
                        value={weight}
                        type="number"
                        onChange={(e) => setWeight(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-2 col-sm-12 col-md-6">
                    <Form.Label className="label_grey">State Code</Form.Label>
                    <InputGroup className="mb-2">
                      <FormControl
                        placeholder="Enter the state code"
                        aria-label="price"
                        aria-describedby="basic-addon1"
                        value={stateCode}
                        type="number"
                        onChange={(e) => setStateCode(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-2 col-sm-12 col-md-6">
                    <Form.Label className="label_grey">Size</Form.Label>
                    <InputGroup className="mb-2">
                      <FormControl
                        placeholder={
                          arr && arr.length > 0
                            ? arr.map((item, index) => " " + item)
                            : ""
                        }
                        aria-label="price"
                        aria-describedby="basic-addon1"
                        disabled
                      />

                      <div
                        className="arrow_select_btn"
                        onClick={() => setSizeAction(!sizeAction)}
                      >
                        <KeyboardArrowDownIcon />
                      </div>
                    </InputGroup>
                    {sizeAction && (
                      <div className="form_checkbox">
                        <div className="form_checkbox_inner">
                          {singleAttributeData &&
                            singleAttributeData.size.length > 0
                            ? singleAttributeData.size.map((item, index) => (
                              <Form.Check
                                type="checkbox"
                                label={item}
                                key={index}
                                checked={
                                  arr.length > 0 && arr.includes(item)
                                    ? true
                                    : false
                                }
                                onChange={() =>
                                  handleSizeCheckbox(item, index)
                                }
                              />
                            ))
                            : "No size found"}
                        </div>
                        {arr && arr.length > 0 && (
                          <div className="check_btn1">
                            <Button
                              className="done_check_box_btn"
                              onClick={() => {
                                setSizeInventoryAction(true);
                                setSizeAction(false);
                                handleNumber();
                              }}
                            >
                              Done
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                  </Form.Group>
                  {arr && arr.length > 0 && sizeInventoryAction && (
                    <div className="product_form_table">
                      <Table>
                        <thead>
                          <tr>
                            <th>Size</th>
                            <th>Inventory</th>
                            <th>SKU ID(Optional)</th>
                            <th>Chest Size</th>
                            <th>Length Size</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sizeServiceList &&
                            sizeServiceList.length > 0 &&
                            sizeServiceList.map((item, index) => (
                              <tr key={index}>
                                <td>{item.size}</td>
                                <td>
                                  <InputGroup>
                                    <FormControl
                                      name="inventory"
                                      aria-label="price"
                                      aria-describedby="basic-addon1"
                                      type="number"
                                      value={item.inventory}
                                      onChange={(e) =>
                                        handleSizeChange(e, index)
                                      }
                                    />
                                  </InputGroup>
                                </td>
                                <td>
                                  <InputGroup>
                                    <FormControl
                                      name="skuId"
                                      aria-label="price"
                                      aria-describedby="basic-addon1"
                                      value={item.skuId}
                                      onChange={(e) =>
                                        handleSizeChange(e, index)
                                      }
                                    />
                                  </InputGroup>
                                </td>
                                <td>
                                  <Form.Select
                                    aria-label="Default select example"
                                    value={item.chestSize}
                                    name="chestSize"
                                    onChange={(e) => handleSizeChange(e, index)}
                                  >
                                    {chestNumber.map((item, index) => (
                                      <option value={item} key={index}>
                                        {item}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </td>
                                <td>
                                  <Form.Select
                                    aria-label="Default select example"
                                    value={item.lengthSize}
                                    name="lengthSize"
                                    onChange={(e) => handleSizeChange(e, index)}
                                  >
                                    {lengthNumber.map((item, index) => (
                                      <option value={item} key={index}>
                                        {item}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </td>
                                <td>
                                  <Button
                                    onClick={() => {
                                      handleRemoveSize(item, index);
                                    }}
                                  >
                                    <img src="assets/images/trash.svg" alt="" />
                                  </Button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  )}

                  <Form.Group className="mb-2 col-sm-12 col-md-6">
                    <Form.Label className="label_grey">Color</Form.Label>

                    <Form.Select
                      aria-label="Default select example"
                      className="mb-2"
                      value={color}
                      onChange={(e) => setColor(e.target.value)}
                    >
                      <option></option>

                      {singleAttributeData?.color.length > 0 ? (
                        singleAttributeData?.color.map((item, index) => (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        ))
                      ) : (
                        <option disabled>Color not found</option>
                      )}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-2 col-sm-12 col-md-6">
                    <Form.Label className="label_grey">Fabric</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      className="mb-2"
                      value={fabric}
                      onChange={(e) => setFabric(e.target.value)}
                    >
                      <option value=""></option>

                      <option value="Caution">Caution</option>
                      <option value="Silk">Silk</option>
                      <option value="Bamboo">Bamboo</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-2 col-sm-12 col-md-6">
                    <Form.Label className="label_grey">
                      Country of Origin
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      className="mb-2"
                      value={countryOrigin}
                      onChange={(e) => setCountryOrigin(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="1">Made in India</option>
                      <option value="2">Made in Japan</option>
                      <option value="3">Made in United States</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-2 col-sm-12 col-md-6">
                    <Form.Label className="label_grey">
                      Manufacturer Details
                    </Form.Label>
                    <InputGroup className="mb-2">
                      <FormControl
                        placeholder="Enter the manufacturer details"
                        aria-label="price"
                        aria-describedby="basic-addon1"
                        value={manufactureDetails}
                        onChange={(e) => setManufactureDetails(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-2 col-sm-12 col-md-6">
                    <Form.Label className="label_grey">
                      Packer Details
                    </Form.Label>
                    <InputGroup className="mb-2">
                      <FormControl
                        placeholder="Enter the Packer Details"
                        aria-label="price"
                        aria-describedby="basic-addon1"
                        value={packerDetails}
                        onChange={(e) => setPackerDetails(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-2 col-12">
                    <Form.Label className="one">
                      Other Attributes (Optional)
                    </Form.Label>
                  </Form.Group>
                  {singleAttributeData?.parameter.length > 0 ? (
                    singleAttributeData?.parameter.map((item, pIndex) => (
                      <Form.Group
                        className="mb-2 col-sm-12  col-md-6"
                        key={pIndex}
                      >
                        <Form.Label className="label_grey">
                          {item.parameterName}
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          className="mb-2"
                          name="attributeValue"
                          onChange={(e) => handleAttributeChange(e, pIndex)}
                        >
                          <option
                            value={
                              singleProductDetailData?.attributes[pIndex]
                                ?.attributeName === item.parameterName
                                ? singleProductDetailData?.attributes[pIndex]
                                  ?.attributeValue
                                : ""
                            }
                          >
                            {singleProductDetailData?.attributes[pIndex]
                              ?.attributeName === item.parameterName
                              ? singleProductDetailData?.attributes[pIndex]
                                ?.attributeValue
                              : ""}
                          </option>
                          {item?.subParameter.length > 0 ? (
                            item?.subParameter.map((item, index) => (
                              <>
                                {singleProductDetailData?.attributes[pIndex]
                                  ?.attributeValue === item ? (
                                  ""
                                ) : (
                                  <option value={item} key={index}>
                                    {item}
                                  </option>
                                )}
                              </>
                            ))
                          ) : (
                            <option disabled>Not found</option>
                          )}
                        </Form.Select>
                      </Form.Group>
                    ))
                  ) : (
                    <Form.Group className="mb-2 col-sm-12  col-md-6">
                      <Form.Label className="label_grey">
                        {/* No Other Attributes found */}
                      </Form.Label>
                    </Form.Group>
                  )}
                </Form>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                Item One
              </TabPanel>
              {/* <form className='form_activity'>
          <div>
            <label>Prize , Size and Inventory:</label>
          </div>
          <div className=''
        </form> */}
            </div>
          </div>
        </Col>
        <Col xs={12} lg={4}>
          <div>
            <div className="product_card_box">
              <h6 className="heading_product_image">Product Image</h6>
              <Box sx={{ width: "100%" }}>
                {progressLoading && <LinearProgress />}
              </Box>

              <div className="upload_product_image">
                <p className="upload_product_heading">Upload image</p>

                <div className="form-group">
                  <div className="upload-container">
                    <div className="upload-inner-container">
                      <input
                        type="file"
                        accept="image/jpeg, image/png, image/jpg"
                        multiple={true}
                        // data-max_length="6"
                        style={{ display: "none" }}
                        id="multipleFiles"
                        onChange={(e) => {
                          handleImage(e);
                          // createCategoryImagesChange(e);
                        }}
                      />
                      <div className="file">
                        <label htmlFor="multipleFiles" className="file_upload">
                          <PlusIcon className="upload_image" />
                        </label>
                      </div>
                    </div>

                    {multiImageList &&
                      multiImageList.length > 0 &&
                      multiImageList.map((item, index) => (
                        <div className="file" key={index}>
                          <label className="file_upload border_none">
                            {" "}
                            <div
                              className="close_icon"
                              onClick={() => handleDeleteImage(item)}
                            >
                              <HighlightOffIcon />
                            </div>
                            <img
                              key={index}
                              src={item}
                              className="w-100 img_preview"
                              alt=""
                            />
                          </label>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div className="submit_product text-center">
                <Button
                  className="btn_cancel"
                  onClick={() => history.push("/products")}
                >
                  Cancel
                </Button>
                <Button
                  className="btn_submit"
                  onClick={() => handleSubmitForm()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default AddProductForm;
