import * as React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CollapseInnerMainList from "./CollapseInnerMainList";
import { useSelector } from "react-redux";
import {
  categoryToggleAction,
  filterProductWithAllFiltersAction,
  saveCategoryIdsInStore,
  showColorAndPriceAction,
  showFilterSubCategoriesAction,
} from "../../../../services/actions/product.action";
import { useDispatch } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";
export default function SimpleAccordion() {
  const { categoryList } = useSelector((state) => state.categoryList);
  const { subCategoryList } = useSelector((state) => state.subCategoryList);

  const { filter } = useSelector((state) => state.catToggleState);

  const [data, setData] = React.useState(null);

  let categoryId =
    subCategoryList &&
    subCategoryList.length !== 0 &&
    subCategoryList[0].subCategory.category;

  const dispatch = useDispatch();

  const handleFilter = (item) => {
    let filterState = {
      price: "",
      size: "",
      color: "",
      subSubCateId: "",
      categoryId: item && item._id,
      subCategoryId: "",
    };
    dispatch(saveCategoryIdsInStore(item._id));
    if (categoryId) {
      dispatch(showColorAndPriceAction(item._id, "cat"));
      categoryId != item._id && dispatch(showFilterSubCategoriesAction(item));
    } else {
      dispatch(showFilterSubCategoriesAction(item));
      dispatch(showColorAndPriceAction(item._id, "cat"));
    }

    dispatch(filterProductWithAllFiltersAction(filterState));
  };

  const handleReset = () => {
    setData(false);
  };

  const handleToggle = (item) => {
    if (data) {
      if (item._id == data._id) {
        handleReset();
      } else {
        setData(item);
        handleFilter(item);
        dispatch(categoryToggleAction(true));
      }
    } else {
      setData(item);
      handleFilter(item);
      dispatch(categoryToggleAction(true));
    }
  };

  React.useEffect(() => {
    if (!filter) {
      handleReset();
    }
  }, [filter]);

  AOS.init();

  return (
    <div className="collapse_filter_menu">
      {categoryList &&
        categoryList.map((item, index) => (
          <div key={index}>
            <div
              className="row pb-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleToggle(item);
              }}
            >
              <div className="col-10 filter_heading">{item.categoryName}</div>
              <div className="col-2">
                <ExpandMoreIcon
                  className={
                    data && data._id === item._id ? "rotate down" : "rotate"
                  }
                />
              </div>
            </div>
            {data && data._id === item._id && <CollapseInnerMainList />}
          </div>
        ))}

      {/* {categoryList &&
        categoryList.map((item, index) => (
          <Accordion
            key={index}
            onClick={() => {
              handleFilter(item);
            }}
            expanded={expanded === "panel" + index + 1}
            onChange={handleChange("panel" + index + 1)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`panel${index + 1}1bh-header`}
              className="category_filter_collapse"
            >
              <Typography className="filter_heading">
                {item.categoryName}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <CollapseInnerMainList />
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))} */}
    </div>
  );
}
