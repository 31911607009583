import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  deliverModalAction,

} from "../../services/actions/deliveryFee.action";

const AddNotificationButton = () => {
  return (
    <Link to="/add_notifications">
      <div className="add_product_btn add_notification">Add Notifications</div>
    </Link>
  );
};



const AddDeliveryFeeButton = () => {
  const dispatch = useDispatch();
  return (
    <Link to="/add_delivery_fee" onClick={() => dispatch(deliverModalAction("ADD_FEE"))}>
      <div className="add_product_btn add_notification">Add Delivery Fee</div>
    </Link>
  );
};



export { AddNotificationButton, AddDeliveryFeeButton };
