import React, { useState, useEffect } from "react";
import { Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAttributeSizes } from "../../services/actions/attribute.action";
function SizeAttributes() {
  const dispatch = useDispatch();
  const { singleAttributeData } = useSelector(
    (state) => state.singleAttributeData
  );
  const [sizeArr, setSizeArr] = useState([]);
  const [size, setSize] = useState("");

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (event.target.value) {
        setSizeArr((pre) => [...pre, event.target.value]);
        setSize("");
      }
    }
  };

  const deleteSize = (item) => {
    let data = sizeArr.filter((size) => size !== item);
    setSizeArr(data);
  };

  useEffect(() => {
    dispatch(getAttributeSizes(sizeArr));
  }, [dispatch, sizeArr]);

  useEffect(() => {
    if (singleAttributeData && singleAttributeData.size) {
      setSizeArr(singleAttributeData && singleAttributeData.size);
    }
  }, [singleAttributeData]);

  console.log(
    singleAttributeData && singleAttributeData.size,
    "singleAttributeData"
  );

  return (
    <div>
      <Row>
        <Col xs={12}>
          <h6>Add Size</h6>
        </Col>
      </Row>
      <div className="form_activity form_add_product row mt-2">
        <Form.Group className="mb-2 col-sm-12 col-md-6">
          <InputGroup className="mb-2">
            <FormControl
              placeholder="XXL"
              aria-label="size"
              aria-describedby="basic-addon1"
              value={size}
              onChange={(e) => setSize(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-2 col-sm-12 col-md-6">
          <div className="d-flex flex-wrap">
            {sizeArr && sizeArr.length > 0
              ? sizeArr.map((item, index) => (
                  <div
                    className="size_box"
                    key={index}
                    onClick={() => deleteSize(item)}
                  >
                    <span>{item}</span>
                    <img src="assets/images/close_btn1.svg" alt="" />
                  </div>
                ))
              : null}
          </div>
        </Form.Group>
      </div>
    </div>
  );
}

export default SizeAttributes;
