import React, { useEffect } from "react";

import Sidebar from "../Sidebar/Sidebar";
import Heading from "../Heading/Heading";
import { useStyles } from "../../utils/useStyles";
import EditCategoryAttribute from "./EditCategoryAttribute";
import SizeAttributes from "./SizeAttributes";
import "./Attributes.css";
import AddParamAttributes from "./AddParamAttributes";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import ColorAttributes from "./ColorAttributes";
function EditAttribute() {
  const classes = useStyles();
  const { message, error } = useSelector((state) => state.addAttribute);

  const alert = useAlert();
  const history = useHistory();

  useEffect(() => {
    if (message) {
      message && alert.success(message);
      history.push("/attributes");
    } else {
      error && alert.error(error);
    }
  }, [message, error, alert, history]);

  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading />

        {/* Data table */}
        <div className="card_box">
          <EditCategoryAttribute />
          <hr />
          <SizeAttributes />
          <hr />
          <ColorAttributes />
          <hr />
          <AddParamAttributes />
        </div>
      </main>
    </div>
  );
}

export default EditAttribute;
