import React, { useEffect, useState } from "react";
import { Button, Container, Form, Modal } from "react-bootstrap";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import {
  addCategoriesAction,
  addSubCategoryAction,
  clearCategory,
  editCategoriesAction,
  editSubCategoryAction,
} from "../../services/actions/category.action";
import { useDispatch, useSelector } from "react-redux";

import { useAlert } from "react-alert";
import { useLocation } from "react-router";
import axios from "axios";
import { Api } from "../../utils/API/Api";
import { loaderAction } from "../../services/actions/loader.action";
function MyVerticallyCenteredModal(props) {
  const { title, heading, imageTitle, type, _id, body } = useSelector(
    (state) => state.categoryModal
  );

  const { categoryData } = useSelector((state) => state.subCategoryToggle);
  const { url } = useSelector((state) => state.requestUrl);

  const { categorySubData } = useSelector(
    (state) => state.subSubCategoryToggle
  );

  const alert = useAlert();

  const dispatch = useDispatch();

  const [upload, setUpload] = useState(null);
  const [imagesPreview, setImagesPreview] = useState([]);
  const [name, setName] = useState("");
  const [imgUrl, setImgUrl] = useState("");

  const [images, setImages] = useState([]);

  const createCategoryImagesChange = async (e) => {
    // alert("test");
    const files = Array.from(e.target.files);

    setImages([]);
    setImagesPreview([]);
    // alert("test");
    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview((old) => [...old, reader.result]);
          setImages((old) => [...old, reader.result]);
        }
      };

      reader.readAsDataURL(file);
      setUpload(e.target.files);

      setImgUrl(null);
    });
  };

  const emptyFrom = () => {
    setName("");
    setUpload(null);
    setImagesPreview([]);
    setImgUrl(null);
  };

  // add category

  // add category

  const createCategorySubmitHandler = async (e) => {
    e.preventDefault();
    const file = upload;
    const fileData = file === null ? null : file[0];
    var myForm = new FormData();

    if (type) {
      if (name) {
        switch (type) {
          case "ADD_CATEGORY": {
            myForm.set("categoryName", name);
            myForm.append("image", fileData);
            emptyFrom();
            props.onHide();
            return dispatch(addCategoriesAction(type, myForm));
          }
          case "ADD_SUB_CATEGORY": {
            const data = {
              subCategoryName: name,
              category: categoryData._id,
            };
            emptyFrom();
            props.onHide();
            return dispatch(addSubCategoryAction(type, data, categoryData));
          }
          case "ADD_SUB_SUB_CATEGORY": {
            myForm.set("subSubCategoryName", name);
            myForm.append("image", fileData);
            myForm.set("category", categorySubData && categorySubData.category);
            myForm.set("subCategory", categorySubData && categorySubData._id);
            console.log(categorySubData, "categorySubData");
            emptyFrom();
            props.onHide();
            return dispatch(addCategoriesAction(type, myForm, categorySubData));
          }
          default:
            return false;
        }
      } else {
        alert.info("All fields are required");
      }
    } else {
      emptyFrom();
      props.onHide();
    }
  };

  const editCategorySubmitHandler = async (e) => {
    e.preventDefault();
    const file = upload;
    const fileData = file === null ? null : file[0];
    var myForm = new FormData();

    if (type) {
      if (name) {
        switch (type) {
          case "EDIT_CATEGORY": {
            myForm.set("categoryName", name);
            myForm.append("_id", _id);
            myForm.append("image", fileData);

            emptyFrom();
            props.onHide();
            return dispatch(editCategoriesAction(type, myForm));
          }
          case "EDIT_SUB_CATEGORY": {
            const data = {
              subCategoryName: name,
              category: categoryData._id,
              _id: _id,
            };
            emptyFrom();
            props.onHide();
            return dispatch(editSubCategoryAction(type, data, categoryData));
          }
          case "EDIT_SUB_SUB_CATEGORY": {
            myForm.set("subSubCategoryName", name);
            myForm.append("_id", _id);
            myForm.append("image", fileData);
            myForm.set("category", categorySubData && categorySubData.category);
            myForm.set("subCategory", categorySubData && categorySubData._id);
            emptyFrom();
            props.onHide();
            return dispatch(
              editCategoriesAction(type, myForm, categorySubData)
            );
          }
          default:
            return false;
        }
      } else {
        alert.info("All fields are required");
      }
    } else {
      emptyFrom();
      props.onHide();
    }
  };

  useEffect(() => {
    if (_id) {
      setName(
        body?.categoryName || body?.subCategoryName || body?.subSubCategoryName
      );
      setImgUrl(body?.categoryImage || body.image);
    }
  }, [body]);

  let cateType = ["ADD_CATEGORY", "ADD_SUB_CATEGORY", "ADD_SUB_SUB_CATEGORY"];

  useEffect(() => {
    if (cateType.includes(type)) {
      emptyFrom();
    }
  }, [type]);

  return (
    <Modal
      className="modal_add_Category"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {heading && heading}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <div className="form-group">
            <label>{title && title}</label>
            <input
              type="text"
              placeholder={`Enter ${title && title} name`}
              autoComplete="off"
              className=" form-control "
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          {imageTitle && (
            <>
              <div className="form-group">
                <label>{imageTitle && imageTitle}</label>
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  id="contained-button-file"
                  onChange={createCategoryImagesChange}
                />
                <div className="file">
                  <label
                    htmlFor="contained-button-file"
                    className="file_upload"
                  >
                    {imagesPreview.length === 0 ? (
                      <>
                        {imgUrl ? (
                          <img
                            src={imgUrl}
                            className="w-100 img_preview auto_image"
                            alt="Category Preview"
                          />
                        ) : (
                          <CollectionsOutlinedIcon className="upload_image" />
                        )}
                      </>
                    ) : (
                      imagesPreview.map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          className="w-100 img_preview"
                          alt="Category Preview"
                        />
                      ))
                    )}
                  </label>
                </div>
              </div>
            </>
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            emptyFrom();
            props.onHide();
          }}
          className="btn_cancel"
        >
          Cancel
        </Button>
        {_id ? (
          <Button onClick={editCategorySubmitHandler} className="btn_submit">
            Submit
          </Button>
        ) : (
          <Button onClick={createCategorySubmitHandler} className="btn_submit">
            Submit
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

function CategoryModal({ modalShow, setModalShow }) {
  const dispatch = useDispatch();

  return (
    <MyVerticallyCenteredModal
      show={modalShow}
      setModalShow={setModalShow}
      clearFrom={true}
      onHide={() => {
        setModalShow(false);
        dispatch(clearCategory());
        setModalShow(false);
      }}
    />
  );
}

export default CategoryModal;
