import { combineReducers } from "redux";
import {
  addAttributeReducer,
  getAllAttributesReducer,
  getCategoriesIdReducer,
  getAttributeSizesReducer,
  getAttributesReducer,
  sendAttributeDataReducer,
  deleteAttributeReducer,
  getAttributeColorReducer,
} from "./attribute.reducer";
import { authReducer } from "./auth.reducer";
import {
  addCategoriesReducer,
  categoryModalReducer,
  categoryReducer,
  categorySubSubModalReducer,
  editSubSubCategoryImageReducer,
  subCategoryReducer,
  subSubCategoryReducer,
  toggleCategoryReducer,
  toggleSubCategoryReducer,
} from "./category.reducer";
import { deliverModalReducer, getDeliverReducer } from "./deliveryFee.reducer";
import { loaderReducer } from "./loader.reducer";
import {
  filterProductWithCategoryReducer,
  productPageReloadReducer,
  showAddProductImageReducer,
  showFilterSubCategoriesReducer,
  showProductWithFilterReducer,
  showColorAndPriceReducer,
  categoryToggleReducer,
  filterProductWithAllFiltersReducer,
  addProductCategoriesIdReducer,
  addFrontImageProductReducer,
  addMultiImageProductReducer,
  savePriceFilterDataInStoreReducer,
  saveSizeFilterDataInStoreReducer,
  saveColorFilterDataInStoreReducer,
  saveCategoryIdsInStoreReducer,
  saveSubCategoryIdsInStoreReducer,
  saveSubSubCategoryIdsInStoreReducer,
  saveSingleProductDetailsReducer,
} from "./product.reducer";
import { userReducer } from "./user.reducer";
const rootReducer = combineReducers({
  // auth
  loader: loaderReducer,
  auth: authReducer,
  userList: userReducer,

  //category
  categoryModal: categoryModalReducer,
  subSubCategoryModal: categorySubSubModalReducer,
  addCategoryMessage: addCategoriesReducer,
  subCategoryToggle: toggleCategoryReducer,
  subSubCategoryToggle: toggleSubCategoryReducer,
  categoryList: categoryReducer,
  categorySubList: subCategoryReducer,
  categorySubSubList: subSubCategoryReducer,
  requestUrl: editSubSubCategoryImageReducer,

  // product
  reloadStatus: productPageReloadReducer,
  showProductImageBtn: showAddProductImageReducer,
  productList: showProductWithFilterReducer,
  subCategoryList: showFilterSubCategoriesReducer,
  filterCatData: filterProductWithCategoryReducer,
  priceAndColor: showColorAndPriceReducer,
  catToggleState: categoryToggleReducer,
  filterData: filterProductWithAllFiltersReducer,

  priceData: savePriceFilterDataInStoreReducer,
  sizeData: saveSizeFilterDataInStoreReducer,
  colorData: saveColorFilterDataInStoreReducer,
  categoryData: saveCategoryIdsInStoreReducer,
  categorySubData: saveSubCategoryIdsInStoreReducer,
  categorySubSubData: saveSubSubCategoryIdsInStoreReducer,
  singleProductDetailData: saveSingleProductDetailsReducer,

  // Add product details

  categoriesId: addProductCategoriesIdReducer,
  frontImageList: addFrontImageProductReducer,
  multiImageList: addMultiImageProductReducer,

  // Add attributes
  allAttributes: getAllAttributesReducer,
  singleAttributeData: getAttributesReducer,
  getSingleAttributeData: sendAttributeDataReducer,
  categoryIds: getCategoriesIdReducer,
  size: getAttributeSizesReducer,
  color: getAttributeColorReducer,
  addAttribute: addAttributeReducer,
  deleteAttribute: deleteAttributeReducer,

  // delivery modules

  deliveryAction: deliverModalReducer,
  deliveryData: getDeliverReducer
});

export default rootReducer;
