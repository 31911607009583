import React from "react";

import Sidebar from "../Sidebar/Sidebar";
import Heading from "../Heading/Heading";
import { useStyles } from "../../utils/useStyles";
import "./Attributes.css";
import AttributeTable from "./AttributeTable";
import { useDispatch } from "react-redux";
import {
  backToAttributePage,
  getAllAttributesAction,
} from "../../services/actions/attribute.action";
function Attributes() {
  const classes = useStyles();
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(backToAttributePage(true));
    dispatch(getAllAttributesAction());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading addAttributeButton={true} />

        {/* Data table */}
        <div className="card_box">
          <AttributeTable />
        </div>
      </main>
    </div>
  );
}

export default Attributes;
