import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import Heading from "../Heading/Heading";
import { useStyles } from "../../utils/useStyles";
import CountCard from "./CountCard";
import TopSellingProductFilter from "./TopSellingProductFilter";
import { Col, Row } from "react-bootstrap";
import SocialSource from "./SocialSource";
import RecentSaleProduct from "./RecentSaleProduct";
import SharedProductInSocial from "./SharedProductInSocial";
import "./Dashboard.css";
import TransactionInner from "./Transactions/TransactionInner";
import ResellerInner from "./Reseller/ResellerInner";
export default function Dashboard() {
  const classes = useStyles();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading dashboardDataRange={true} />

        <div className="total_count_cards">
          <CountCard />
        </div>

        <div>
          <Row>
            <Col>
              <div className="card_box h_3">
                <TopSellingProductFilter />
              </div>
            </Col>
            <Col>
              <div className="card_box h_3">
                <SocialSource />
              </div>
            </Col>
          </Row>
        </div>

        <div>
          <Row>
            <Col xs lg="6">
              <div className="card_box h_3  pt-0">
                <RecentSaleProduct />
              </div>
            </Col>
            <Col xs lg="6">
              <div className="card_box h_3 pt-0">
                <SharedProductInSocial />
              </div>
            </Col>
          </Row>
        </div>

        <div>
          <Row>
            <Col xs lg="6">
              <div className="card_box recent_sale_product h_3 pt-0">
                <TransactionInner />
              </div>
            </Col>
            <Col xs lg="6">
              <div className="card_box h_3 pt-0">
                <ResellerInner />
              </div>
            </Col>
          </Row>
        </div>
        {/* Data table */}
      </main>
    </div>
  );
}
