import React from "react";

import MaterialTable from "material-table";
import { DeleteIcon, EditIcon } from "../../../utils/MenusList/Icons";
import { Link } from "react-router-dom";

export default function ResellerInner() {
  const columns = [
    { title: "#", field: "id" },
    { title: "Product Name", field: "productName" },
    { title: "Facebook", field: "facebook" },
    { title: "Google", field: "google" },
    { title: "Instagram", field: "instagram" },
  ];
  const options = {
    print: true,
    download: true,
    filter: true,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 50, 100],
    actionsColumnIndex: -1,
    search: false,
  };
  const tableData = [
    {
      id: 1,
      productName: "Shirt",
      facebook: "100k",
      google: "50k",
      instagram: "20k",
    },
  ];
  const actions = [
    {
      icon: EditIcon,
      tooltip: "Edit Order",
      // onClick: (event, rowData) => history.push("/orders-detail"),
    },
    {
      icon: DeleteIcon,
      tooltip: "Delete Order",
      // onClick: (event, rowData) => history.push("/orders-detail"),
    },
  ];

  return (
    <>
      <div className={"table_dashboard"}>
        <MaterialTable
          options={options}
          data={tableData && tableData}
          columns={columns && columns}
          title={"Reseller"}
          onRowClick={(event, rowData) => console.log(rowData)}
          actions={actions && actions}
        />
      </div>
      <Link to="/dashboard_reseller">See more</Link>
    </>
  );
}
