import React from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import CommissionEarnedSlider from "./CommissionEarnedSlider";

function ByCommissionEarned() {
  return (
    <div>
      <Form className="form_activity">
        <Form.Group>
          <Form.Label>By Commission Earned </Form.Label>
          <CommissionEarnedSlider />
        </Form.Group>

        <Form.Group className="no_of_shares">
          <Form.Label>Number of Shares</Form.Label>
          <FormControl
            placeholder="Enter the number of shares"
            aria-label="shares"
            aria-describedby="basic-addon1"
          />
        </Form.Group>
      </Form>
    </div>
  );
}

export default ByCommissionEarned;
