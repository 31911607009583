import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { useSelector } from "react-redux";
import {
  filterProductWithAllFiltersAction,
  priceProductFilterAction,
  savePriceFilterDataInStore,
} from "../../../services/actions/product.action";
import { useDispatch } from "react-redux";
import { Skeleton } from "@mui/material";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import InputAdornment from "@mui/material/InputAdornment";
function valueText(value) {
  return `${value}°C`;
}

export default function CategoryPriceFIlter() {
  const { max, loader } = useSelector((state) => state.productList);

  const { categoryData } = useSelector((state) => state.categoryData);
  const { categorySubData } = useSelector((state) => state.categorySubData);
  const { categorySubSubData } = useSelector(
    (state) => state.categorySubSubData
  );

  const { sizeData } = useSelector((state) => state.sizeData);
  const { colorData } = useSelector((state) => state.colorData);

  const [value, setValue] = React.useState([0, 10]);
  const dispatch = useDispatch();
  let typingTimer = null;

  const handleChange = (event) => {
    // console.log(event);
    const val = event.target.value;

    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => {
      if (val) {
        setValue(val);

        let data = {
          price: [
            Math.floor((val[0] * max) / 8),
            Math.floor((val[1] * max) / 8),
          ],
          size: sizeData ? sizeData : "",
          color: colorData ? colorData : "",
          subSubCateId: categorySubSubData ? categorySubSubData : "",
          categoryId: categoryData ? categoryData : "",
          subCategoryId: categorySubData ? categorySubData : "",
        };
        // dispatch(priceProductFilterAction(val, max, data));
        dispatch(
          savePriceFilterDataInStore([
            Math.floor((val[0] * max) / 8),
            Math.floor((val[1] * max) / 8),
          ])
        );
        dispatch(filterProductWithAllFiltersAction(data));
      }
    }, 25);
  };

  const marks = [
    {
      value: 0,
    },

    {
      value: 10,
    },
  ];

  React.useEffect(() => {
    setValue([0, max * 10]);
  }, [max]);

  React.useEffect(() => {
    clearTimeout(typingTimer);
  }, [typingTimer]);

  return (
    <Box className="price_filter_container">
      {loader ? (
        <Skeleton animation="wave" />
      ) : (
        <Slider
          getAriaLabel={() => "Product Price"}
          value={value}
          onChange={(e) => handleChange(e)}
          valueLabelDisplay="off"
          getAriaValueText={valueText}
          marks={marks}
          valueLabelFormat={(value) => <span className="price_label"></span>}
          className="commission_slider"
        />
      )}
      <div className="box_prix">
        <Row>
          <Col xs={12} md={6}>
            <div className="prix_container">
              <span>Price Minimum</span>
              <span className="sign">FCFA</span>
              <Form.Group className="mb-3">
                <Form.Control
                  disabled
                  type="number"
                  placeholder={(value[0] * max) / 8}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </Form.Group>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="prix_container">
              <span>Price Maximum</span>
              <span className="sign">FCFA</span>
              <Form.Group className="mb-3">
                <Form.Control
                  disabled
                  type="number"
                  placeholder={(value[1] * max) / 8}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </Form.Group>
            </div>
          </Col>
        </Row>
        <div className="hypen">-</div>
      </div>
    </Box>
  );
}
