import React from "react";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import LeftSideBanner from "../Login/LeftSideBanner";
function ForgotPassword() {
  return (
    <div>
      <div className="bg-grey">
        <Container fluid>
          <Row>
            <Col className="login_left_side">
              <LeftSideBanner />
            </Col>
            <Col className="login_right_side">
              <div className="right_side_content">
                <Form className="" autoComplete="off">
                  <p className="login_heading mb-4">Forgot Password</p>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Email Address"
                      autoComplete="off"
                    />
                  </Form.Group>

                  <div className="d-flex justify-content-between mt-4">
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <NavLink to="/" className="back_to_login">
                        Back to Login
                      </NavLink>
                    </Form.Group>
                    <NavLink to="/dashboard" className="link_forgot_password">
                      <Button>
                        Submit{" "}
                        <ArrowRightAltIcon className="right_arrow ml-2" />
                      </Button>
                    </NavLink>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default ForgotPassword;
