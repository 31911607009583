import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { positions, transitions, Provider as AlertProvider } from "react-alert";

import App from "./App";

import AlertTemplate from "react-alert-template-basic";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import store from "./services/store/store";

const options = {
  timeout: 3000,
  position: positions.TOP_RIGHT,
  transition: transitions.SCALE,
};
window.store = store;

ReactDOM.render(
  <Provider store={store}>
    <AlertProvider template={AlertTemplate} {...options} className="mt-5 pt-4">
      {/* <ThemeProvider theme={theme}> */}
      <App />
      {/* </ThemeProvider> */}
    </AlertProvider>
  </Provider>,
  document.getElementById("root")
);
