import React from "react";

import MaterialTable from "material-table";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import SwitchButton from "../Users/SwitchButton";
import { DeleteIcon, EditIcon } from "../../utils/MenusList/Icons";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Api } from "./../../utils/API/Api";
import { useFetch } from "../../Hooks/useFetch";
import LinearProgressWithLabel from "../../Loader/LinearProgessWithLabel";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAlert } from "react-alert";
import { loaderAction } from "./../../services/actions/loader.action";
import { useDispatch } from "react-redux";

const options = {
  print: true,
  download: true,
  filter: true,
  viewColumns: false,
  selectableRowsHideCheckboxes: true,
  rowsPerPage: 10,
  rowsPerPageOptions: [5, 10, 50, 100],
};
function DriverDatatable() {
  const { data, loading, reFetchData } = useFetch(Api.getDrivers);
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();
  const columns = [
    {
      title: "#",
      field: "index",
    },
    { title: "Driver Name", field: "name" },
    { title: "Phone Number", field: "phoneNumber" },

    { title: "License ID", field: "driverLicenseNumber" },
    { title: "Vehicle Number", field: "numberPlate" },

    {
      title: "Deactivate Driver",
      field: "isActive",
      render: (item) => <SwitchButton item={item} driver={true} />,
    },
  ];
  const options = {
    print: true,
    download: true,
    filter: true,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 50, 100],
    actionsColumnIndex: -1,
  };
  let driverData =
    data.length > 0
      ? data.map((item, index) => {
          return {
            index: index + 1,
            _id: item._id,
            name: item?.name,
            phoneNumber: item?.phoneNumber,
            driverLicenseNumber: item?.driverLicenseNumber,
            numberPlate: item?.numberPlate,
            isActive: item?.isActive,
            rowData: item,
          };
        })
      : [];

  const actions = [
    {
      icon: ModeEditIcon,
      tooltip: "Edit Order",
      onClick: (event, rowData) =>
        history.push("/add_driver?id=" + rowData._id),
      className: "view_icon",
    },
    {
      icon: DeleteIcon,
      tooltip: "Delete Order",
      onClick: (event, rowData) => handleDelete(rowData._id),
    },
  ];

  const handleDelete = async (id) => {
    dispatch(loaderAction(true));
    await axios
      .put(Api.deleteDriver, { _id: id })
      .then((res) => {
        if (res.data.status === 1) {
          alert.success(res.data.message);
          reFetchData();
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => {
        alert.error(err.message);
      });

    dispatch(loaderAction(false));
  };

  return (
    <>
      <Box>
        {loading ? (
          <LinearProgressWithLabel />
        ) : (
          <MaterialTable
            options={options && options}
            data={driverData && driverData}
            columns={columns && columns}
            title=""
            // onRowClick={(event, rowData) => console.log(rowData)}
            actions={actions && actions}
          />
        )}
      </Box>
    </>
  );
}

export default DriverDatatable;
