import { FormControl } from "@material-ui/core";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  colorProductFilterAction,
  filterProductWithAllFiltersAction,
  saveColorFilterDataInStore,
} from "../../../services/actions/product.action";

function ColorRadio() {
  const { color } = useSelector((state) => state.priceAndColor);

  const { categoryData } = useSelector((state) => state.categoryData);
  const { categorySubData } = useSelector((state) => state.categorySubData);
  const { categorySubSubData } = useSelector(
    (state) => state.categorySubSubData
  );

  const { sizeData } = useSelector((state) => state.sizeData);
  const { priceData } = useSelector((state) => state.priceData);

  const dispatch = useDispatch();
  const handleFilter = (e) => {
    let data = {
      price: priceData ? priceData : "",
      size: sizeData ? sizeData : "",
      color: e.target.value,
      subSubCateId: categorySubSubData ? categorySubSubData : "",
      categoryId: categoryData ? categoryData : "",
      subCategoryId: categorySubData ? categorySubData : "",
    };
    dispatch(filterProductWithAllFiltersAction(data));

    dispatch(saveColorFilterDataInStore(e.target.value));
  };

  React.useEffect(() => {
    // let data = {
    //   price: price ? price : "",
    //   size: size ? size : "",
    //   color: e.target.value,
    //   subSubCateId: subSubCateId ? subSubCateId : "",
    //   categoryId: categoryId ? categoryId : "",
    //   subCategoryId: subCategoryId ? subCategoryId : "",
    // };
  }, []);

  return (
    <>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
          className="radio_product"
        >
          {color && color.length > 0 ? (
            color &&
            color.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item}
                control={<Radio />}
                label={item}
                onChange={(e) =>
                  // dispatch(colorProductFilterAction(e.target.value))
                  handleFilter(e)
                }
              />
            ))
          ) : (
            <p value="No color">No color found</p>
          )}
        </RadioGroup>
      </FormControl>
    </>
  );
}

export default ColorRadio;
