import cookie from "js-cookie";

// set cookie

export const setCookie = (key, value) => {
  if (window !== "undefined") {
    cookie.set(key, value, {
      // 1 day
      expires: 1,
    });
  }
};

// Remove from cookie

export const removeCookie = (key) => {
  if (window !== "undefined") {
    cookie.remove(key, {
      // 1 day
      expires: 1,
    });
  }
};

// Get from cookie like token, type etc

export const getCookie = (key) => {
  if (window !== "undefined") {
    return cookie.get(key);
  }
};

// redux management
// => user

// Auth user after login for vendor

export const authenticate = (token, callback) => {
  setCookie("admin-token", token);
  callback();
};

//   get authentication

export const getAuthentication = () => {
  return getCookie("admin-token");
};

// remove authentication

export const removeAuthentication = (callback) => {
  removeCookie("admin-token");
  callback();
};

// Auth user after login for vendor
export const setEmail = (email, next) => {
  setCookie("e", email);
  next();
};

export const getEmail = () => {
  return getCookie("e");
};

export const removeEmail = (next) => {
  removeCookie("e");
  next();
};

// set session after login for vendor

export const setSession = (token, callback) => {
  sessionStorage.setItem("token_session", token);
  callback();
};

export const getSession = () => {
  return sessionStorage.getItem("token_session");
};

export const removeSession = (next) => {
  sessionStorage.removeItem("token_session");
  next();
};
