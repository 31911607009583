import React from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import { headingToggle } from "../../utils/controller/HeadingChange";
import BasicDateRangePicker from "../Dashboard/BasicDateRangePicker";
import ProductSearch from "../Products/ProductSearch";
import AddProductButton from "../Products/AddProductButton";
import "./Heading.css";
import AddDriverButton from "../DriverUsers/AddDriverButton";
import AddAttributeButton from "../Attributes/AddAttributeButton";
import {
  AddDeliveryFeeButton,
  AddNotificationButton,
} from "../AddButtons/AddButtons";
function Heading({
  dashboardDataRange,
  productAction,
  driverButton,
  addAttributeButton,
  notificationButton,
  deliveryFeesButton, type
}) {
  let location = useLocation();

  const [heading, setHeading] = React.useState("");

  const search = useLocation().search;
  const params = new URLSearchParams(search).get("id");

  React.useEffect(() => {
    headingToggle(setHeading, location, params);
  }, [location, heading, params]);

  return (
    <Row>
      <Col>
        <div className="heading">

          <h3> {type ? type == "Edit" ? "Edit Delivery Fee" : "Add Delivery Fee " : heading}</h3>
        </div>
      </Col>
      <Col>
        {dashboardDataRange && dashboardDataRange ? (
          <BasicDateRangePicker />
        ) : null}
        {productAction && (
          <div className="product_search">
            <ProductSearch />
            <AddProductButton />
          </div>
        )}
        {driverButton && <AddDriverButton />}

        {addAttributeButton && <AddAttributeButton />}
        {notificationButton && <AddNotificationButton />}
        {deliveryFeesButton && <AddDeliveryFeeButton />}
      </Col>
    </Row>
  );
}

export default Heading;
