import React, { useState } from "react";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import {
  categoryModalAction,
  getCategoryAction,
  getSubCategoryAction,
  hideToggleCategory,
  toggleSubCategoryAction,
  toggleSubSubCategoryAction,
} from "../../services/actions/category.action";
import { useDispatch } from "react-redux";
import { loaderAction } from "../../services/actions/loader.action";
import { customPostAPI, deleteCustomAPI } from "../../middleware/apiMiddleware";
import { Api } from "../../utils/API/Api";
import { useAlert } from "react-alert";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import CategoryModal from "./CategoryModal";

const Container = styled.div`
  background-color: ${(props) =>
    props.isDragDisabled
      ? "lightgrey"
      : props.isDragging
      ? "lightgreen"
      : "white"};
`;

export const SubCategoryTask = ({ index, task }) => {
  let type = "EDIT_SUB_CATEGORY";
  const isDragDisabled = task._id === "task-1";
  const { categoryData } = useSelector((state) => state.subCategoryToggle);
  const { categorySubData } = useSelector(
    (state) => state.subSubCategoryToggle
  );
  const alert = useAlert();
  let location = useLocation();
  const dispatch = useDispatch();

  const [action, setAction] = useState(true);
  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {
    if (location.pathname === "/add_products") {
      setAction(false);
    } else {
      setAction(true);
    }
  }, []);

  const handleDelete = async (id) => {
    dispatch(loaderAction(true));

    let body = {
      _id: id,
    };
    const { data, error } = await deleteCustomAPI(Api.deleteSubCategory, body);
    if (!error) {
      if (data.status === 1) {
        dispatch(getSubCategoryAction(categoryData));

        if (id == categorySubData?._id) {
          dispatch(toggleSubSubCategoryAction(categoryData, true));
        }
        alert.success(data.message);
      } else {
        alert.error(data.message);
      }
    } else {
      alert.error(error.message);
    }
    dispatch(loaderAction(false));
  };

  return (
    <>
      <CategoryModal modalShow={modalShow} setModalShow={setModalShow} />

      <Draggable
        draggableId={task._id}
        isDragDisabled={isDragDisabled}
        index={index}
      >
        {(provided, snapshot) => (
          <Container
            ref={provided.innerRef}
            {...provided.draggableProps}
            isDragging={snapshot.isDragging}
            isDragDisabled={isDragDisabled}
            {...provided.dragHandleProps}
          >
            <div
              key={index}
              className={
                task.content?._id === categorySubData?._id
                  ? "menu_btn_active"
                  : null
              }
            >
              <div className="d-flex justify-content-start category_box_container">
                <div
                  className="category_name_text"
                  onClick={() => {
                    dispatch(toggleSubSubCategoryAction(task.content, true));
                  }}
                >
                  <h5 className="category_name">
                    {task.content.subCategoryName}
                  </h5>
                </div>
                <div
                  onClick={() => {
                    dispatch(toggleSubSubCategoryAction(task.content, true));
                  }}
                >
                  <img
                    src="assets/images/arrow_right.svg"
                    className="category_arrow"
                    alt=""
                  />
                </div>
                {action && (
                  <>
                    <div>
                      <img
                        src="assets/images/edit.png"
                        className="category_arrow"
                        alt=""
                        onClick={() => {
                          setModalShow(true);

                          dispatch(
                            categoryModalAction(
                              type,
                              task.content._id,
                              task.content,
                              "true"
                            )
                          );
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src="assets/images/trash.svg"
                        className="category_arrow"
                        alt=""
                        onClick={() => handleDelete(task.content._id)}
                      />
                    </div>
                  </>
                )}
              </div>

              <hr className="m-0" />
            </div>
          </Container>
        )}
      </Draggable>
    </>
  );
};
