import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";

import Skeleton from "@mui/material/Skeleton";
import $ from "jquery";
import { useSelector } from "react-redux";

import {
  filterProductWithAllFiltersAction,
  filterProductWithCategory,
  saveSubCategoryIdsInStore,
  saveSubSubCategoryIdsInStore,
  showColorAndPriceAction,
} from "../../../../services/actions/product.action";
import { useDispatch } from "react-redux";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={""} {...props} />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export default function CollapseInnerMainList() {
  const [expanded, setExpanded] = React.useState(false);
  const dispatch = useDispatch();
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const { subCategoryList, loader } = useSelector(
    (state) => state.subCategoryList
  );

  React.useEffect(() => {
    $(document).ready(function () {
      $(".category_main_list li ").click(function () {
        $("li ").removeClass("category_list_active");
        $(this).addClass("category_list_active");
      });
      $(".category_main_list li .list_heading").click(function () {
        $("li .list_heading").removeClass("category_list_active");
        $(this).addClass("category_list_active");
      });
      $(".category_inner_list .t_shirt").click(function () {
        $(".category_inner_list .t_shirt").removeClass(
          "category_inner_list_active"
        );
        $(this).addClass("category_inner_list_active");
      });
    });
  });

  const handleSubFilter = (item) => {
    let filterState = {
      price: "",
      size: "",
      color: "",
      subSubCateId: "",
      categoryId: "",
      subCategoryId: item && item._id,
    };
    dispatch(filterProductWithAllFiltersAction(filterState));
    dispatch(showColorAndPriceAction(item && item._id, "subCat"));
    dispatch(saveSubCategoryIdsInStore(item._id));
  };

  const handleSubSubFilter = (item) => {
    let filterState = {
      price: "",
      size: "",
      color: "",
      subSubCateId: item && item._id,
      categoryId: "",
      subCategoryId: "",
    };
    dispatch(filterProductWithAllFiltersAction(filterState));
    dispatch(showColorAndPriceAction(item && item._id, "subSubCat"));
    dispatch(saveSubSubCategoryIdsInStore(item._id));
  };

  return (
    <>
      {loader ? (
        <Box sx={{ width: "auto" }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      ) : (
        <ul className="category_main_list ">
          {subCategoryList && subCategoryList.length === 0 ? (
            <li className="category_not_found">
              <p className="sub_cat_not_found">Sub-category not found</p>
            </li>
          ) : (
            subCategoryList &&
            subCategoryList.map((item, index) => (
              <li data-aos="fade-down" key={index}>
                <Accordion
                  expanded={expanded === `panel${index}`}
                  onChange={handleChange(`panel${index}`)}
                >
                  <AccordionSummary
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                    className="category_filter_collapse"
                  >
                    <Typography
                      className="list_heading"
                      onClick={() => handleSubFilter(item.subCategory)}
                    >
                      {item.subCategory && item.subCategory.subCategoryName}
                    </Typography>
                  </AccordionSummary>
                  <>
                    {item.subSubCategory && item.subSubCategory.length === 0 ? (
                      <>
                        <Typography>
                          <p className="not_found">
                            Sub sub-category not found
                          </p>
                        </Typography>
                      </>
                    ) : (
                      <Typography>
                        <ul className="category_inner_list">
                          {item.subSubCategory.map((item1, index) => (
                            <li
                              className="t_shirt"
                              onClick={() => {
                                // dispatch(filterProductWithCategory(item1));
                                handleSubSubFilter(item1);
                              }}
                            >
                              {item1.subSubCategoryName}
                            </li>
                          ))}
                        </ul>
                      </Typography>
                    )}
                  </>
                </Accordion>
              </li>
            ))
          )}
        </ul>
      )}
    </>
  );
}
