import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import MaterialTable from "material-table";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Col, Form, Row } from "react-bootstrap";
import { styled } from '@mui/material/styles';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';



const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    // marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: "1px solid grey",
    fontSize: 11,
    // padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    padding: '5px 10px',
    fontWeight: 500,
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

function CustomizedSelects() {
  const [age, setAge] = React.useState('');
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <div>

      <FormControl variant="standard" sx={{ minWidth: 100 }} size="small">



        <Form.Select aria-label="Default select" className="aToZ">
          <option disabled selected></option>
          <option value={10}>User1</option>
          <option value={20}>User2</option>
          <option value={30}>User3</option>

        </Form.Select>
      </FormControl>

    </div>
  );
}
function BasicDateRangePicker() {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const range = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    "Last Year": [
      moment().subtract(1, "year").startOf("year"),
      moment().subtract(1, "year").endOf("year"),
    ],
  };

  const handleEvent = (event, picker) => {
    console.log("start: ", picker.startDate._d);
    console.log("end: ", picker.endDate._d);
    setFromDate(picker.startDate._d.toISOString());
    setToDate(picker.endDate._d.toISOString());
  };

  return (
    <>
      <div className="App">
        <span>Date Range</span>
        <DateRangePicker
          // startDate={new Date()}
          // endDate={new Date()}
          ranges={range}
          alwaysShowCalendars={true}
          onEvent={handleEvent}
        >
          <button>
            {moment(fromDate).format("LL")} to {moment(toDate).format("LL")}
          </button>
        </DateRangePicker>


      </div>
    </>



  );
}



export default function TransactionTable() {
  const columns = [
    { title: "#", field: "id" },
    { title: "Order ID", field: "orderId" },
    { title: "Amount", field: "amount" },
    { title: "Payment Method", field: "paymentMethod" },
    { title: "Role", field: "role" },
    {
      title: "Action", field: "pdf", render: (item) => <img width="30" src="assets/images/pdf.png" alt="" />
    },

  ];
  const options = {
    print: true,
    download: true,
    filter: true,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 50, 100],
    actionsColumnIndex: -1,
  };
  const tableData = [
    {
      id: 1,
      orderId: "HJH256",
      amount: "32",
      paymentMethod: "MasterCard",
      role: "User",
      title: ""

    },
  ];


  return (
    <div className="transaction_table">

      <div className="d-flex transaction_sort">

        <BasicDateRangePicker />
        <div className="d-flex">
          <span className="transaction_span">Select User</span>
          <CustomizedSelects />
        </div>

      </div>



      <MaterialTable
        options={options}
        data={tableData && tableData}
        columns={columns && columns}
        title={""}
        onRowClick={(event, rowData) => console.log(rowData)}

      />
    </div>

  );
}
