import React from "react";
import { useStyles } from "../../../utils/useStyles";
import Heading from "../../Heading/Heading";
import Sidebar from "../../Sidebar/Sidebar";
import ResellerTable from "./ResellerTable";
import AToZFilter from "./AToZFilter";
import "./Reseller.css";
function Reseller() {
  const classes = useStyles();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading />

        {/* Data table */}
        <div>
          <AToZFilter />

          <div className="card_box">
            <ResellerTable />
          </div>
        </div>
      </main>
    </div>
  );
}

export default Reseller;
