import axios from "axios";
import { Api } from "../../utils/API/Api";
import { categoryReOrder } from "../../utils/customReOrder";
import { constants } from "../constants";
import store from "../store/store";
import { loaderAction } from "./loader.action";
import { showAddProductImageAction } from "./product.action";

const categoryModalAction = (type, id, body, open) => async (dispatch) => {
  dispatch({ type: constants.CATEGORY_MODAL_CLOSE });
  switch (type) {
    case "ADD_CATEGORY": {
      dispatch(hideToggleCategory(type));
      return dispatch({
        type: constants.CATEGORY_MODAL_SUCCESS,

        payload: {
          heading: "Add Category",
          title: "Category",
          imageTitle: "Category Image",
          category: "",
          subCategory: "",
          type: type,
          _id: id,
          open: open,
          body: body,
        },
      });
    }

    case "EDIT_CATEGORY": {
      dispatch(hideToggleCategory(type));
      return dispatch({
        type: constants.CATEGORY_MODAL_SUCCESS,

        payload: {
          heading: "Edit Category",
          title: "Edit Category",
          imageTitle: "Edit Category Image",
          category: "",
          subCategory: "",
          type: type,
          _id: id,
          open: open,
          body: body,
        },
      });
    }

    case "ADD_SUB_CATEGORY": {
      dispatch({
        type: constants.CATEGORY_SUB_SUB_TOGGLE_FAIL,
      });
      return dispatch({
        type: constants.CATEGORY_MODAL_SUCCESS,
        payload: {
          heading: "Add Sub Category",
          title: "Sub Category",
          imageTitle: "",
          category: store.getState().subCategoryToggle,
          subCategory: "",
          type: type,
          _id: id,
          open: open,
          body: body,
        },
      });
    }
    case "EDIT_SUB_CATEGORY": {
      dispatch({
        type: constants.CATEGORY_SUB_SUB_TOGGLE_FAIL,
      });
      return dispatch({
        type: constants.CATEGORY_MODAL_SUCCESS,
        payload: {
          heading: "Edit Sub Category",
          title: "Sub Category",
          imageTitle: "",
          category: store.getState().subCategoryToggle,
          subCategory: "",
          type: type,
          _id: id,
          open: open,
          body: body,
        },
      });
    }
    case "ADD_SUB_SUB_CATEGORY": {
      // dispatch({
      //   type: constants.CATEGORY_SUB_SUB_TOGGLE_FAIL,
      // });
      return dispatch({
        type: constants.CATEGORY_MODAL_SUCCESS,
        payload: {
          heading: "Add Sub-subcategory",
          title: "Sub-subcategory",
          imageTitle: "Sub-subcategory Image",
          category: store.getState().subCategoryToggle,
          subCategory: store.getState().subSubCategoryToggle,
          type: type,
          _id: id,
          open: false,
          body: body,
        },
      });
    }

    default:
      return dispatch({
        type: constants.CATEGORY_MODAL_FAIL,
      });
  }
};

const openAddSubSubCategoryModal = () => async (dispatch) => {
  return dispatch({
    type: constants.CATEGORY_MODAL_OPEN,
    payload: {
      open: true,
    },
  });
};

const resetCategoryDataAction = () => async (dispatch) => {
  return dispatch({
    type: constants.CATEGORY_MODAL_FAIL,
  });
};

const editSubSubCategoryModalAction =
  (type, id, body, open) => async (dispatch) => {
    dispatch({
      type: constants.CATEGORY_MODAL_SUCCESS,
      payload: {
        heading: "Edit Sub-subcategory",
        title: "Edit Sub-subcategory",
        imageTitle: "Edit Sub-subcategory Image",
        category: store.getState().subCategoryToggle,
        subCategory: store.getState().subSubCategoryToggle,
        type: type,
        _id: id,
        open: "false",
        body: body,
      },
    });
  };

const editSubSubCategoryImageAction = (url) => async (dispatch) => {
  dispatch({
    type: constants.CATEGORY_MODAL_IMAGE_SUCCESS,
    payload: {
      url: url,
    },
  });
};

const addCategoriesAction =
  (type, categoryData, subCategory) => async (dispatch) => {
    dispatch(loaderAction(true));

    if (type === "ADD_CATEGORY") {
      dispatch({ type: constants.ADD_CATEGORY_REQUEST });
      const { data } = await axios.post(Api.addCategories, categoryData);
      if (data.status === 0) {
        dispatch({ type: constants.ADD_CATEGORY_FAIL, error: data.message });
        dispatch(loaderAction(false));
      } else {
        dispatch({
          type: constants.ADD_CATEGORY_SUCCESS,
          message: data.message,
        });
        dispatch(getCategoryAction());
        dispatch(loaderAction(false));
      }
    } else if (type === "ADD_SUB_SUB_CATEGORY") {
      dispatch({ type: constants.ADD_CATEGORY_REQUEST });
      const { data } = await axios.post(Api.addSubSubCategories, categoryData);
      if (data.status === 0) {
        dispatch({ type: constants.ADD_CATEGORY_FAIL, error: data.message });
        dispatch(loaderAction(false));
      } else {
        dispatch(getSubSubCategoryAction(subCategory));
        dispatch(loaderAction(false));
        dispatch({
          type: constants.ADD_CATEGORY_SUCCESS,
          message: data.message,
        });
      }
    } else {
      dispatch(loaderAction(false));

      return dispatch({
        type: constants.ADD_CATEGORY_FAIL,
      });
    }
  };

const editCategoriesAction =
  (type, categoryData, subCategory) => async (dispatch) => {
    dispatch(loaderAction(true));

    if (type === "EDIT_CATEGORY") {
      dispatch({ type: constants.ADD_CATEGORY_REQUEST });
      const { data } = await axios.put(Api.editCategory, categoryData);
      if (data.status === 0) {
        dispatch({ type: constants.ADD_CATEGORY_FAIL, error: data.message });
        dispatch(loaderAction(false));
      } else {
        dispatch({
          type: constants.ADD_CATEGORY_SUCCESS,
          message: data.message,
        });
        dispatch(getCategoryAction());
        dispatch(loaderAction(false));
      }
      return false;
    } else if (type === "ADD_SUB_SUB_CATEGORY") {
      dispatch({ type: constants.ADD_CATEGORY_REQUEST });
      const { data } = await axios.post(Api.addSubSubCategories, categoryData);
      if (data.status === 0) {
        dispatch({ type: constants.ADD_CATEGORY_FAIL, error: data.message });
        dispatch(loaderAction(false));
      } else {
        dispatch(getSubSubCategoryAction(subCategory));
        dispatch(loaderAction(false));
        dispatch({
          type: constants.ADD_CATEGORY_SUCCESS,
          message: data.message,
        });
      }
    } else if (type === "EDIT_SUB_SUB_CATEGORY") {
      dispatch({ type: constants.ADD_CATEGORY_REQUEST });
      const { data } = await axios.put(Api.editSubSubCategory, categoryData);
      if (data.status === 0) {
        dispatch({ type: constants.ADD_CATEGORY_FAIL, error: data.message });
        dispatch(loaderAction(false));
      } else {
        dispatch(getSubSubCategoryAction(subCategory));
        dispatch(loaderAction(false));
        dispatch({
          type: constants.ADD_CATEGORY_SUCCESS,
          message: data.message,
        });
      }
    } else {
      dispatch(loaderAction(false));

      return dispatch({
        type: constants.ADD_CATEGORY_FAIL,
      });
    }
  };

const addSubCategoryAction =
  (type, categoryData, category) => async (dispatch) => {
    if (type === "ADD_SUB_CATEGORY") {
      dispatch({ type: constants.ADD_CATEGORY_REQUEST });

      const { data } = await axios.post(Api.addSubCategories, categoryData);

      if (data.status === 0) {
        dispatch({ type: constants.ADD_CATEGORY_FAIL, error: data.message });
        dispatch(loaderAction(false));
        await dispatch(getSubCategoryAction(category));
      } else {
        dispatch({
          type: constants.ADD_CATEGORY_SUCCESS,
          message: data.message,
        });
        dispatch(loaderAction(false));
        await dispatch(getSubCategoryAction(category));
      }
    }
  };

const editSubCategoryAction =
  (type, categoryData, category) => async (dispatch) => {
    if (type === "EDIT_SUB_CATEGORY") {
      dispatch({ type: constants.ADD_CATEGORY_REQUEST });

      const { data } = await axios.put(Api.editSubCategory, categoryData);

      if (data.status === 0) {
        dispatch({ type: constants.ADD_CATEGORY_FAIL, error: data.message });
        dispatch(loaderAction(false));
        await dispatch(getSubCategoryAction(category));
      } else {
        dispatch({
          type: constants.ADD_CATEGORY_SUCCESS,
          message: data.message,
        });
        dispatch(loaderAction(false));
        await dispatch(getSubCategoryAction(category));
      }
    }
  };

const hideToggleCategory = (type) => {
  return async (dispatch) => {
    switch (type) {
      case "ADD_CATEGORY": {
        dispatch({
          type: constants.CATEGORY_SUB_SUB_TOGGLE_FAIL,
        });
        return dispatch({
          type: constants.CATEGORY_SUB_TOGGLE_FAIL,
        });
      }
      case "EDIT_CATEGORY": {
        dispatch({
          type: constants.CATEGORY_SUB_SUB_TOGGLE_FAIL,
        });
        return dispatch({
          type: constants.CATEGORY_SUB_TOGGLE_FAIL,
        });
      }

      case "ADD_SUB_CATEGORY": {
        return dispatch({
          type: constants.CATEGORY_SUB_SUB_TOGGLE_FAIL,
        });
      }

      default:
        return;
    }
  };
};

const clearCategory = () => {
  return async (dispatch) => {
    dispatch({
      type: constants.CLEAR_CATEGORY,
    });
  };
};

const toggleSubCategoryAction = (data, boolean) => async (dispatch) => {
  // dispatch(loaderAction(true));

  dispatch(showAddProductImageAction(false));
  await dispatch({
    type: constants.CATEGORY_SUB_TOGGLE_REQUEST,
  });

  dispatch({
    type: constants.CATEGORY_SUB_SUB_TOGGLE_FAIL,
  });

  await dispatch({
    type: constants.CATEGORY_SUB_TOGGLE_SUCCESS,
    payload: data && data,
    subCategory: boolean,
  });

  await dispatch(getSubCategoryAction(data));

  // dispatch(loaderAction(false));
};
const toggleSubSubCategoryAction = (data, boolean) => async (dispatch) => {
  dispatch(showAddProductImageAction(false));

  dispatch({
    type: constants.CATEGORY_SUB_SUB_TOGGLE_SUCCESS,
    payload: data,
    subSubCategory: boolean,
  });

  await dispatch(getSubSubCategoryAction(data));

  // dispatch(loaderAction(false));
};

const getCategoryAction = () => async (dispatch) => {
  dispatch(loaderAction(true));

  dispatch({ type: constants.CATEGORY_REQUEST });
  try {
    const { data } = await axios.get(Api.getCategories);

    if (data.status === 0) {
      dispatch(loaderAction(false));
      dispatch({
        type: constants.CATEGORY_FAIL,
      });
    }

    const customData = await categoryReOrder(data.category);

    dispatch({
      type: constants.CATEGORY_SUCCESS,
      payload: {
        data: data.category.length === 0 ? [] : data.category,
        reOrder: data.category.length === 0 ? [] : customData,
      },
    });
    dispatch(loaderAction(false));
  } catch (error) {
    console.log(error);
  }
};

const getSubCategoryAction = (category) => async (dispatch) => {
  dispatch(loaderAction(true));

  dispatch({ type: constants.CATEGORY_SUB_REQUEST });

  const { data } = await axios.post(Api.getSubCategory, {
    category: category._id,
  });

  if (data.status === 0) {
    dispatch({
      type: constants.CATEGORY_SUB_FAIL,
    });
    dispatch(loaderAction(false));
  }

  const customData = await categoryReOrder(data.data);

  dispatch({
    type: constants.CATEGORY_SUB_SUCCESS,
    payload: {
      data: data.data.length === 0 ? [] : data.data,
      reOrder: customData,
    },
  });
  dispatch(loaderAction(false));
};

const getSubSubCategoryAction = (subCategory) => async (dispatch) => {
  dispatch(loaderAction(true));

  dispatch({ type: constants.CATEGORY_SUB_SUB_REQUEST });

  const { data } = await axios.post(Api.getSubSubCategory, {
    category: subCategory.category,
    subCategory: subCategory._id,
  });
  if (data.status === 0) {
    dispatch({
      type: constants.CATEGORY_SUB_SUB_FAIL,
    });
    dispatch(loaderAction(false));
  }
  const customData = await categoryReOrder(data.data);

  dispatch({
    type: constants.CATEGORY_SUB_SUB_SUCCESS,
    payload: {
      data: data.data.length === 0 ? [] : data.data,
      reOrder: customData,
    },
  });
  dispatch(loaderAction(false));
};

export {
  categoryModalAction,
  getCategoryAction,
  getSubCategoryAction,
  getSubSubCategoryAction,
  toggleSubCategoryAction,
  toggleSubSubCategoryAction,
  addCategoriesAction,
  addSubCategoryAction,
  hideToggleCategory,
  clearCategory,
  editCategoriesAction,
  editSubCategoryAction,
  editSubSubCategoryModalAction,
  editSubSubCategoryImageAction,
  resetCategoryDataAction,
  openAddSubSubCategoryModal,
};
