import * as React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";

export default function SortByButton() {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        Sort By
        <NativeSelect
          defaultValue={30}
          inputProps={{
            name: "age",
            id: "uncontrolled-native",
          }}
          onChange={(e) => {
            console.log(e.target.value);
          }}
        >
          <option value={10}>Weakly</option>
          <option value={20}>Monthly</option>
          <option value={30}>Yearly</option>
        </NativeSelect>
      </FormControl>
    </Box>
  );
}
