import React, { useState, useEffect } from "react";
import { Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAttributeColors } from "../../services/actions/attribute.action";
function ColorAttributes() {
  const dispatch = useDispatch();
  const { singleAttributeData } = useSelector(
    (state) => state.singleAttributeData
  );
  const [colorArr, setColorArr] = useState([]);
  const [color, setColor] = useState("");

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (event.target.value) {
        setColorArr((pre) => [...pre, event.target.value]);
        setColor("");
      }
    }
  };

  const deleteSize = (item) => {
    let data = colorArr.filter((size) => size !== item);
    setColorArr(data);
  };

  useEffect(() => {
    dispatch(getAttributeColors(colorArr));
  }, [dispatch, colorArr]);

  useEffect(() => {
    if (singleAttributeData && singleAttributeData.color) {
      setColorArr(singleAttributeData && singleAttributeData.color);
    }
  }, [singleAttributeData]);

  return (
    <div>
      <Row>
        <Col xs={12}>
          <h6>Add Color</h6>
        </Col>
      </Row>
      <div className="form_activity form_add_product row mt-2">
        <Form.Group className="mb-2 col-sm-12 col-md-6">
          <InputGroup className="mb-2">
            <FormControl
              placeholder="XXL"
              aria-label="size"
              aria-describedby="basic-addon1"
              value={color}
              onChange={(e) => setColor(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-2 col-sm-12 col-md-6">
          <div className="d-flex flex-wrap">
            {colorArr && colorArr.length > 0
              ? colorArr.map((item, index) => (
                  <div
                    className="size_box"
                    key={index}
                    onClick={() => deleteSize(item)}
                  >
                    <span>{item}</span>
                    <img src="assets/images/close_btn.svg" alt="" />
                  </div>
                ))
              : null}
          </div>
        </Form.Group>
      </div>
    </div>
  );
}

export default ColorAttributes;
