import React from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import { ageRange } from "./AgeRange";
function ByAge() {
  return (
    <div>
      <Form className="form_activity">
        <Form.Group className="mb-3">
          <Form.Label>By Age </Form.Label>
          <div className="age_selection">
            {ageRange.map((range) => (
              <Button>
                {range.min}
                <span>{range.min && range.min ? "-" : null}</span>
                {range.max}
              </Button>
            ))}
          </div>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Sales Volume</Form.Label>
          <FormControl
            placeholder="Enter the Sales Volume"
            aria-label="salesVolumne"
            aria-describedby="basic-addon1"
          />
        </Form.Group>
      </Form>
    </div>
  );
}

export default ByAge;
